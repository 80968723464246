export const UI = {
  SET: 'UI_SET'
};

export const SESSION = {
  SET: 'SET_SESSION',
  INIT: 'INIT_SESSION',
  UNSET: 'UNSET_SESSION'
};

export const COUNTRY = {
  FETCH: 'FETCH_COUNTRIES',
  FETCH_SUCCEEDED: 'FETCH_COUNTRIES_SUCCEEDED',
  FETCH_FAILED: 'FETCH_COUNTRIES_FAILED'
};

export const STATE = {
  FETCH: 'FETCH_STATES',
  FETCH_SUCCEEDED: 'FETCH_STATES_SUCCEEDED',
  FETCH_FAILED: 'FETCH_STATES_FAILED'
};

export const AFFILIATE = {
  SAVE: 'SAVE_AFFILIATE',
  SAVE_SUCCEEDED: 'SAVE_AFFILIATE_SUCCEEDED',
  SAVE_FAILED: 'SAVE_AFFILIATE_FAILED',
  SAVE_DRAFT: 'SAVE_DRAFT',
  SAVE_DRAFT_SUCCEEDED: 'SAVE_DRAFT_SUCCEEDED',
  SAVE_DRAFT_FAILED: 'SAVE_DRAFT_FAILED',
  FETCH_DETAIL: 'FETCH_AFFILIATE_DETAIL',
  FETCH_DETAIL_SUCCEEDED: 'FETCH_AFFILIATE_DETAIL_SUCCEEDED',
  FETCH_DETAIL_FAILED: 'FETCH_AFFILIATE_DETAIL_FAILED',
  FETCH: 'FETCH_AFFILIATE',
  FETCH_SUCCEEDED: 'FETCH_AFFILIATE_SUCCEEDED',
  FETCH_FAILED: 'FETCH_AFFILIATE_FAILED',
  TOGGLE_CONFIGURATION_COMPONENT: 'TOGGLE_CONFIGURATION_COMPONENT',
  SAVE_BASIC_INFO: 'SAVE_BASIC_INFO',
  SAVE_BASIC_INFO_SUCCEEDED: 'SAVE_BASIC_INFO_SUCCEEDED',
  SAVE_BASIC_INFO_FAILED: 'SAVE_BASIC_INFO_FAILED',
  UPDATE: 'UPDATE',
  SAVE_KYC_INFO: 'SAVE_KYC_INFO',
  SAVE_ORIGINATOR_INFO: 'SAVE_ORIGINATOR_INFO',
  SAVE_PAYOUT_PARTNER_INFO: 'SAVE_PAYOUT_PARTNER_INFO',
  SAVE_SERVICE_PROVIDER_INFO: 'SAVE_SERVICE_PROVIDER_INFO'
};

export const ORIGINATOR = {
  FETCH: 'FETCH_ORIGINATORS',
  FETCH_SUCCEEDED: 'FETCH_ORIGINATORS_SUCCEEDED',
  FETCH_FAILED: 'FETCH_ORIGINATORS_FAILED'
};

export const PAYOUT_PARTNER = {
  FETCH: 'FETCH_PAYOUT_PARTNERS',
  FETCH_SUCCEEDED: 'FETCH_PAYOUT_PARTNERS_SUCCEEDED',
  FETCH_FAILED: 'FETCH_PAYOUT_PARTNERS_FAILED'
};

export const KYC_TAG = {
  FETCH: 'FETCH_KYC_TAG',
  FETCH_SUCCEEDED: 'FETCH_KYC_TAG_SUCCEEDED',
  FETCH_FAILED: 'FETCH_KYC_TAG_FAILED'
};

export const SERVICE_PROVIDER = {
  FETCH: 'FETCH_SERVICE_PROVIDERS',
  FETCH_SUCCEEDED: 'FETCH_SERVICE_PROVIDERS_SUCCEEDED',
  FETCH_FAILED: 'FETCH_SERVICE_PROVIDERS_FAILED'
};

export const SENDER = {
  FETCH: 'FETCH_SENDERS',
  FETCH_SUCCEEDED: 'FETCH_SENDERS_SUCCEEDED',
  FETCH_FAILED: 'FETCH_SENDERS_FAILED',
  FETCH_SENDER_DETAIL: 'FETCH_SENDER_DETAIL',
  FETCH_SENDER_DETAIL_SUCCEEDED: 'FETCH_SENDER_DETAIL_SUCCEEDED',
  FETCH_SENDER_DETAIL_FAILED: 'FETCH_SENDER_DETAIL_FAILED',
  FETCH_RECIPIENTS_OF_SENDER: 'FETCH_RECIPIENTS_OF_SENDER',
  FETCH_RECIPIENTS_OF_SENDER_SUCCEEDED: 'FETCH_RECIPIENTS_OF_SENDER_SUCCEEDED',
  FETCH_RECIPIENTS_OF_SENDER_FAILED: 'FETCH_RECIPIENTS_OF_SENDER_FAILED',
  FETCH_TRANSACTIONS_OF_SENDER: 'FETCH_TRANSACTIONS_OF_SENDER',
  FETCH_TRANSACTIONS_OF_SENDER_SUCCEEDED:
    'FETCH_TRANSACTIONS_OF_SENDER_SUCCEEDED',
  FETCH_TRANSACTIONS_OF_SENDER_FAILED: 'FETCH_TRANSACTIONS_OF_SENDER_FAILED',
  FETCH_FUNDING_SOURCES_OF_SENDER: 'FETCH_FUNDING_SOURCES_OF_SENDER',
  FETCH_FUNDING_SOURCES_OF_SENDER_SUCCEEDED:
    'FETCH_FUNDING_SOURCES_OF_SENDER_SUCCEEDED',
  FETCH_FUNDING_SOURCES_OF_SENDER_FAILED:
    'FETCH_FUNDING_SOURCES_OF_SENDER_FAILED',
  INITIALIZE_SENDERS: 'INITIALIZE_SENDERS',
  FETCH_ALL_SENDERS: 'FETCH_ALL_SENDERS'
};

export const TRANSACTION = {
  FETCH: 'FETCH_TRANSACTIONS',
  FETCH_SUCCEEDED: 'FETCH_TRANSACTIONS_SUCCEEDED',
  FETCH_FAILED: 'FETCH_TRANSACTIONS_FAILED',
  FETCH_DETAIL: 'FETCH_TRANSACTIONS_DETAIL',
  INITIALIZE_TRANSACTIONS: 'INITIALIZE_TRANSACTIONS',
  FETCH_INVOICE: 'FETCH_TRANSACTION_INVOICE',
  FETCH_ALL_TRANSACTIONS: 'FETCH_ALL_TRANSACTIONS'
};

export const RECIPIENT = {
  FETCH: 'FETCH_RECIPIENTS',
  FETCH_SUCCEEDED: 'FETCH_RECIPIENTS_SUCCEEDED',
  FETCH_FAILED: 'FETCH_RECIPIENTS_FAILED',
  FETCH_RECIPIENT_DETAIL: 'FETCH_RECIPIENT_DETAIL',
  FETCH_RECIPIENT_DETAIL_SUCCEEDED: 'FETCH_RECIPIENT_DETAIL_SUCCEEDED',
  FETCH_RECIPIENT_DETAIL_FAILED: 'FETCH_RECIPIENT_DETAIL_FAILED',
  INITIALIZE_RECIPIENTS: 'INITIALIZE_RECIPIENTS',
  FETCH_ALL_RECIPIENTS: 'FETCH_ALL_RECIPIENTS'
};

export const AUTH = {
  SAVE_USER_CREDENTIALS: 'SAVE_USER_CREDENTIALS',
  USER_AUTHORIZATION_PENDING: 'USER_AUTHORIZATION_PENDING',
  USER_AUTHORIZATION_SUCCESS: 'USER_AUTHORIZATION_SUCCESS',
  USER_AUTHORIZATION_FAILED: 'USER_AUTHORIZATION_FAILED',
  SIGN_OUT_PENDING: 'SIGN_OUT_PENDING',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILED: 'SIGN_OUT_FAILED'
};

export const MSB = {
  FETCH: 'FETCH_MSB_LIST',
  FETCH_SUCCEEDED: 'FETCH_MSB_LIST_SUCCEEDED',
  FETCH_FAILED: 'FETCH_MSB_LIST_FAILED'
};

export const MTO = {
  FETCH: 'FETCH_MTO_LIST',
  FETCH_SUCCEEDED: 'FETCH_MTO_LIST_SUCCEEDED',
  FETCH_FAILED: 'FETCH_MTO_LIST_FAILED'
};

export const ADMIN_TOOLS = {
  UNHOLD_TRANSACTION: 'UNHOLD_TRANSACTION',
  UPDATE_TRANSACTION_STATUS: 'UPDATE_TRANSACTION_STATUS',
  UPDATE_DELIVERY_STATUS: 'UPDATE_DELIVERY_STATUS',
  REQUEST_TRANSACTION_DELIVERY: 'REQUEST_TRANSACTION_DELIVERY',
  HOLD_TRANSACTION_DELIVERY: 'HOLD_TRANSACTION_DELIVERY',
  UNHOLD_TRANSACTION_DELIVERY: 'UNHOLD_TRANSACTION_DELIVERY'
};

export const TIER = {
  FETCH_TIER_LIST: 'FETCH_TIER_LIST',
  FETCH_TIER_LIST_SUCCESS: 'FETCH_TIER_LIST_SUCCESS',
  FETCH_TIER_LIST_ERROR: 'FETCH_TIER_LIST_ERROR',
  REVIEW_TIER_CHANGE_REQUEST: 'REVIEW_TIER_CHANGE_REQUEST',
  REVIEW_TIER_CHANGE_REQUEST_SUCCESS: 'REVIEW_TIER_CHANGE_REQUEST_SUCCESS',
  REVIEW_TIER_CHANGE_REQUEST_ERROR: 'REVIEW_TIER_CHANGE_REQUEST_ERROR'
};

export const EXCHANGE_RATE = {
  FETCH: 'FETCH_EXCHANGE_RATE',
  FETCH_SUCCEEDED: 'FETCH_EXCHANGE_RATE_SUCCEEDED',
  FETCH_FAILED: 'FETCH_EXCHANGE_RATE_FAILED'
};
