import { takeEvery } from 'redux-saga/effects';

import {
  MSB,
  MTO,
  TIER,
  STATE,
  SENDER,
  COUNTRY,
  KYC_TAG,
  AFFILIATE,
  RECIPIENT,
  ORIGINATOR,
  TRANSACTION,
  ADMIN_TOOLS,
  EXCHANGE_RATE,
  PAYOUT_PARTNER,
  SERVICE_PROVIDER,
  AUTH
} from '../actions/actions';

import { fetchMsbList } from './msbSaga';
import { fetchMtoList } from './mtoSaga';
import { fetchStates } from './stateSaga';
import { fetchKycTags } from './kycTagsSaga';
import { fetchCountries } from './countrySaga';
import { fetchOriginators } from './originatorSaga';
import { signOutUser, authorizeUser } from './authSaga';
import { fetchPayoutPartners } from './payoutPartnerSaga';
import { fetchFundingSources } from './fundingSourceSaga';
import { updateTransactionStatus } from './adminToolSaga';
import { fetchTierList, reviewTierRequest } from './tierSaga';
import { fetchServiceProviders } from './serviceProviderSaga';
import { fetchSenders, fetchSenderDetail, fetchAllSenders } from './senderSaga';
import {
  fetchRecipients,
  fetchAllRecipients,
  fetchRecipientDetail
} from './recipientSaga';
import {
  fetchTransactions,
  fetchAllTransactions,
  fetchTransactionDetail,
  fetchTransactionInvoice
} from './transactionSaga';
import {
  saveBasicInfo,
  saveAffiliate,
  fetchAffiliates,
  updateAffiliate,
  saveAffiliateDraft,
  fetchAffiliateDetails
} from './affiliateSaga';
import { fetchExchangeRates } from './exchangeRateSaga';

export default function* rootSaga() {
  yield takeEvery(MSB.FETCH, fetchMsbList);
  yield takeEvery(MTO.FETCH, fetchMtoList);
  yield takeEvery(STATE.FETCH, fetchStates);
  yield takeEvery(SENDER.FETCH, fetchSenders);
  yield takeEvery(KYC_TAG.FETCH, fetchKycTags);
  yield takeEvery(AFFILIATE.SAVE, saveAffiliate);
  yield takeEvery(COUNTRY.FETCH, fetchCountries);
  yield takeEvery(AFFILIATE.FETCH, fetchAffiliates);
  yield takeEvery(RECIPIENT.FETCH, fetchRecipients);
  yield takeEvery(AFFILIATE.UPDATE, updateAffiliate);
  yield takeEvery(ORIGINATOR.FETCH, fetchOriginators);
  yield takeEvery(AUTH.SIGN_OUT_PENDING, signOutUser);
  yield takeEvery(TIER.FETCH_TIER_LIST, fetchTierList);
  yield takeEvery(TRANSACTION.FETCH, fetchTransactions);
  yield takeEvery(EXCHANGE_RATE.FETCH, fetchExchangeRates);
  yield takeEvery(AFFILIATE.SAVE_BASIC_INFO, saveBasicInfo);
  yield takeEvery(AFFILIATE.SAVE_DRAFT, saveAffiliateDraft);
  yield takeEvery(PAYOUT_PARTNER.FETCH, fetchPayoutPartners);
  yield takeEvery(SENDER.FETCH_ALL_SENDERS, fetchAllSenders);
  yield takeEvery(SENDER.FETCH_SENDER_DETAIL, fetchSenderDetail);
  yield takeEvery(AFFILIATE.FETCH_DETAIL, fetchAffiliateDetails);
  yield takeEvery(SERVICE_PROVIDER.FETCH, fetchServiceProviders);
  yield takeEvery(AUTH.USER_AUTHORIZATION_PENDING, authorizeUser);
  yield takeEvery(TIER.REVIEW_TIER_CHANGE_REQUEST, reviewTierRequest);
  yield takeEvery(TRANSACTION.FETCH_DETAIL, fetchTransactionDetail);
  yield takeEvery(RECIPIENT.FETCH_ALL_RECIPIENTS, fetchAllRecipients);
  yield takeEvery(TRANSACTION.FETCH_INVOICE, fetchTransactionInvoice);
  yield takeEvery(SENDER.FETCH_RECIPIENTS_OF_SENDER, fetchRecipients);
  yield takeEvery(SENDER.FETCH_RECIPIENTS_OF_SENDER, fetchRecipients);
  yield takeEvery(SENDER.FETCH_TRANSACTIONS_OF_SENDER, fetchTransactions);
  yield takeEvery(RECIPIENT.FETCH_RECIPIENT_DETAIL, fetchRecipientDetail);
  yield takeEvery(ADMIN_TOOLS.UNHOLD_TRANSACTION, updateTransactionStatus);
  yield takeEvery(TRANSACTION.FETCH_ALL_TRANSACTIONS, fetchAllTransactions);
  yield takeEvery(ADMIN_TOOLS.UPDATE_DELIVERY_STATUS, updateTransactionStatus);
  yield takeEvery(SENDER.FETCH_FUNDING_SOURCES_OF_SENDER, fetchFundingSources);
  yield takeEvery(
    ADMIN_TOOLS.HOLD_TRANSACTION_DELIVERY,
    updateTransactionStatus
  );
  yield takeEvery(
    ADMIN_TOOLS.UNHOLD_TRANSACTION_DELIVERY,
    updateTransactionStatus
  );
  yield takeEvery(
    ADMIN_TOOLS.UPDATE_TRANSACTION_STATUS,
    updateTransactionStatus
  );
  yield takeEvery(
    ADMIN_TOOLS.REQUEST_TRANSACTION_DELIVERY,
    updateTransactionStatus
  );
}
