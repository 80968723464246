import { RECIPIENT } from './actions';

export const fetchRecipients = (page, filter) => ({
  type: RECIPIENT.FETCH,
  payload: {
    url: `/raas/admin/summary-recipients?page=${page}&${filter}`,
    onSuccess: RECIPIENT.FETCH_SUCCEEDED,
    onFailure: RECIPIENT.FETCH_FAILED
  }
});

export const fetchRecipientDetail = (senderId, recipientId) => ({
  type: RECIPIENT.FETCH_RECIPIENT_DETAIL,
  payload: {
    url: `/raas/admin/senders/${senderId}/recipients/${recipientId}`,
    onSuccess: RECIPIENT.FETCH_RECIPIENT_DETAIL_SUCCEEDED,
    onFailure: RECIPIENT.FETCH_RECIPIENT_DETAIL
  }
});

export const initializeRecipients = () => ({
  type: RECIPIENT.INITIALIZE_RECIPIENTS
});

export const fetchAllRecipients = (onSuccess, onFailure) => ({
  type: RECIPIENT.FETCH_ALL_RECIPIENTS,
  payload: {
    url: `/raas/admin/recipients`,
    onSuccess,
    onFailure
  }
});
