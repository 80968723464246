import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button/Button';

import './Section.scss';
/**
 * Section component for viewing detail.
 *
 * @param {Object} props
 *
 * @returns {React.FC}
 */
const Section = props => {
  const { title, onEdit, children } = props;

  return (
    <>
      <div className="section">
        <div className="section__header">
          <h3>{title}</h3>
          {onEdit && (
            <Button onClick={onEdit} className="btn btn-link section__edit-btn">
              <span>
                <i className="fas fa-edit"></i>
              </span>
            </Button>
          )}
        </div>
        <div>{children}</div>
      </div>
      <hr />
    </>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  onEdit: PropTypes.function,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Section;
