export const transactionStatus = {
  PENDING: 'PENDING',
  PROCESSED: 'PROCESSED',
  FAILED: 'FAILED',
  CANCELED: 'CANCELED',
  RETURNED: 'RETURNED',
  HOLD: 'HOLD',
  REFUNDED: 'REFUNDED'
};

export const transactionStatusOptions = [
  { name: transactionStatus.PENDING, value: transactionStatus.PENDING },
  { name: transactionStatus.PROCESSED, value: transactionStatus.PROCESSED },
  { name: transactionStatus.FAILED, value: transactionStatus.FAILED },
  { name: transactionStatus.CANCELED, value: transactionStatus.CANCELED },
  { name: transactionStatus.RETURNED, value: transactionStatus.RETURNED },
  { name: transactionStatus.HOLD, value: transactionStatus.HOLD },
  { name: transactionStatus.REFUNDED, value: transactionStatus.REFUNDED }
];
