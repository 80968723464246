import { compose } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import { fetchMsbList } from 'actions/msbAction';
import { fetchMtoList } from 'actions/mtoAction';
import {
  fetchTransactionDetail,
  fetchTransactionInvoice
} from 'actions/transactionAction';

import { isObjectEmpty, setPreferredTimeZone } from 'utils/commonUtils';
import { Header, Button, ErrorToastMessage } from '@raas-dashboard/base';

import './Transactions.scss';

const payoutMethod = {
  CASH_PICKUP: 'CASH_PICKUP'
};

const TransactionDetail = props => {
  const [transactionDetail, setTransactionDetail] = useState({});
  const onSuccess = data => {
    setTransactionDetail(data);
  };
  const onFailure = () => {
    toast.error(
      <ErrorToastMessage message="Failed to fetch transaction detail" />
    );
  };
  const { msbs, mtos, fetchMsbList, fetchMtoList } = props;
  const { sender_id, transaction_id } = props.match.params;

  useEffect(() => {
    props.fetchTransactionDetail(
      sender_id,
      transaction_id,
      onSuccess,
      onFailure
    );
    if (isObjectEmpty(msbs)) {
      fetchMsbList();
    }
    if (isObjectEmpty(mtos)) {
      fetchMtoList();
    }
  }, []);

  const openInvoiceLink = () => {
    const onSuccess = data => {
      window.open(data.frontImageLink, '_blank');
    };
    const onFailure = () => {
      toast.error(<ErrorToastMessage message="Couldn't get invoice link" />);
    };

    props.fetchTransactionInvoice(
      sender_id,
      transaction_id,
      onSuccess,
      onFailure
    );
  };

  return (
    <div className="content-container">
      <Header text="Transactions Detail" />
      <div className="transaction-detail-container">
        <div className="basic-transaction-detail-container">
          <div className="display-flex padding-bottom-20">
            <div className="transaction-id">
              <ul className="detail-list">
                <li>
                  <span className="heading">Transaction id: </span>
                  <span className="data">{transactionDetail.id}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="display-flex padding-bottom-20">
            <div className="basic-transaction-detail">
              <ul className="detail-list">
                <li>
                  <span className="heading">Reference Number: </span>
                  <span className="data">
                    {transactionDetail.mto_reference_number}
                  </span>
                </li>
                <li>
                  <span className="heading">MSB: </span>
                  <span className="data">
                    {msbs[transactionDetail.msb_id] &&
                      msbs[transactionDetail.msb_id].name}
                  </span>
                </li>
                <li>
                  <span className="heading">MSB Reference Number: </span>
                  <span className="data">
                    {transactionDetail.payment_processor_txn_id}
                  </span>
                </li>
                {transactionDetail.payout_method ===
                payoutMethod.CASH_PICKUP ? (
                  <li>
                    <span className="heading">Payout Reference Number: </span>
                    <span className="data">
                      {transactionDetail.payout_reference_number}
                    </span>
                  </li>
                ) : null}
                <li>
                  <span className="heading">Affiliate: </span>
                  <span className="data">
                    {mtos[transactionDetail.mto_id] &&
                      mtos[transactionDetail.mto_id].name}
                  </span>
                </li>
              </ul>
            </div>
            <div className="action-button-container">
              <Button
                className="btn btn-primary"
                onClick={openInvoiceLink}
                name="Check Invoice"
              />
            </div>
          </div>
        </div>
        <div className="complete-transaction-detail-container display-flex row">
          <div className="sender-detail col-md-4">
            <ul className="detail-list">
              <li className="padding-bottom-20">
                <span className="heading display-block">From: </span>
                <span className="data display-block">
                  {transactionDetail.from && transactionDetail.from.full_name}
                </span>
                <span className="data display-block">
                  {transactionDetail.from &&
                    `${transactionDetail.from.city}, ${transactionDetail.from.state}, ${transactionDetail.from.country}`}
                </span>
              </li>
              <li>
                <span className="heading">Funding Source Name: </span>
                <span className="data">
                  {transactionDetail.from &&
                    transactionDetail.from.account_detail &&
                    transactionDetail.from.account_detail.institution_name}
                </span>
              </li>
              <li>
                <span className="heading">User ID: </span>
                <span className="data">
                  {transactionDetail.from && (
                    <Link to={`/senders/${transactionDetail.from.sender_id}`}>
                      {transactionDetail.from.sender_id}
                    </Link>
                  )}
                </span>
              </li>
              <li>
                <span className="heading">Funding Source ID: </span>
                <span className="data">
                  {transactionDetail.from &&
                    transactionDetail.from.account_detail &&
                    transactionDetail.from.account_detail.id}
                </span>
              </li>
              <li>
                <span className="heading">Payment Processor ID: </span>
                <span className="data">
                  {transactionDetail.from &&
                    transactionDetail.payment_processor_txn_id}
                </span>
              </li>
            </ul>
          </div>
          <div className="recipient-detail col-md-4">
            <ul className="detail-list">
              <li className="padding-bottom-20">
                <span className="heading display-block">To: </span>
                <span className="data display-block">
                  {transactionDetail.to && transactionDetail.to.full_name}
                </span>
                <span className="data display-block">
                  {transactionDetail.to && transactionDetail.to.address_line1}
                </span>
              </li>
              <li>
                <span className="heading">Payout Method: </span>
                <span className="data">
                  {transactionDetail.payout_method &&
                    transactionDetail.payout_method.replace('_', ' ')}
                </span>
              </li>
              <li>
                <span className="heading">Bank Name: </span>
                <span className="data">
                  {transactionDetail.to &&
                    transactionDetail.to.account_detail &&
                    transactionDetail.to.account_detail.bank_name}
                </span>
              </li>
              <li>
                <span className="heading">Branch Name: </span>
                <span className="data">
                  {transactionDetail.to &&
                    transactionDetail.to.account_detail &&
                    transactionDetail.to.account_detail.branch_name}
                </span>
              </li>
              <li>
                <span className="heading">Account No.: </span>
                <span className="data">
                  {transactionDetail.to &&
                    transactionDetail.to.account_detail &&
                    transactionDetail.to.account_detail.account_number}
                </span>
              </li>
              <li>
                <span className="heading">Account Type: </span>
                <span className="data">
                  {transactionDetail.to &&
                    transactionDetail.to.account_detail &&
                    transactionDetail.to.account_detail.account_type}
                </span>
              </li>
              <li>
                <span className="heading">User ID: </span>

                <span className="data">
                  {transactionDetail.from && transactionDetail.to && (
                    <Link
                      to={`/senders/${transactionDetail.from.sender_id}/recipients/${transactionDetail.to.recipient_id}`}
                    >
                      {transactionDetail.to.recipient_id}
                    </Link>
                  )}
                </span>
              </li>
            </ul>
          </div>
          <div className="transaction-detail col-md-4">
            <ul className="detail-list">
              <li className="padding-bottom-20">
                <span className="heading">Transaction Date: </span>
                <span className="data">
                  {setPreferredTimeZone(transactionDetail.created_on)}
                </span>
              </li>
              <li className="padding-bottom-20">
                <span className="heading">IP Address: </span>
                <span className="data">{transactionDetail.ip_address}</span>
              </li>
              <li className="padding-bottom-20">
                <span className="heading">Risk Score: </span>
                <span className="data">{transactionDetail.risk_score}</span>
              </li>
              <li>
                <span className="heading">Total: </span>
                <span className="data">{`${transactionDetail.sender_currency} ${transactionDetail.total_amount}`}</span>
              </li>
              <li className="padding-bottom-20">
                <span className="heading">FX Rate: </span>
                <span className="data">{transactionDetail.exchange_rate}</span>
              </li>
              <li>
                <span className="heading">Send Amount: </span>
                <span className="data">{`${transactionDetail.sender_currency} ${transactionDetail.txn_amount}`}</span>
              </li>
              <li>
                <span className="heading">Receiver Amount: </span>
                <span className="data">
                  {`${transactionDetail.recipient_currency} ${transactionDetail.recipient_amount}`}
                </span>
              </li>
              <li>
                <span className="heading">Transfer Fee: </span>
                <span className="data">{`${transactionDetail.sender_currency} ${transactionDetail.fee_amount}`}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="transaction-history-container">
          <Header text="Transaction Status History" />
          <div>
            {transactionDetail.status_history &&
              transactionDetail.status_history.map((history, index) => (
                <div className="transaction-history" key={index}>
                  <span className="status">{history.status}</span>
                  <span className="history-date">{` on ${setPreferredTimeZone(
                    history.changed_at
                  )}`}</span>
                  {history.comment && (
                    <div className="history-comment-container">
                      Comment:
                      <span className="history-comment">{history.comment}</span>
                    </div>
                  )}
                  {history.hold_reasons && history.hold_reasons[0] && (
                    <div className="history-comment-container">
                      Hold Reason:
                      <span className="history-comment">
                        {history.hold_reasons[0].message}
                      </span>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className="transaction-history-container">
          <Header text="Transaction Delivery Status History" />
          <div>
            {transactionDetail.status_history &&
              transactionDetail.delivery_status_history.map(
                (history, index) => (
                  <div className="transaction-history" key={index}>
                    <span className="status">{history.status}</span>
                    <span className="history-date">{` on ${setPreferredTimeZone(
                      history.changed_at
                    )}`}</span>
                  </div>
                )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  connect(
    state => ({
      msbs: state.msb.data,
      mtos: state.mto.data
    }),
    dispatch => ({
      fetchMsbList: () => dispatch(fetchMsbList()),
      fetchMtoList: () => dispatch(fetchMtoList()),
      fetchTransactionDetail: (senderId, transactionId, onSuccess, onFailure) =>
        dispatch(
          fetchTransactionDetail(senderId, transactionId, onSuccess, onFailure)
        ),
      fetchTransactionInvoice: (
        senderId,
        transactionId,
        onSuccess,
        onFailure
      ) =>
        dispatch(
          fetchTransactionInvoice(senderId, transactionId, onSuccess, onFailure)
        )
    })
  )
)(TransactionDetail);
