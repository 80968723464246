import { TIER } from './actions';

export const fetchTierTwoList = () => ({
  type: TIER.FETCH_TIER_LIST,
  payload: {
    url: `/raas/admin/tiertwolist`,
    onSuccess: TIER.FETCH_TIER_LIST_SUCCESS,
    onFailure: TIER.FETCH_TIER_LIST_ERROR
  }
});

export const fetchTierThreeList = () => ({
  type: TIER.FETCH_TIER_LIST,
  payload: {
    url: `/raas/admin/tierthreelist`,
    onSuccess: TIER.FETCH_TIER_LIST_SUCCESS,
    onFailure: TIER.FETCH_TIER_LIST_ERROR
  }
});

export const reviewTierRequest = (senderId, payload) => ({
  type: TIER.REVIEW_TIER_CHANGE_REQUEST,
  payload: {
    url: `/raas/admin/senders/${senderId}/review-tierchangerequest`,
    data: payload,
    onSuccess: TIER.REVIEW_TIER_CHANGE_REQUEST_SUCCESS,
    onFailure: TIER.REVIEW_TIER_CHANGE_REQUEST_ERROR
  }
});
