import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getLoginUrl } from 'utils/cognitoUtils';
import { RoleBasedRoute } from './RoleBasedRoute';
import { securedLS } from 'utils/localStorageUtils';

import { saveUserCredentials } from 'actions/authAction';

/**
 * Component to authenticate routes.
 */
const PrivateRoute = props => {
  const { isLoggedIn, saveUserCredentials } = props;

  if (securedLS.get('userCredentials').data && isLoggedIn) {
    return <RedirectPage {...props} />;
  } else if (securedLS.get('userCredentials').data) {
    saveUserCredentials(JSON.parse(securedLS.get('userCredentials').data));

    return <RedirectPage {...props} />;
  }

  return <LoginPage {...props} />;
};

const RedirectPage = props => {
  return <RoleBasedRoute userRoles={props.userRoles} {...props} />;
};

const LoginPage = props => {
  sessionStorage.setItem('redirectUri', props.location.pathname);

  return window.open(getLoginUrl(), '_self');
};

export default compose(
  connect(
    state => ({
      userRoles: state.auth.userRoles,
      isLoggedIn: state.auth.isLoggedIn
    }),
    dispatch => ({
      saveUserCredentials: data => dispatch(saveUserCredentials(data))
    })
  )
)(withRouter(PrivateRoute));
