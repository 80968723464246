import React from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';

import { PAYOUT_PARTNER } from '../actions/actions';
import { ErrorToastMessage } from '@raas-dashboard/base';

const initialState = {
  data: []
};

const payoutPartnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYOUT_PARTNER.FETCH_SUCCEEDED:
      return produce(state, draft => {
        draft.data = action.payload;
      });

    case PAYOUT_PARTNER.FETCH_FAILED:
      toast.error(
        <ErrorToastMessage message="Network error. Couldn't fetch Payout Partners." />
      );

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    default:
      return state;
  }
};

export default payoutPartnerReducer;
