import React from 'react';

import './Loader.scss';

/**
 * Basic loader component.
 *
 * @returns {React.FC}
 */
export const Loader = () => {
  return (
    <span className="spinner" role="status">
      <span className="sr-only">Loading...</span>
    </span>
  );
};

/**
 * Fixed loader component.
 *
 * @returns {React.FC}
 */
export const FixedLoader = () => {
  return (
    <div className="fixed-loader">
      <div className="spinner-center" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
