import React from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'formik';

/**
 * Radio field component.
 *
 * @param {Object} props
 *
 * @returns {React.FC}
 */
export const RadioGroup = ({ values, label, ...props }) => {
  const [field] = useField(props);

  return (
    <>
      {label && <label className="radio__title-label">{label}</label>}
      {values.map((radio, index) => (
        <label className="radio__label" key={index}>
          <Field {...field} type="radio" value={radio} {...props} />
          {radio}
        </label>
      ))}
    </>
  );
};

RadioGroup.propTypes = {
  id: PropTypes.string,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  onCheck: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  values: PropTypes.array.isRequired
};

export default RadioGroup;
