import React from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';

import { KYC_TAG } from '../actions/actions';
import { ErrorToastMessage } from '@raas-dashboard/base';

const initialState = {
  data: {},
  error: '',
  isFetching: false
};

const kycTagReducer = (state = initialState, action) => {
  switch (action.type) {
    case KYC_TAG.FETCH:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case KYC_TAG.FETCH_SUCCEEDED:
      return produce(state, draft => {
        draft.isFetching = false;
        action.payload.forEach(obj => {
          draft.data[obj.tag_id] = obj;
        });
      });

    case KYC_TAG.FETCH_FAILED:
      toast.error(
        <ErrorToastMessage message="Network error. Couldn't fetch KYC Tags." />
      );

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    default:
      return state;
  }
};

export default kycTagReducer;
