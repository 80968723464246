import { STATE } from './actions';

export const fetchStates = countryCode => ({
  type: STATE.FETCH,
  payload: {
    //todo: api is only receiving the country ID
    url: `/raas/states?countryId=${2}`,
    onSuccess: STATE.FETCH_SUCCEEDED,
    onFailure: STATE.FETCH_FAILED
  }
});
