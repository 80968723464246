/**
 * Token generated by concatenating username and password with `:` character within a base64 encoded string.
 *
 * @param  {String} user
 * User identifier.
 *
 * @param  {String} password
 * Password.
 *
 * @return {String}
 * Base64 encoded authentication token.
 */

export const getBasicToken = (user, password) => {
  return `Basic ${base64([user, password].join(':'))}`;
};

/**
 * Base64 encode string.
 *
 * @param  {String} string
 * String input.
 *
 * @return {String}
 * Base64 encoded string.
 */

const base64 = string => {
  return new Buffer(string).toString('base64');
};
