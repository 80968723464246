import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Table } from '@raas-dashboard/base';
import { fetchCountries } from '../actions/countryAction';

class CountryInfo extends React.Component {
  componentDidMount() {
    this.props.fetchCountries();
  }

  render() {
    const cols = [
      { label: 'Name', field: 'name' },
      { label: 'ISO Code', field: 'three_char_code' }
    ];

    return (
      <div className="content-container">
        Country Info
        <Table
          cols={cols}
          data={Object.values(this.props.countries)}
          isLoading={this.props.isFetchingCountries}
        />
      </div>
    );
  }
}

export default compose(
  connect(
    state => ({
      countries: state.country.data,
      isFetchingCountries: state.country.isFetching
    }),
    dispatch => ({
      fetchCountries: _ => dispatch(fetchCountries())
    })
  )
)(CountryInfo);
