import { KYC_TAG } from './actions';

export const fetchKycTags = _ => ({
  type: KYC_TAG.FETCH,
  payload: {
    url: '/raas/cip-tags',
    onSuccess: KYC_TAG.FETCH_SUCCEEDED,
    onFailure: KYC_TAG.FETCH_FAILED
  }
});
