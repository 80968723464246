import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { getUrlParams } from 'utils/cognitoUtils';
import { isObjectEmpty } from 'utils/commonUtils';
import { authorizeUser } from 'actions/authAction';

import { FixedLoader } from '@raas-dashboard/base';

const Callback = props => {
  const redirectUri = sessionStorage.getItem('redirectUri') || '/';

  useEffect(() => {
    if (props.location.hash || props.location.search) {
      const code = getUrlParams(window.location.href).code;

      props.authorizeUser({ code });
    }
  }, []);

  return isObjectEmpty(props.error) ? (
    props.isLoggedIn ? (
      <Redirect to={redirectUri} />
    ) : (
      <FixedLoader />
    )
  ) : (
    <div className="content-container">
      You may have beed disabled. PLease contact your Administrator
    </div>
  );
};

export default compose(
  connect(
    state => ({
      isLoggedIn: state.auth.isLoggedIn,
      error: state.auth.error
    }),
    dispatch => ({
      authorizeUser: data => dispatch(authorizeUser(data))
    })
  )
)(Callback);
