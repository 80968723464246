import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

/**
 * Input field component.
 *
 * @param {Object} props
 *
 * @returns {React.FC}
 */
const InputField = props => {
  const { label } = props;
  const [field] = useField(props);

  return (
    <div className="form-group">
      <label>{label}</label>
      <input className={`form-control`} {...props} {...field} />
    </div>
  );
};

InputField.propTypes = {
  onBlur: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default InputField;
