import React from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'formik';

import './Form.scss';

/**
 * Select field component.
 *
 * @param {Object} props
 *
 * @returns {React.FC}
 */
const Select = ({ label, options, disabled, placeholder, ...props }) => {
  const [field] = useField(props);

  return (
    <div className="form-group">
      <label>{label}</label>
      <Field
        {...field}
        as="select"
        className="raas-select"
        disabled={disabled}
        {...props}
      >
        {placeholder && <option value={null}>{placeholder}</option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.name}
          </option>
        ))}
      </Field>
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};

export default Select;
