import { compose } from 'redux';
import { saveAs } from 'file-saver';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';

import { generateComponentId } from 'utils/commonUtils';
import { fetchTierThreeList, reviewTierRequest } from 'actions/tierAction';

import {
  Form,
  Table,
  Modal,
  Header,
  Button,
  InputField
} from '@raas-dashboard/base';

import './TierTwoVerification.scss';

const TierThreeVerification = props => {
  const { tiers, isFetching, fetchTierThreeList, reviewTierRequest } = props;
  useEffect(() => {
    fetchTierThreeList();
  }, []);

  return (
    <div className="content-container">
      <Header text="Tier 3 Verification" />
      <TiersTable
        tiers={Object.values(tiers)}
        isFetching={isFetching}
        reviewTierRequest={reviewTierRequest}
      />
    </div>
  );
};

const TiersTable = ({ tiers, reviewTierRequest, isFetching }) => {
  const [isDocumentVisible, setIsDocumentVisible] = useState(false);
  const [activeDocument, setActiveDocument] = useState('');
  const [isActionModalVisible, setIsActionModalVisible] = useState(false);
  const [activeTierInfo, setActiveTierInfo] = useState({});

  const cols = [
    { label: 'Full Name', field: 'senderName' },
    { label: 'Sender ID', field: 'senderId' },
    { label: 'Sender KYC ID', field: 'senderKycId' },
    { label: 'SSN', field: 'ssn' },
    { label: 'Failure Reason', field: 'failureReason' },
    { label: 'Occupation', field: 'occupation' },
    { label: 'Source of Fund', field: 'sourceOfFund' },
    { label: 'Document', field: 'document' },
    { label: 'Company Name', field: 'companyName' },
    { label: 'Designation', field: 'designation' },
    { label: 'Comment', field: 'comment' },
    { label: 'Actions', field: 'actions' }
  ];
  const saveImage = fileLink => {
    // const image = (fileLink.responseType = 'blob');
    // const image = new File(fileLink, );
    // debugger;

    saveAs(fileLink, 'img.jpg');
  };
  const reviewTier = (status, comment) => {
    const payload = {
      status,
      reviewerEmail: activeTierInfo.senderEmail,
      comment: comment
    };
    reviewTierRequest(activeTierInfo.senderId, payload);
    setIsActionModalVisible(false);
  };
  const mapTierData = tiers => {
    const mappedData = [];

    Object.values(tiers).forEach(tier => {
      mappedData.push({
        senderName: tier.senderName,
        senderId: (
          <Link to={`/senders/${tier.senderId}`} target="_blank">
            {tier.senderId}
          </Link>
        ),
        senderKycId: tier.kycId,
        ssn: tier.ssn,
        failureReason: tier.failureReason,
        occupation: tier.occupation,
        sourceOfFund: tier.sourceOfFund,
        companyName: tier.companyName,
        designation: tier.designation,
        failure_reason: tier.tierDocument
          ? tier.tierDocument.failure_reasons
          : null,
        document: tier.tierDocument ? (
          <div>
            <Button
              name="View"
              onClick={() => {
                setActiveDocument(tier.tierDocument.file_link);
                setIsDocumentVisible(true);
              }}
            />
            <a href={`data:${tier.tierDocument.file_link}`} download>
              {/* <Button onClick={() => saveImage(tier.tierDocument.file_link)}>
                download
              </Button> */}
              download
            </a>
          </div>
        ) : null,
        comment: tier.comment,
        actions: (
          <div>
            <Button
              name="View Actions"
              onClick={() => {
                setIsActionModalVisible(true);
                setActiveTierInfo(tier);
              }}
            />
          </div>
        )
      });
    });

    const sortedData = mappedData.sort((a, b) => {
      const dateA = new Date(a.doc_date_uploaded);
      const dateB = new Date(b.doc_date_uploaded);

      return dateB - dateA;
    });

    return sortedData;
  };

  return (
    <>
      {isDocumentVisible && (
        <Modal onClose={() => setIsDocumentVisible(false)}>
          <img
            src={activeDocument}
            className="document-view"
            alt="document preview"
          ></img>
        </Modal>
      )}
      {isActionModalVisible && (
        <Modal onClose={() => setIsActionModalVisible(false)}>
          <div className="modal-container">
            <Header text="Verification Actions" />
            <Form initialValues={{}}>
              <VerificationForm reviewTier={reviewTier} />
            </Form>
          </div>
        </Modal>
      )}

      <Table cols={cols} data={mapTierData(tiers)} isLoading={isFetching} />
    </>
  );
};

const VerificationForm = ({ reviewTier }) => {
  const { values } = useFormikContext();

  return (
    <>
      <InputField
        name="comment"
        label="Comment"
        id={generateComponentId('mto_reference_number', 'txn-filter')}
        value={values.comment}
      />
      <div className="tier-verification-action__button-wrapper">
        <div className="tier-verification-action__button">
          <Button
            className="btn btn-success"
            name="Approve"
            onClick={data => reviewTier('APPROVED', values.comment)}
          />
        </div>
        <div className="tier-verification-action__button">
          <Button
            className="btn btn-danger"
            name="Disapprove"
            onClick={data => reviewTier('DISAPPROVED', values.comment)}
          />
        </div>
        <div className="tier-verification-action__button">
          <Button
            className="btn btn-primary"
            name="Request for Document"
            onClick={data => reviewTier('DOCUMENT', values.comment)}
          />
        </div>
      </div>
    </>
  );
};

export default compose(
  connect(
    state => ({
      tiers: state.tier.data,
      isFetching: state.tier.isFetching
    }),
    dispatch => ({
      fetchTierThreeList: () => dispatch(fetchTierThreeList()),
      reviewTierRequest: (senderId, payload) =>
        dispatch(reviewTierRequest(senderId, payload))
    })
  )
)(TierThreeVerification);
