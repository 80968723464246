import React from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';

import { RECIPIENT } from 'actions/actions';
import { PAGE_SIZE } from 'constants/index';
import { ErrorToastMessage } from '@raas-dashboard/base';

const initialState = {
  data: {},
  error: '',
  paging: {
    page: 1,
    has_more: false,
    page_size: PAGE_SIZE
  },
  recipientDetail: {},
  isFetching: false
};

const recipientReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECIPIENT.FETCH:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case RECIPIENT.FETCH_SUCCEEDED:
      const { results, paging } = action.payload;
      return produce(state, draft => {
        draft.isFetching = false;
        results.forEach(obj => {
          draft.data[obj.id] = obj;
        });
        draft.paging = paging;
      });

    case RECIPIENT.FETCH_FAILED:
      toast.error(
        <ErrorToastMessage message="Network error. Couldn't fetch Recipients." />
      );

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    case RECIPIENT.FETCH_RECIPIENT_DETAIL:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case RECIPIENT.FETCH_RECIPIENT_DETAIL_SUCCEEDED:
      return produce(state, draft => {
        draft.isFetching = false;
        draft.recipientDetail = action.payload;
      });

    case RECIPIENT.FETCH_RECIPIENT_DETAIL_FAILED:
      toast.error(
        <ErrorToastMessage message="Network error. Couldn't fetch Recipient Details." />
      );

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    case RECIPIENT.INITIALIZE_RECIPIENTS:
      return produce(state, draft => {
        draft.data = {};
        draft.isFetching = false;
        draft.error = '';
        draft.paging = {
          page: 1,
          has_more: false,
          page_size: PAGE_SIZE
        };
        draft.recipientDetail = {};
      });

    default:
      return state;
  }
};

export default recipientReducer;
