import produce from 'immer';
import { compose } from 'redux';
import { useField } from 'formik';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';

import { Form, Button, RadioGroup } from '@raas-dashboard/base';

import {
  toggleComponent,
  saveOriginatorInfo,
  saveAffiliateDraft
} from 'actions/affiliateAction';

import { configuration } from 'components/affiliate/AffiliateForm';

const OriginatorConfiguration = props => {
  const [states, setStates] = useState({});
  const initialValues = props.initialValues;
  const handleSubmit = data => {
    if (props.onSubmit) {
      return props.onSubmit(data);
    }
    props.saveOriginatorInfo({ ...data });
    props.toggleComponent(configuration.PAYOUT_PARTNER);
  };

  useEffect(() => {
    setStateOriginators(props, setStates);
  }, []);

  return (
    <div className="container">
      <div>
        <h2>Sources States</h2>
      </div>
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        <FormElements
          states={states}
          isEditView={props.isEditView}
          affiliate={props.affiliate}
          saveAffiliateDraft={props.saveAffiliateDraft}
        />
      </Form>
    </div>
  );
};

const setStateOriginators = (props, setStates) => {
  const { states, originators } = props;
  const stateList = produce(states, draft => {
    for (let key in states) {
      draft[key].originators = getStateOriginators(states[key], originators);
    }
  });
  setStates(stateList);
};

const getStateOriginators = (state, originators) => {
  let stateList = [];
  Object.values(originators).forEach(originator => {
    if (originator.states.includes(state.code)) {
      stateList.push(originator.name);
    }
  });
  return stateList;
};

const showStateRow = state => {
  if (state.originators.length > 0) {
    return (
      <tr key={state.code}>
        <td>
          <label>{state.name}</label>
        </td>
        <td>
          <RadioGroup name={state.code} values={state.originators} />
        </td>
      </tr>
    );
  }
};

const FormElements = props => {
  const { affiliate, isEditView, saveAffiliateDraft } = props;
  const saveProgress = data => {
    const affiliateDraft = produce(affiliate, draft => {
      draft.originators = data;
    });
    saveAffiliateDraft(affiliateDraft);
  };
  const [field] = useField(props);

  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>State Name</th>
            <th>Available Originators</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(props.states).map(key =>
            showStateRow(props.states[key])
          )}
        </tbody>
      </Table>
      <div className="affiliate-form__button-wrapper">
        {isEditView ? (
          <div className="affiliate-form__button">
            <Button name="Save" type="submit" disabled={affiliate.isFetching} />
          </div>
        ) : (
          <>
            <div className="affiliate-form__button">
              <Button
                name="Next"
                type="submit"
                disabled={affiliate.isFetching}
              />
            </div>
            <div className="affiliate-form__button">
              <Button
                name="Save Progress"
                disabled={affiliate.isFetching}
                onClick={() => saveProgress(field.value)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default compose(
  connect(
    state => ({
      affiliate: state.affiliate,
      states: state.country.states,
      originators: state.originator.data
    }),
    dispatch => ({
      saveAffiliateDraft: draft => dispatch(saveAffiliateDraft(draft)),
      toggleComponent: component => dispatch(toggleComponent(component)),
      saveOriginatorInfo: originators =>
        dispatch(saveOriginatorInfo(originators))
    })
  )
)(OriginatorConfiguration);
