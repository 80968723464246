export const deliveryStatus = {
  HOLD: 'HOLD',
  DELIVERED: 'DELIVERED',
  DELIVERY_PAYOUT_READY: 'DELIVERY_PAYOUT_READY',
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  REQUESTED: 'REQUESTED'
};

export const deliveryStatusOptions = [
  { name: deliveryStatus.HOLD, value: deliveryStatus.HOLD },
  { name: deliveryStatus.DELIVERED, value: deliveryStatus.DELIVERED },
  {
    name: deliveryStatus.DELIVERY_PAYOUT_READY,
    value: deliveryStatus.DELIVERY_PAYOUT_READY
  },
  { name: deliveryStatus.PENDING, value: deliveryStatus.PENDING },
  { name: deliveryStatus.FAILED, value: deliveryStatus.FAILED },
  { name: deliveryStatus.REQUESTED, value: deliveryStatus.REQUESTED }
];
