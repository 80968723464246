import produce from 'immer';
import { SESSION } from '../actions/actions';

const initialState = {
  isLoggedIn: sessionStorage.getItem('authSession') || false,
  user: {
    userName: '',
    email: ''
  },
  credentials: {}
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION.SET:
      return produce(state, draft => {
        draft.user = action.session.user;
        draft.credentials = action.session.credentials;
        draft.isLoggedIn = true;
      });
    // return state.setIn(action.payload.key.split('.'), fromJS(action.payload.value));
    case SESSION.UNSET:
      sessionStorage.removeItem('authSession');
      return initialState;
    // return state.deleteIn(action.payload.key.split('.'));
    default:
      return state;
  }
};

export default sessionReducer;
