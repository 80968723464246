import React from 'react';
import { useFormikContext } from 'formik';

import { Form, RadioGroup } from '@raas-dashboard/base';
import './Preference.scss';

const Preference = props => {
  const initialValues = {
    timeZone: localStorage.getItem('timeZone') || 'Local Time'
  };
  console.log(initialValues);
  return (
    <div className="content-container">
      <h2>Preference</h2>
      <Form initialValues={initialValues}>
        <FormElements />
      </Form>
    </div>
  );
};

export const timeZones = {
  local: 'Local Time',
  pst: 'PST Time',
  utc: 'UTC time'
};

const FormElements = _ => {
  const { values } = useFormikContext();
  if (values.timeZone) {
    localStorage.setItem('timeZone', values.timeZone);
  } else {
    localStorage.setItem('timeZone', timeZones.local);
  }

  return (
    <>
      <div>
        <div>
          <span className="preference__label">Prefered Time Zone</span>
        </div>
        <RadioGroup name="timeZone" values={Object.values(timeZones)} />
      </div>
    </>
  );
};

export default Preference;
