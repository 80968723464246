import { TRANSACTION } from './actions';

export const fetchTransactions = (page, filter) => ({
  type: TRANSACTION.FETCH,
  payload: {
    url: `/raas/admin/summary-transactions?page=${page}&view=summary&${filter}`,
    onSuccess: TRANSACTION.FETCH_SUCCEEDED,
    onFailure: TRANSACTION.FETCH_FAILED
  }
});

export const fetchTransactionDetail = (
  senderId,
  transactionId,
  onSuccess,
  onFailure
) => ({
  type: TRANSACTION.FETCH_DETAIL,
  payload: {
    url: `/raas/admin/senders/${senderId}/transactions/${transactionId}`,
    onSuccess,
    onFailure
  }
});

export const fetchTransactionInvoice = (
  senderId,
  transactionId,
  onSuccess,
  onFailure
) => ({
  type: TRANSACTION.FETCH_INVOICE,
  payload: {
    url: `/raas/senders/${senderId}/transactions/${transactionId}/invoice`,
    onSuccess,
    onFailure
  }
});

export const initializeTransactions = () => ({
  type: TRANSACTION.INITIALIZE_TRANSACTIONS
});

export const fetchAllTransactions = (filter, onSuccess, onFailure) => ({
  type: TRANSACTION.FETCH_ALL_TRANSACTIONS,
  payload: {
    url: `/raas/admin/transactions?${filter}`,
    onSuccess,
    onFailure
  }
});
