import { put, call } from 'redux-saga/effects';

import { get } from './api';

export function* fetchSenders({ payload }) {
  try {
    const { data } = yield call(get, payload);
    yield put({ type: payload.onSuccess, payload: data });
  } catch (error) {
    yield put({ type: payload.onFailure, error });
  }
}

export function* fetchSenderDetail({ payload }) {
  try {
    const { data } = yield call(get, payload);
    yield put({ type: payload.onSuccess, payload: data });
  } catch (error) {
    yield put({ type: payload.onFailure, error });
  }
}

export function* fetchAllSenders({ payload }) {
  try {
    const { data } = yield call(get, payload);
    payload.onSuccess(data);
  } catch (error) {
    payload.onFailure(error);
  }
}
