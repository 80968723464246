import PropTypes from 'prop-types';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const exportReport = ({ exportCols, exportData, fileName, fileExtension }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const csvArray = [];
  const header = [];

  exportCols.forEach(col => {
    header.push(col.label);
  });
  csvArray.push(header);

  for (let i = 0; i < exportData.length; i++) {
    const row = [];

    exportCols.forEach(col => {
      row.push(exportData[i][col.field]);
    });
    csvArray.push(row);
  }

  const ws = XLSX.utils.json_to_sheet(csvArray, { skipHeader: true });
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, fileName + fileExtension);
};

exportReport.propTypes = {
  exportCols: PropTypes.array,
  exportData: PropTypes.array,
  fileName: PropTypes.string,
  fileExtension: PropTypes.string
};

export default exportReport;
