const config = {
    server: {
        url: 'https://portal.machpay.com/api/v1/'
    },
    reactAppEncryptionSecret: 'R@@$-Pr0ducti0n-$ecret',
    auth: {
        clientId: '2osjrt8ld8e4rh4oqnto7aoibc',
        callbackUri: 'https://portal.machpay.com/callback',
        signinUri: 'https://admin-dashboard.auth.us-east-1.amazoncognito.com/login',
        signoutUri: 'https://admin-dashboard.auth.us-east-1.amazoncognito.com/logout',
        scope: [
            'aws.cognito.signin.user.admin',
            'email',
            'phone',
            'openid',
            'profile'
        ],
        responseType: 'code'
    }
};
export default config;

