import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';

import { history } from './history';
import { routes } from 'constants/routes';
import { userRoles } from 'components/common/RoleBasedRoute';
import { getLoginUrl, getLogoutUrl } from 'utils/cognitoUtils';

import PrivateRoute from 'components/common/PrivateRoute';

import Callback from './Callback';
import App from 'components/index';
import MainWrapper from './MainWrapper';
import CountryInfo from 'components/CountryList';
import Preference from './preference/Preference';
import ExchangeRate from './exchange-rate/ExchangeRate';
import SenderRoutes from 'components/sender/SenderRoutes';
import RecipientRoutes from 'components/recipient/RecipientRoutes';
import AffiliateRoutes from 'components/affiliate/AffiliateRoutes';
import AdminToolRoutes from 'components/adminTools/AdminToolRoutes';
import TransactionRoutes from 'components/transaction/TransactionRoutes';

// Top level application router.
const RaasRouter = _ => (
  <Router history={history}>
    <MainWrapper>
      <Switch>
        <PrivateRoute
          exact
          path={routes.HOME}
          component={App}
          roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
        />
        <Route exact path={routes.CALLBACK} component={Callback} />
        <PrivateRoute exact path={routes.COUNTRY} component={CountryInfo} />
        <PrivateRoute
          exact
          path={routes.PREFERENCE}
          component={Preference}
          roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
        />

        <Route path="/affiliates">
          <AffiliateRoutes />
        </Route>

        <Route path="/senders">
          <SenderRoutes />
        </Route>

        <Route path="/recipients">
          <RecipientRoutes />
        </Route>

        <Route path="/transactions">
          <TransactionRoutes />
        </Route>

        <Route path="/admin-tools">
          <AdminToolRoutes />
        </Route>

        <PrivateRoute
          exact
          path={routes.EXCHANGE_RATE}
          component={ExchangeRate}
          roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
        />
      </Switch>
    </MainWrapper>
  </Router>
);

export default RaasRouter;
