import { put, call } from 'redux-saga/effects';

import { get, put as putApi } from './api';

export function* fetchTierList({ payload }) {
  try {
    const { data } = yield call(get, payload);
    yield put({ type: payload.onSuccess, payload: data });
  } catch (error) {
    yield put({ type: payload.onFailure, error });
  }
}

export function* reviewTierRequest({ payload }) {
  try {
    const { data } = yield call(putApi, payload);
    yield put({ type: payload.onSuccess, payload: data });
  } catch (error) {
    yield put({ type: payload.onFailure, error });
  }
}
