import { SENDER } from './actions';

export const fetchSenders = (page, filter) => ({
  type: SENDER.FETCH,
  payload: {
    url: `/raas/admin/summary-senders?page=${page}&${filter}`,
    onSuccess: SENDER.FETCH_SUCCEEDED,
    onFailure: SENDER.FETCH_FAILED
  }
});

export const initializeSenders = () => ({
  type: SENDER.INITIALIZE_SENDERS
});

export const fetchSenderDetail = senderId => ({
  type: SENDER.FETCH_SENDER_DETAIL,
  payload: {
    url: `/raas/admin/senders/${senderId}`,
    onSuccess: SENDER.FETCH_SENDER_DETAIL_SUCCEEDED,
    onFailure: SENDER.FETCH_SENDER_DETAIL_FAILED
  }
});

export const fetchRecipientsOfSender = senderId => ({
  type: SENDER.FETCH_RECIPIENTS_OF_SENDER,
  payload: {
    url: `/raas/admin/senders/${senderId}/recipients`,
    onSuccess: SENDER.FETCH_RECIPIENTS_OF_SENDER_SUCCEEDED,
    onFailure: SENDER.FETCH_RECIPIENTS_OF_SENDER_FAILED
  }
});

export const fetchTransactionsOfSender = senderId => ({
  type: SENDER.FETCH_TRANSACTIONS_OF_SENDER,
  payload: {
    url: `/raas/admin/senders/${senderId}/transactions`,
    onSuccess: SENDER.FETCH_TRANSACTIONS_OF_SENDER_SUCCEEDED,
    onFailure: SENDER.FETCH_TRANSACTIONS_OF_SENDER_FAILED
  }
});

export const fetchFundingSourcesOfSender = senderId => ({
  type: SENDER.FETCH_FUNDING_SOURCES_OF_SENDER,
  payload: {
    url: `/raas/senders/${senderId}/funding-sources`,
    onSuccess: SENDER.FETCH_FUNDING_SOURCES_OF_SENDER_SUCCEEDED,
    onFailure: SENDER.FETCH_FUNDING_SOURCES_OF_SENDER_FAILED
  }
});

export const fetchAllSenders = (onSuccess, onFailure) => ({
  type: SENDER.FETCH_ALL_SENDERS,
  payload: {
    url: `/raas/admin/senders`,
    onSuccess,
    onFailure
  }
});
