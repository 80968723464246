import { EXCHANGE_RATE } from './actions';

export const fetchExchangeRates = provider => ({
  type: EXCHANGE_RATE.FETCH,
  payload: {
    url: `/exchange-rate?provider=${provider}`,
    onSuccess: EXCHANGE_RATE.FETCH_SUCCEEDED,
    onFailure: EXCHANGE_RATE.FETCH_FAILED
  }
});
