import produce from 'immer';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';

import { fetchMsbList } from 'actions/msbAction';
import { fetchMtoList } from 'actions/mtoAction';
import { fetchStates } from 'actions/stateAction';
import {
  fetchSenders,
  initializeSenders,
  fetchAllSenders
} from 'actions/senderAction';
import {
  isObjectEmpty,
  getQueryString,
  generateComponentId,
  setPreferredTimeZone
} from 'utils/commonUtils';
import {
  Form,
  Table,
  Modal,
  Header,
  Select,
  Button,
  Loader,
  Checkbox,
  InputField,
  DatePicker,
  exportReport,
  ErrorToastMessage,
  SuccessToastMessage
} from '@raas-dashboard/base';
import { Link } from 'react-router-dom';

import './Senders.scss';

const Senders = props => {
  const {
    msbs,
    mtos,
    states,
    senders,
    hasMore,
    isFetching,
    fetchStates,
    fetchSenders,
    fetchMsbList,
    fetchMtoList,
    fetchAllSenders,
    initializeSenders
  } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(20);
  const [filter, setFilter] = useState({});
  const [displayData, setDisplayData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const cols = [
    { label: 'ID', field: 'id' },
    { label: 'KYC ID', field: 'payment_processor_account_id' },
    { label: 'Name', field: 'full_name' },
    { label: 'Email', field: 'email' },
    { label: 'State', field: 'state' },
    { label: 'Sign Up Date', field: 'date' },
    { label: 'KYC Status', field: 'status' }
  ];

  const getVisibleData = (dataArray, page) => {
    const slicedDataArray = dataArray
      .sort((a, b) => {
        const dateA = new Date(a.signup_date);
        const dateB = new Date(b.signup_date);

        return dateB - dateA;
      })
      .slice((page - 1) * pageSize, page * pageSize);
    const mappedData = produce(slicedDataArray, draft => {
      for (let key in slicedDataArray) {
        draft[key].status = (
          <span
            className={`user-status ${slicedDataArray[
              key
            ].status.toLowerCase()}`}
          >
            {slicedDataArray[key].status}
          </span>
        );
        draft[key].date = setPreferredTimeZone(
          slicedDataArray[key].signup_date
        );
        draft[key].onRowClick = () =>
          window.open(`/senders/${slicedDataArray[key].id}`, '_blank');
      }
    });

    return mappedData;
  };

  const hasNextPage =
    hasMore || getVisibleData(Object.values(senders), currentPage + 1).length;
  const pagination = {
    currentPage,
    pageSize,
    hasNextPage,
    fetchPrevious: () => fetchPrevious(currentPage),
    fetchNext: () => fetchNext(fetchSenders, currentPage)
  };

  const fetchPrevious = currentPage => {
    setCurrentPage(currentPage - 1);
  };

  const fetchNext = (fetchSenders, currentPage) => {
    const nextPage = currentPage + 1;

    setCurrentPage(nextPage);
    if (!getVisibleData(Object.values(senders), nextPage).length) {
      fetchSenders(nextPage, getQueryString(filter));
    }
  };

  useEffect(() => {
    if (isObjectEmpty(states)) {
      fetchStates();
    }
    if (isObjectEmpty(msbs)) {
      fetchMsbList();
    }
    if (isObjectEmpty(mtos)) {
      fetchMtoList();
    }
  }, []);

  useEffect(() => {
    if (!isObjectEmpty(msbs)) {
      setFilter({ msb_id: Object.keys(msbs)[0] });
    }
  }, [props.msbs]);

  useEffect(() => {
    const initialPage = 1;

    setCurrentPage(initialPage);
    initializeSenders();

    if (filter.msb_id) {
      fetchSenders(initialPage, getQueryString(filter));
    }
  }, [filter]);

  useEffect(() => {
    setIsLoading(true);
    setDisplayData(getVisibleData(Object.values(senders), currentPage));
  }, [filter, currentPage, senders]);

  useEffect(() => {
    setIsLoading(false);
  }, [displayData]);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  const [isExporting, setIsExporting] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);

  const handleExport = exportCols => {
    const onSuccess = data => {
      const exportData = data.results;
      exportReport({
        exportCols,
        exportData,
        fileName: `Sender_Report_${moment().format('YYYY-MM-DD_HH-mm-ss')}`,
        fileExtension: '.xlsx'
      });
      setIsExporting(false);
      setIsExportModalVisible(false);
      toast.success(<SuccessToastMessage message="Sender Report Exported" />);
    };
    const onFailure = () => {
      toast.error(
        <ErrorToastMessage message="Failed to export sender report." />
      );
      setIsExporting(false);
      setIsExportModalVisible(false);
    };
    setIsExporting(true);
    fetchAllSenders(onSuccess, onFailure);
  };

  return (
    <div className="content-container">
      <Header text="Sender Report" />
      {isFilterVisible && (
        <Filter
          filter={filter}
          setFilter={setFilter}
          states={states}
          msbs={msbs}
          mtos={mtos}
        />
      )}
      <div className="toggle-filter__wrapper">
        {isFilterVisible ? (
          <span className="toggle-filter__button" onClick={toggleFilter}>
            Hide Filter{' '}
            <i class="fa fa-chevron-circle-up" aria-hidden="true"></i>
          </span>
        ) : (
          <span className="toggle-filter__button" onClick={toggleFilter}>
            Show Filter{' '}
            <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
          </span>
        )}
      </div>
      {isExportModalVisible && (
        <ExportModal
          onSubmit={handleExport}
          onClose={() => setIsExportModalVisible(false)}
          isExporting={isExporting}
        />
      )}
      <div className="sender-export__container">
        <button
          className="sender-export__button"
          onClick={() => setIsExportModalVisible(true)}
          disabled={isExporting}
        >
          <span>
            <i class="fas fa-download"></i> Export
          </span>
        </button>
      </div>
      <Table
        cols={cols}
        data={displayData}
        isLoading={isFetching || isLoading}
        pagination={pagination}
      />
    </div>
  );
};

const ExportModal = ({ onSubmit, onClose, isExporting }) => {
  const exportCols = [
    { label: 'ID', field: 'id' },
    { label: 'Email', field: 'email' },
    { label: 'Sign Up Date', field: 'signup_date' },
    { label: 'Full Name', field: 'full_name' },
    { label: 'Status', field: 'status' },
    { label: 'City', field: 'city' },
    { label: 'State', field: 'state' },
    { label: 'gender', field: 'gender' },
    { label: 'SSN', field: 'ssn' },
    { label: 'Current Tier', field: 'tier' },
    { label: 'Zip code', field: 'zipcode' },
    { label: 'Date of Birth', field: 'date_of_birth' },
    { label: 'Address Line 1', field: 'address_line1' },
    { label: 'Address Line 2', field: 'address_line2' },
    { label: 'Home Phone', field: 'home_phone' },
    { label: 'Work Phone', field: 'work_phone' },
    { label: 'Mobile Phone', field: 'mobile_phone' },
    { label: 'Source of Income', field: 'source_of_income' },
    { label: 'Sender Document ID Number', field: 'document_id' },
    { label: 'MTO', field: 'mto_id' },
    { label: 'MSB', field: 'msb_id' }
  ];
  const selectedExportFields = {
    id: true,
    email: true,
    signup_date: true,
    full_name: true,
    status: true,
    city: true,
    state: true,
    gender: true,
    ssn: true,
    tier: true,
    zipcode: true,
    date_of_birth: true,
    address_line1: true,
    address_line2: true,
    home_phone: true,
    work_phone: true,
    mobile_phone: true,
    source_of_income: true,
    document_id: true,
    mto_id: true,
    msb_id: true
  };
  const handleSubmit = data => {
    const selectedExportCols = [];
    exportCols.forEach(exportCol => {
      if (data.exportFields[exportCol.field]) {
        selectedExportCols.push(exportCol);
      }
    });

    onSubmit(selectedExportCols);
  };

  return (
    <Modal onClose={onClose}>
      <div className="container">
        <div>
          <Header text="Export Sender Report" />
        </div>
        <Form
          initialValues={{ exportFields: selectedExportFields }}
          onSubmit={handleSubmit}
        >
          <div className="sender-export__field-selector">
            {exportCols.map(col => (
              <Checkbox label={col.label} name={`exportFields[${col.field}]`} />
            ))}
          </div>
          <div className="sender-export__btn-container">
            <Button
              className="sender-export__button"
              type="submit"
              disabled={isExporting}
            >
              <span>
                <i class="fas fa-download"></i> Export
              </span>
              {isExporting && (
                <div className="sender-export__loader">
                  <Loader />
                </div>
              )}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const Filter = ({ filter, setFilter, states, msbs, mtos }) => {
  const msbOptions = (msbs = {}) => {
    const result = [];
    produce(msbs, draft => {
      for (let key in msbs) {
        result.push({
          name: msbs[key].name,
          value: msbs[key].id
        });
      }
    });

    return result;
  };
  const mtoOptions = (mtos = {}) => {
    const result = [];
    produce(mtos, draft => {
      for (let key in mtos) {
        result.push({
          name: mtos[key].name,
          value: mtos[key].id
        });
      }
    });

    return result;
  };
  const stateOptions = (states = {}) => {
    const result = [];
    produce(states, draft => {
      for (let key in states) {
        result.push({
          name: states[key].name,
          value: states[key].code
        });
      }
    });

    return result;
  };
  const senderKycStatusOptions = [
    { name: 'VERIFIED', value: 'VERIFIED' },
    { name: 'DOCUMENT', value: 'DOCUMENT' },
    { name: 'DOCUMENT_REQUESTED', value: 'DOCUMENT_REQUESTED' },
    { name: 'UNVERIFIED', value: 'UNVERIFIED' },
    { name: 'RETRY', value: 'RETRY' },
    { name: 'RETRY_REQUESTED', value: 'RETRY_REQUESTED' },
    { name: 'REVIEW_PENDING', value: 'REVIEW_PENDING' },
    { name: 'IN_PROGRESS', value: 'IN_PROGRESS' },
    { name: 'SUSPENDED', value: 'SUSPENDED' }
  ];
  const submitFilter = filter => {
    setFilter(filter);
  };
  const FormElements = props => {
    const { values, setValues } = useFormikContext();
    const {
      email,
      state,
      msb_id,
      mto_id,
      last_name,
      first_name,
      kyc_status,
      signup_date
    } = values;
    return (
      <>
        <div className="sender-filter__element">
          <InputField
            name="first_name"
            id={generateComponentId('first_name', 'sender-filter')}
            label="First Name"
            value={first_name}
          />
        </div>
        <div className="sender-filter__element">
          <InputField
            name="last_name"
            id={generateComponentId('last_name', 'sender-filter')}
            label="Last Name"
            value={last_name}
          />
        </div>
        <div className="sender-filter__element">
          <InputField
            name="email"
            id={generateComponentId('email', 'sender-filter')}
            label="Email"
            value={email}
          />
        </div>
        <div className="sender-filter__element">
          <Select
            name="msb_id"
            id={generateComponentId('msb_id', 'sender-filter')}
            label="MSB"
            placeholder="Select MSB"
            options={msbOptions(msbs)}
            value={msb_id}
          />
        </div>
        <div className="sender-filter__element">
          <Select
            name="mto_id"
            id={generateComponentId('mto_id', 'sender-filter')}
            label="Affiliate"
            placeholder="Select Affiliate"
            options={mtoOptions(mtos)}
            value={mto_id}
          />
        </div>
        <div className="sender-filter__element">
          <Select
            name="state"
            id={generateComponentId('state', 'sender-filter')}
            label="State"
            placeholder="Select State"
            options={stateOptions(states)}
            value={state}
          />
        </div>
        <div className="sender-filter__element">
          <Select
            name="kyc_status"
            id={generateComponentId('kyc_status', 'sender-filter')}
            label="KYC Status"
            placeholder="Select KYC Status"
            options={senderKycStatusOptions}
            value={kyc_status}
          />
        </div>
        <div className="sender-filter__element">
          <DatePicker
            name="signup_date"
            id={generateComponentId('signup_date', 'sender-filter')}
            label="Sign up Date"
            disabled
            value={signup_date}
          />
        </div>

        <div className="sender-filter__button-wrapper">
          <div className="sender-filter__button">
            <Button className="btn btn-primary" name="Filter" type="submit" />
          </div>
          <div className="sender-filter__button">
            <Button
              className="btn btn-primary"
              name="Reset"
              type="reset"
              onClick={() => {
                setValues({ msb_id: Object.keys(msbs)[0] });
                setFilter({ msb_id: Object.keys(msbs)[0] });
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="sender-filter">
      <Form initialValues={filter} onSubmit={submitFilter}>
        <FormElements />
      </Form>
    </div>
  );
};

export default compose(
  connect(
    state => ({
      msbs: state.msb.data,
      mtos: state.mto.data,
      senders: state.sender.data,
      states: state.country.states,
      isFetching: state.sender.isFetching,
      hasMore: state.sender.paging.has_more
    }),
    dispatch => ({
      fetchMsbList: () => dispatch(fetchMsbList()),
      fetchMtoList: () => dispatch(fetchMtoList()),
      fetchStates: country => dispatch(fetchStates(country)),
      initializeSenders: () => dispatch(initializeSenders()),
      fetchSenders: (page, filter) => dispatch(fetchSenders(page, filter)),
      fetchAllSenders: (onSuccess, onFailure) =>
        dispatch(fetchAllSenders(onSuccess, onFailure))
    })
  )
)(Senders);
