import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import Header from '../header/Header';

import './SideNav.scss';

const SideNav = ({ menuData, title, activeTab }) => {
  return (
    <div className="side-nav">
      {title && <Header text={title} />}
      {menuData.map(menu =>
        menu.route ? (
          <Link
            to={menu.route}
            key={menu.label}
            className={`side-nav__menu-items ${useLocation().pathname ===
              menu.route && 'active'}`}
          >
            {menu.label}
          </Link>
        ) : (
          <Link
            onClick={menu.onClick}
            key={menu.label}
            className={`side-nav__menu-items ${activeTab === menu.name &&
              'active'}`}
          >
            {menu.label}
          </Link>
        )
      )}
    </div>
  );
};

SideNav.propTypes = {
  title: PropTypes.string,
  activeTab: PropTypes.string,
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      route: PropTypes.string,
      onClick: PropTypes.func,
      name: PropTypes.name
    })
  )
};

export default SideNav;
