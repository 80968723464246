import produce from 'immer';
import moment from 'moment';
import { timeZones } from 'components/preference/Preference';

export const toStatesObject = array => {
  return produce({}, draft => {
    array.forEach(arrayElement => {
      return (draft[arrayElement.code] = arrayElement);
    });
  });
};

export const toCountriesObject = array => {
  return produce({}, draft => {
    array.forEach(arrayElement => {
      return (draft[arrayElement.three_char_code] = arrayElement);
    });
  });
};

/**
 * Generate unique id for DOM component.
 *
 * @param {String} name
 * @param {String} component
 * @returns {String}
 */
export const generateComponentId = (name, component) =>
  `prj-${component}_${name}`;

export const isObjectEmpty = obj => {
  return Object.keys(obj).length <= 0;
};

export const isObject = arg => {
  return toString.call(arg) === '[object Object]';
};

export const withError = arg => {
  if (isObject(arg)) {
    const { message = '', ...rest } = arg;

    return {
      data: null,
      error: {
        status: true,
        message,
        ...rest
      }
    };
  }

  return {
    data: null,
    error: {
      status: true,
      message: arg
    }
  };
};

export const withData = data => {
  return {
    error: false,
    data
  };
};

export const getCurrentPage = (offset, pageSize) => {
  return (offset - 1) / pageSize + 1;
};

export const getQueryString = object => {
  let query = [];
  for (let key in object) {
    if (object[key]) {
      query.push(`${key}=${object[key]}`);
    }
  }

  return query.join('&');
};

export const setPreferredTimeZone = utcTime => {
  switch (localStorage.getItem('timeZone')) {
    case timeZones.pst:
      return moment(moment.utc(utcTime))
        .utcOffset('-08:00')
        .format('YYYY-MM-DD, h:mm:ss a');

    case timeZones.utc:
      return moment.utc(utcTime).format('YYYY-MM-DD, h:mm:ss a');

    default:
      return moment.utc(utcTime).local().format('YYYY-MM-DD, h:mm:ss a');
  }
};
