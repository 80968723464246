import produce from 'immer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';

import { history } from 'components/history';
import { Table, Header } from '@raas-dashboard/base';
import { fetchAffiliates } from 'actions/affiliateAction';

import './Affiliates.scss';

const Affiliates = props => {
  const { isFetching, affiliates, fetchAffiliates } = props;
  const cols = [
    { label: 'ID', field: 'id' },
    { label: 'Name', field: 'name' },
    { label: 'Corridors', field: 'corridorsDisplay' },
    {
      label: 'Enabled Originators',
      field: 'enabledOriginatorsDisplay'
    },
    { label: 'Payment Methods', field: 'paymentMethodsDisplay' },
    { label: 'Payout Methods', field: 'payoutMethodsDisplay' },
    {
      label: 'Approval Required',
      field: 'approvalRequiredDisplay'
    },
    { label: 'Actions', field: 'actions' }
  ];

  const getVisibleData = affiliates => {
    const viewAffiliateDetail = id => {
      history.push(`/affiliates/${id}`);
    };
    const mappedData = produce(affiliates, draft => {
      for (let key in affiliates) {
        const {
          id,
          corridors,
          paymentMethod,
          originators,
          payoutMethod,
          approvalRequired
        } = affiliates[key];
        draft[key].corridorsDisplay = corridors.join(', ');
        draft[key].paymentMethodsDisplay = paymentMethod.join(', ');
        draft[key].enabledOriginatorsDisplay = originators.join(', ');
        draft[key].payoutMethodsDisplay = payoutMethod.join(', ');
        draft[key].approvalRequiredDisplay = approvalRequired ? (
          <span className="approval-status required">required</span>
        ) : (
          <span className="approval-status approved">approved</span>
        );
        draft[key].actions = (
          <button
            onClick={() => {
              viewAffiliateDetail(id);
            }}
          >
            View Detail
          </button>
        );
      }
    });

    return Object.values(mappedData);
  };

  useEffect(() => {
    fetchAffiliates();
  }, []);

  return (
    <div className="content-container">
      <Header text="Affiliates" />
      <Table
        cols={cols}
        data={getVisibleData(affiliates)}
        isLoading={isFetching}
      />
    </div>
  );
};

export default compose(
  connect(
    state => ({
      affiliates: state.affiliate.data,
      isFetching: state.affiliate.isFetching
    }),
    dispatch => ({
      fetchAffiliates: _ => dispatch(fetchAffiliates())
    })
  )
)(Affiliates);
