import React from 'react';
import { Switch } from 'react-router-dom';

import { routes } from 'constants/routes';
import { userRoles } from 'components/common/RoleBasedRoute';

import PrivateRoute from 'components/common/PrivateRoute';

import Senders from 'components/sender/Senders';
import SenderDetail from 'components/sender/SenderDetail';
import RecipientDetail from 'components/recipient/RecipientDetail';
import TransactionDetail from 'components/transaction/TransactionDetail';

const SenderRoutes = _ => (
  <Switch>
    <PrivateRoute
      exact
      path={routes.SENDER}
      component={Senders}
      roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
    />
    <PrivateRoute
      exact
      path={routes.SENDER_DETAIL}
      component={SenderDetail}
      roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
    />
    <PrivateRoute
      exact
      path={routes.RECIPIENT_DETAIL}
      component={RecipientDetail}
      roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
    />
    <PrivateRoute
      exact
      path={routes.TRANSACTION_DETAIL}
      component={TransactionDetail}
      roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
    />
  </Switch>
);

export default SenderRoutes;
