export const routes = {
  HOME: '/',
  SENDER: '/senders',
  COUNTRY: '/countries',
  CALLBACK: '/callback',
  DASHBOARD: '/dashboard',
  AFFILIATE: '/affiliates',
  RECIPIENT: '/recipients',
  PREFERENCE: '/preference',
  TRANSACTION: '/transactions',
  REFUND: '/transactions/refunds',
  RETURN: '/transactions/returns',
  ADD_AFFILIATE: '/affiliates/add',
  EXCHANGE_RATE: '/exchange-rates',
  SENDER_DETAIL: '/senders/:sender_id',
  HOLD_TRANSACTION: '/transactions/holds',
  AFFILIATE_DETAIL: '/affiliates/:affiliate_id',
  TIER_2_VERIFICATION: '/admin-tools/tier-2-verification',
  TIER_3_VERIFICATION: '/admin-tools/tier-3-verification',
  CHANGE_DELIVERY_STATUS: '/admin-tools/change-delivery-status',
  RECIPIENT_DETAIL: '/senders/:sender_id/recipients/:recipient_id',
  CHANGE_TRANSACTION_STATUS: '/admin-tools/change-transaction-status',
  TRANSACTION_DETAIL: '/senders/:sender_id/transactions/:transaction_id'
};
