import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';

import AffiliateReview from './AffiliateReview';
import TierConfiguration from './configuration/TierConfiguration';
import BasicInfoConfiguration from './configuration/BasicInfoConfiguration';
import OriginatorConfiguration from './configuration/OriginatorConfiguration';
import PayoutPartnerConfiguration from './configuration/PayoutPartnerConfiguration';
import ServiceProviderConfiguration from './configuration/ServiceProviderConfiguration';

import { fetchStates } from 'actions/stateAction';
import { fetchKycTags } from 'actions/kycTagAction';
import { fetchCountries } from 'actions/countryAction';
import { fetchOriginators } from 'actions/originatorAction';
import { fetchPayoutPartners } from 'actions/payoutPartnerAction';
import { fetchServiceProviders } from 'actions/serviceProviderAction';
import { toggleComponent } from 'actions/affiliateAction';

import { HorizontalNav } from '@raas-dashboard/base';

import './Affiliates.scss';

export const configuration = {
  BASIC_INFO: 'basic info',
  ORIGINATOR: 'originator',
  PAYOUT_PARTNER: 'payout partner',
  SERVICE_PROVIDER: 'service provider',
  TIER_1: 'tier 1',
  TIER_2: 'tier 2',
  TIER_3: 'tier 3',
  REVIEW: 'review'
};

const AffiliateForm = props => {
  const {
    affiliate,
    fetchStates,
    fetchKycTags,
    fetchCountries,
    toggleComponent,
    fetchOriginators,
    fetchPayoutPartners,
    fetchServiceProviders
  } = props;
  const { activeConfiguration } = affiliate;

  useEffect(() => {
    fetchStates('USA');
    fetchKycTags();
    fetchCountries();
    fetchOriginators();
    fetchPayoutPartners();
    fetchServiceProviders();
  }, []);
  const menuData = [
    {
      name: configuration.BASIC_INFO,
      label: 'Basic Info',
      onClick: () => toggleComponent(configuration.BASIC_INFO)
    },
    {
      name: configuration.ORIGINATOR,
      label: 'Originators',
      onClick: () => toggleComponent(configuration.ORIGINATOR)
    },
    {
      name: configuration.PAYOUT_PARTNER,
      label: 'Payout Partners',
      onClick: () => toggleComponent(configuration.PAYOUT_PARTNER)
    },
    {
      name: configuration.SERVICE_PROVIDER,
      label: 'Service Providers',
      onClick: () => toggleComponent(configuration.SERVICE_PROVIDER)
    },
    {
      name: configuration.TIER_1,
      label: 'Tiers',
      onClick: () => toggleComponent(configuration.TIER_1)
    },
    {
      name: configuration.REVIEW,
      label: 'Review',
      onClick: () => toggleComponent(configuration.REVIEW)
    }
  ];

  return (
    <div className="content-container">
      <HorizontalNav
        activeTab={activeConfiguration || configuration.BASIC_INFO}
        menuData={menuData}
      />
      <ActiveConfiguration
        affiliate={affiliate}
        activeConfiguration={activeConfiguration}
      />
    </div>
  );
};

const ActiveConfiguration = ({ affiliate, activeConfiguration }) => {
  switch (activeConfiguration) {
    case configuration.ORIGINATOR:
      return <OriginatorConfiguration initialValues={affiliate.originators} />;

    case configuration.PAYOUT_PARTNER:
      return (
        <PayoutPartnerConfiguration initialValues={affiliate.payoutPartners} />
      );

    case configuration.SERVICE_PROVIDER:
      return (
        <ServiceProviderConfiguration
          initialValues={affiliate.serviceProviders}
        />
      );

    case configuration.TIER_1:
      return <TierConfiguration level={1} key={1} />;

    case configuration.TIER_2:
      return <TierConfiguration level={2} key={2} />;

    case configuration.TIER_3:
      return <TierConfiguration level={3} key={3} />;

    case configuration.REVIEW:
      return <AffiliateReview />;

    default:
      return <BasicInfoConfiguration initialValues={affiliate.basicInfo} />;
  }
};

export default compose(
  connect(
    state => ({
      affiliate: state.affiliate
    }),
    dispatch => ({
      fetchKycTags: _ => dispatch(fetchKycTags()),
      fetchCountries: _ => dispatch(fetchCountries()),
      fetchOriginators: _ => dispatch(fetchOriginators()),
      fetchPayoutPartners: _ => dispatch(fetchPayoutPartners()),
      fetchServiceProviders: _ => dispatch(fetchServiceProviders()),
      fetchStates: countryCode => dispatch(fetchStates(countryCode)),
      toggleComponent: component => dispatch(toggleComponent(component))
    })
  )
)(AffiliateForm);
