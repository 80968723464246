import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';

export const userRoles = {
  SUPER_ADMIN: 'ROLE_ADMIN',
  MSB: 'ROLE_MSB',
  MTO: 'ROLE_MTO'
};

export const isAuthorized = (grantedRoles = [], userRoles = []) => {
  let permissionGranted = false;

  if (grantedRoles.length < 1) {
    return true;
  }
  userRoles.forEach(userRole => {
    if (grantedRoles.includes(userRole)) {
      permissionGranted = true;
    }
  });

  return permissionGranted;
};

export const RoleBasedRoute = ({
  component: Component,
  roles: grantedRoles,
  userRoles,
  ...props
}) => {
  return (
    <>
      {isAuthorized(grantedRoles, userRoles) && (
        <Route {...props} render={props => <Component {...props} />} />
      )}
      {!isAuthorized(grantedRoles, userRoles) && (
        <Route render={() => <>Unauthorized Access.</>} />
      )}
    </>
  );
};

export const ComponentAccess = ({ children, roles, ...props }) => {
  const isPermitted = isAuthorized(roles, props.userRoles);

  return <>{isPermitted && children}</>;
};

export const RoleAccess = compose(
  connect(
    state => ({
      userRoles: state.auth.userRoles
    }),
    dispatch => ({})
  )
)(withRouter(ComponentAccess));
