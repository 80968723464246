import produce from 'immer';

import { AUTH } from '../actions/actions';
import { getLogoutUrl } from 'utils/cognitoUtils';
import { storage, securedLS, KS_KEY } from 'utils/localStorageUtils';

const initialState = {
  isLoggedIn: false,
  user: {},
  token: '',
  userRoles: [],
  error: {}
  //todo: for now manually setting userRoles, need to fetch from user credentials
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH.USER_AUTHORIZATION_PENDING:
      storage.clear();

      return produce(state, draft => {
        draft.isLoggedIn = false;
      });

    case AUTH.USER_AUTHORIZATION_SUCCESS:
      securedLS.set(KS_KEY.TOKEN, action.payload.token);
      securedLS.set('userCredentials', JSON.stringify(action.payload));

      return produce(state, draft => {
        draft.isLoggedIn = true;
        draft.user = action.payload.user;
        draft.token = action.payload.token;
        draft.tokenType = action.payload.tokenType;
        draft.userRoles = action.payload.user.roles;
      });

    case AUTH.USER_AUTHORIZATION_FAILED:
      return produce(state, draft => {
        draft.error = action.error;
      });

    case AUTH.SAVE_USER_CREDENTIALS:
      return produce(state, draft => {
        draft.isLoggedIn = true;
        draft.user = action.payload.user;
        draft.token = action.payload.token;
        draft.tokenType = action.payload.tokenType;
        draft.userRoles = action.payload.user.roles;
      });

    case AUTH.SIGN_OUT_SUCCESS:
      produce(state, draft => {
        draft.isLoggedIn = false;
        draft.user = {};
        draft.token = '';
        draft.tokenType = '';
        draft.userRoles = [];
      });
      storage.clear();
      window.open(getLogoutUrl(), '_self');
      return;

    default:
      return state;
  }
};

export default authReducer;
