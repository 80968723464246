export const mapToAffiliateRequest = (affiliate, originatorList) => {
  const {
    tiers,
    basicInfo,
    originators,
    payoutPartners,
    serviceProviders
  } = affiliate;
  const basicInfoRequestData = basicInfo;
  const originatorRequestData = mapToOriginatorInfoRequest(
    originators,
    originatorList
  );
  const tierInfoRequestData = mapToTierInfoRequest(tiers);
  const payoutPartnerRequestData = mapToPayoutPartnersRequest(payoutPartners);
  const serviceProviderRequestData = mapToServiceProviderRequest(
    serviceProviders
  );

  return {
    basicInfo: basicInfoRequestData,
    licensedStates: originatorRequestData,
    tiers: tierInfoRequestData,
    serviceProviders: serviceProviderRequestData,
    payouts: payoutPartnerRequestData
  };
};

export const mapToOriginatorInfoRequest = (originators, originatorList) => {
  let result = [];
  Object.keys(originators).forEach(key => {
    Object.values(originatorList).forEach(originator => {
      if (originator.name === originators[key]) {
        result.push({
          state: key,
          originator: originator.id
        });
      }
    });
  });
  return result;
};

export const mapToOriginatorInfoData = (originators, originatorList) => {
  let result = {};
  originators.forEach(originator => {
    Object.values(originatorList).forEach(originatorElement => {
      if (originatorElement.id === originator.originator) {
        result[originator.state] = originatorElement.name;
      }
    });
  });
  return result;
};

const mapToTierInfoRequest = tiers => {
  const { level1, level2, level3 } = tiers;
  const mappedData = [];
  if (level1) {
    mappedData.push({
      level: 'LEVEL1',
      tags: level1.tags,
      txnLimit: level1.limit
    });
  }
  if (level2) {
    mappedData.push({
      level: 'LEVEL2',
      tags: level2.tags,
      txnLimit: level2.limit
    });
  }
  if (level3) {
    mappedData.push({
      level: 'LEVEL3',
      tags: level3.tags,
      txnLimit: level3.limit
    });
  }

  return mappedData;
};

export const mapToPayoutPartnersRequest = payoutPartners => {
  let result = [];
  Object.keys(payoutPartners).forEach(key => {
    if (
      payoutPartners[key].BANK_DEPOSIT &&
      payoutPartners[key].BANK_DEPOSIT.value &&
      payoutPartners[key].BANK_DEPOSIT.payoutPartner
    ) {
      result.push({
        provider: payoutPartners[key].BANK_DEPOSIT.payoutPartner,
        country: key,
        payoutMethod: 'BANK_DEPOSIT'
      });
    }
    if (
      payoutPartners[key].CASH_PICKUP &&
      payoutPartners[key].CASH_PICKUP.value &&
      payoutPartners[key].CASH_PICKUP.payoutPartner
    ) {
      result.push({
        provider: payoutPartners[key].CASH_PICKUP.payoutPartner,
        country: key,
        payoutMethod: 'CASH_PICKUP'
      });
    }
  });

  return result;
};

export const mapToPayoutPartnersData = payoutPartners => {
  const result = {};

  payoutPartners.forEach(payoutPartner => {
    result[payoutPartner.country] = {
      ...result[payoutPartner.country],
      [payoutPartner.payoutMethod]: {
        value: true,
        payoutPartner: payoutPartner.provider
      }
    };
  });

  return result;
};

export const mapToServiceProviderRequest = serviceProviders => {
  let result = [];
  Object.keys(serviceProviders).forEach(key => {
    if (serviceProviders[key].DEFAULT) {
      result.push({
        type: key,
        provider: serviceProviders[key].DEFAULT.serviceProvider
      });
    }
    if (serviceProviders[key].CARD) {
      result.push({
        type: key,
        provider: serviceProviders[key].CARD.serviceProvider,
        fundingSourceType: 'CARD'
      });
    }
    if (serviceProviders[key].ACH) {
      result.push({
        type: key,
        provider: serviceProviders[key].ACH.serviceProvider,
        fundingSourceType: 'BANK'
      });
    }
  });
  return result;
};

export const mapToServiceProvidersData = serviceProviders => {
  const result = {};
  serviceProviders.forEach(serviceProvider => {
    if (serviceProvider.fundingSourceType) {
      result[serviceProvider.type] = {
        ...result[serviceProvider.type],
        [serviceProvider.fundingSourceType]: {
          value: true,
          serviceProvider: serviceProvider.provider
        }
      };
    } else {
      result[serviceProvider.type] = {
        ...result[serviceProvider.type],
        DEFAULT: {
          serviceProvider: serviceProvider.provider
        }
      };
    }
  });

  return result;
};
