import React from 'react';
import PropTypes from 'prop-types';

/**
 * Button component.
 *
 * @param {Object} props
 *
 * @returns {React.FC}
 */
export const Button = ({ name, disabled = false, children, ...props }) => {
  return (
    <button {...props} disabled={disabled}>
      {children || name}
    </button>
  );
};

Button.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Button;
