import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form as FormikForm } from 'formik';

/**
 * Form component.
 *
 * @param {Object} props
 *
 * @returns {React.FC}
 */
const Form = ({ initialValues, validationSchema, children, onSubmit }) => {
  const handleSubmit = data => {
    onSubmit(data);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={true}
      onSubmit={handleSubmit}
    >
      {() => <FormikForm>{children}</FormikForm>}
    </Formik>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  validationSchema: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Form;
