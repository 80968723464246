import produce from 'immer';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';

import './Recipients.scss';
import {
  isObjectEmpty,
  getQueryString,
  generateComponentId
} from 'utils/commonUtils';
import { Link } from 'react-router-dom';
import {
  Form,
  Table,
  Modal,
  Header,
  Select,
  Loader,
  Button,
  Checkbox,
  InputField,
  exportReport,
  ErrorToastMessage,
  SuccessToastMessage
} from '@raas-dashboard/base';
import { fetchMsbList } from 'actions/msbAction';
import { fetchMtoList } from 'actions/mtoAction';
import {
  fetchRecipients,
  fetchAllRecipients,
  initializeRecipients
} from 'actions/recipientAction';

const Recipients = props => {
  const {
    msbs,
    mtos,
    hasMore,
    recipients,
    isFetching,
    fetchMsbList,
    fetchMtoList,
    fetchRecipients,
    fetchAllRecipients,
    initializeRecipients
  } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(20);
  const [filter, setFilter] = useState({});
  const [displayData, setDisplayData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const cols = [
    { label: 'ID', field: 'id' },
    { label: 'Name', field: 'full_name' },
    { label: 'Email', field: 'email' },
    { label: 'Sender Email', field: 'sender_email' },
    { label: 'Address', field: 'address_line1' },
    { label: 'Status', field: 'status' }
  ];

  const getVisibleData = (dataArray, page) => {
    const slicedDataArray = dataArray
      .sort((a, b) => {
        const dateA = new Date(a.date_created);
        const dateB = new Date(b.date_created);

        return dateB - dateA;
      })
      .slice((page - 1) * pageSize, page * pageSize);
    const mappedData = produce(slicedDataArray, draft => {
      for (let key in slicedDataArray) {
        draft[key].status = (
          <span
            className={`user-status ${slicedDataArray[
              key
            ].verification_status.toLowerCase()}`}
          >
            {slicedDataArray[key].verification_status}
          </span>
        );
        draft[key].onRowClick = () =>
          window.open(
            `/senders/${slicedDataArray[key].sender_id}/recipients/${slicedDataArray[key].id}`,
            '_blank'
          );
      }
    });

    return mappedData;
  };

  const hasNextPage =
    hasMore ||
    getVisibleData(Object.values(recipients), currentPage + 1).length;
  const pagination = {
    currentPage,
    pageSize,
    hasNextPage,
    fetchPrevious: () => fetchPrevious(currentPage),
    fetchNext: () => fetchNext(fetchRecipients, currentPage)
  };

  const fetchPrevious = currentPage => {
    setCurrentPage(currentPage - 1);
  };

  const fetchNext = (fetchRecipients, currentPage) => {
    const nextPage = currentPage + 1;

    setCurrentPage(nextPage);
    if (!getVisibleData(Object.values(recipients), nextPage).length) {
      fetchRecipients(nextPage, getQueryString(filter));
    }
  };

  useEffect(() => {
    if (isObjectEmpty(msbs)) {
      fetchMsbList();
    }
    if (isObjectEmpty(mtos)) {
      fetchMtoList();
    }
  }, []);

  useEffect(() => {
    if (!isObjectEmpty(msbs)) {
      setFilter({ msb_id: Object.keys(msbs)[0] });
    }
  }, [props.msbs]);

  useEffect(() => {
    const initialPage = 1;

    setCurrentPage(initialPage);
    initializeRecipients();

    if (filter.msb_id) {
      fetchRecipients(initialPage, getQueryString(filter));
    }
  }, [filter]);

  useEffect(() => {
    setIsLoading(true);
    setDisplayData(getVisibleData(Object.values(recipients), currentPage));
  }, [filter, currentPage, recipients]);

  useEffect(() => {
    setIsLoading(false);
  }, [displayData]);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  const [isExporting, setIsExporting] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);

  const mapDataForExport = data => {
    const mappedData = [];

    data.forEach(recipient => {
      const { recipient_accounts } = recipient;
      mappedData.push({
        id: recipient.id,
        full_name: recipient.full_name,
        verification_status: recipient.verification_status,
        bank_name: recipient_accounts[0] && recipient_accounts[0].bank_name,
        account_number:
          recipient_accounts[0] && recipient_accounts[0].account_number,
        account_type:
          recipient_accounts[0] && recipient_accounts[0].account_type,
        email: recipient.email,
        date_created: recipient.date_created,
        address_line1: recipient.address_line1,
        address_line2: recipient.address_line2,
        city: recipient.city,
        province: recipient.province,
        postal_code: recipient.postal_code,
        country: recipient.country,
        home_phone: recipient.home_phone,
        work_phone: recipient.work_phone,
        mobile_phone: recipient.mobile_phone,
        sender_relationship: recipient.sender_relationship
      });
    });
    return mappedData;
  };

  const handleExport = exportCols => {
    const onSuccess = data => {
      const exportData = mapDataForExport(data.results);
      exportReport({
        exportCols,
        exportData,
        fileName: `Recipient_Report_${moment().format('YYYY-MM-DD_HH-mm-ss')}`,
        fileExtension: '.xlsx'
      });
      setIsExporting(false);
      setIsExportModalVisible(false);
      toast.success(
        <SuccessToastMessage message="Recipient Report Exported" />
      );
    };
    const onFailure = () => {
      toast.error(
        <ErrorToastMessage message="Failed to export recipient report." />
      );
      setIsExporting(false);
      setIsExportModalVisible(false);
    };
    setIsExporting(true);
    fetchAllRecipients(onSuccess, onFailure);
  };

  return (
    <div className="content-container">
      <Header text="Recipient Report" />
      {isFilterVisible && (
        <Filter filter={filter} setFilter={setFilter} msbs={msbs} mtos={mtos} />
      )}
      <div className="toggle-filter__wrapper">
        {isFilterVisible ? (
          <span className="toggle-filter__button" onClick={toggleFilter}>
            Hide Filter{' '}
            <i class="fa fa-chevron-circle-up" aria-hidden="true"></i>
          </span>
        ) : (
          <span className="toggle-filter__button" onClick={toggleFilter}>
            Show Filter{' '}
            <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
          </span>
        )}
      </div>
      {isExportModalVisible && (
        <ExportModal
          onSubmit={handleExport}
          onClose={() => setIsExportModalVisible(false)}
          isExporting={isExporting}
        />
      )}
      <div className="recipient-export__container">
        <button
          className="recipient-export__button"
          onClick={() => setIsExportModalVisible(true)}
          disabled={isExporting}
        >
          <span>
            <i class="fas fa-download"></i> Export
          </span>
        </button>
      </div>

      <Table
        cols={cols}
        data={displayData}
        isLoading={isFetching || isLoading}
        pagination={pagination}
      />
    </div>
  );
};

const ExportModal = ({ onSubmit, onClose, isExporting }) => {
  const exportCols = [
    { label: 'ID', field: 'id' },
    { label: 'Full Name', field: 'full_name' },
    { label: 'Status', field: 'verification_status' },
    { label: 'Bank Name', field: 'bank_name' },
    { label: 'Account Number', field: 'account_number' },
    { label: 'Account Type', field: 'account_type' },
    { label: 'Email', field: 'email' },
    { label: 'Beneficiary Added Date', field: 'date_created' },
    { label: 'Address Line 1', field: 'address_line1' },
    { label: 'Address Line 2', field: 'address_line2' },
    { label: 'City', field: 'city' },
    { label: 'Province', field: 'province' },
    { label: 'Postal Code', field: 'postal_code' },
    { label: 'Country', field: 'country' },
    { label: 'Home Phone', field: 'home_phone' },
    { label: 'Work Phone', field: 'work_phone' },
    { label: 'Mobile Phone', field: 'mobile_phone' },
    { label: 'Relationship', field: 'sender_relationship' }
  ];
  const selectedExportFields = {
    id: true,
    full_name: true,
    verification_status: true,
    bank_name: true,
    account_number: true,
    account_type: true,
    email: true,
    date_created: true,
    address_line1: true,
    address_line2: true,
    city: true,
    province: true,
    postal_code: true,
    country: true,
    home_phone: true,
    work_phone: true,
    mobile_phone: true,
    sender_relationship: true
  };
  const handleSubmit = data => {
    const selectedExportCols = [];
    exportCols.forEach(exportCol => {
      if (data.exportFields[exportCol.field]) {
        selectedExportCols.push(exportCol);
      }
    });

    onSubmit(selectedExportCols);
  };

  return (
    <Modal onClose={onClose}>
      <div className="container">
        <div>
          <Header text="Export Recipient Report" />
        </div>
        <Form
          initialValues={{ exportFields: selectedExportFields }}
          onSubmit={handleSubmit}
        >
          <div className="recipient-export__field-selector">
            {exportCols.map(col => (
              <Checkbox label={col.label} name={`exportFields[${col.field}]`} />
            ))}
          </div>
          <div className="recipient-export__btn-container">
            <Button
              className="recipient-export__button"
              type="submit"
              disabled={isExporting}
            >
              <span>
                <i class="fas fa-download"></i> Export
              </span>
              {isExporting && (
                <div className="recipient-export__loader">
                  <Loader />
                </div>
              )}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const Filter = ({ filter, setFilter, msbs, mtos }) => {
  const msbOptions = (msbs = {}) => {
    const result = [];
    produce(msbs, draft => {
      for (let key in msbs) {
        result.push({
          name: msbs[key].name,
          value: msbs[key].id
        });
      }
    });

    return result;
  };
  const mtoOptions = (mtos = {}) => {
    const result = [];
    produce(mtos, draft => {
      for (let key in mtos) {
        result.push({
          name: mtos[key].name,
          value: mtos[key].id
        });
      }
    });

    return result;
  };

  const statusOptions = [
    { name: 'VERIFIED', value: 'VERIFIED' },
    { name: 'UNVERIFIED', value: 'UNVERIFIED' }
  ];
  const submitFilter = filter => {
    const updatedFilter = produce(filter, draft => {
      if (filter.start_date && filter.end_date) {
        draft.start_date = `btn:${filter.start_date},${filter.end_date}`;
      } else {
        draft.start_date = undefined;
      }
      draft.end_date = undefined;
    });
    setFilter(updatedFilter);
  };
  const FormElements = props => {
    const { values, setValues } = useFormikContext();
    const {
      id,
      email,
      msb_id,
      mto_id,
      status,
      last_name,
      first_name,
      sender_email
    } = values;
    return (
      <>
        <div className="recipient-filter__element">
          <InputField
            name="id"
            id={generateComponentId('id', 'recipient-filter')}
            label="Reference ID"
            value={id}
          />
        </div>
        <div className="recipient-filter__element">
          <InputField
            name="first_name"
            id={generateComponentId('first_name', 'recipient-filter')}
            label="First Name"
            value={first_name}
          />
        </div>
        <div className="recipient-filter__element">
          <InputField
            name="last_name"
            id={generateComponentId('last_name', 'recipient-filter')}
            label="Last Name"
            value={last_name}
          />
        </div>
        <div className="recipient-filter__element">
          <InputField
            name="email"
            id={generateComponentId('email', 'recipient-filter')}
            label="Email"
            value={email}
          />
        </div>
        <div className="recipient-filter__element">
          <InputField
            name="sender_email"
            id={generateComponentId('sender_email', 'sender-filter')}
            label="Sender Email"
            value={sender_email}
          />
        </div>
        <div className="recipient-filter__element">
          <Select
            name="msb_id"
            id={generateComponentId('msb_id', 'recipient-filter')}
            label="MSB"
            placeholder="Select MSB"
            options={msbOptions(msbs)}
            value={msb_id}
          />
        </div>
        <div className="recipient-filter__element">
          <Select
            name="mto_id"
            id={generateComponentId('mto_id', 'recipient-filter')}
            label="Affiliate"
            placeholder="Select Affiliate"
            options={mtoOptions(mtos)}
            value={mto_id}
          />
        </div>
        <div className="recipient-filter__element">
          <Select
            name="status"
            id={generateComponentId('status', 'recipient-filter')}
            label="Status"
            placeholder="Select Status"
            options={statusOptions}
            value={status}
          />
        </div>

        <div className="recipient-filter__button-wrapper">
          <div className="recipient-filter__button">
            <Button className="btn btn-primary" name="Filter" type="submit" />
          </div>
          <div className="recipient-filter__button">
            <Button
              name="Reset"
              className="btn btn-primary"
              type="reset"
              onClick={() => {
                setValues({ msb_id: Object.keys(msbs)[0] });
                setFilter({ msb_id: Object.keys(msbs)[0] });
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="recipient-filter">
      <Form initialValues={filter} onSubmit={submitFilter}>
        <FormElements />
      </Form>
    </div>
  );
};

export default compose(
  connect(
    state => ({
      msbs: state.msb.data,
      mtos: state.mto.data,
      recipients: state.recipient.data,
      isFetching: state.recipient.isFetching,
      currentPage: state.recipient.paging.page,
      pageSize: state.recipient.paging.page_size,
      hasMore: state.recipient.paging.has_more
    }),
    dispatch => ({
      fetchMsbList: () => dispatch(fetchMsbList()),
      fetchMtoList: () => dispatch(fetchMtoList()),
      initializeRecipients: () => dispatch(initializeRecipients()),
      fetchRecipients: (page, filter) =>
        dispatch(fetchRecipients(page, filter)),
      fetchAllRecipients: (onSuccess, onFailure) =>
        dispatch(fetchAllRecipients(onSuccess, onFailure))
    })
  )
)(Recipients);
