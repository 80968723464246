import React from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';

import { PAGE_SIZE } from 'constants/index';
import { TRANSACTION } from 'actions/actions';
import { ErrorToastMessage } from '@raas-dashboard/base';

const initialState = {
  data: {},
  error: '',
  paging: {
    page: 1,
    has_more: false,
    page_size: PAGE_SIZE
  },
  isFetching: false
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRANSACTION.FETCH:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case TRANSACTION.FETCH_SUCCEEDED:
      const { results, paging } = action.payload;
      return produce(state, draft => {
        draft.isFetching = false;
        results.forEach(obj => {
          draft.data[obj.id] = obj;
        });
        draft.paging = paging;
      });

    case TRANSACTION.FETCH_FAILED:
      toast.error(
        <ErrorToastMessage message="Network error. Couldn't fetch Transaction." />
      );

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    case TRANSACTION.INITIALIZE_TRANSACTIONS:
      return produce(state, draft => {
        draft.data = {};
        draft.isFetching = false;
        draft.error = '';
        draft.paging = {
          page: 1,
          has_more: false,
          page_size: PAGE_SIZE
        };
      });

    default:
      return state;
  }
};

export default transactionReducer;
