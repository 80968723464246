import { compose } from 'redux';
import { saveAs } from 'file-saver';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';

import { generateComponentId } from 'utils/commonUtils';
import { fetchTierTwoList, reviewTierRequest } from 'actions/tierAction';

import {
  Form,
  Table,
  Modal,
  Header,
  Button,
  InputField
} from '@raas-dashboard/base';

import './TierTwoVerification.scss';

const TierTwoVerification = props => {
  const { tiers, isFetching, fetchTierTwoList, reviewTierRequest } = props;
  useEffect(() => {
    fetchTierTwoList();
  }, []);

  const sortData = data => {
    const sortedData = data.sort((a, b) => {
      const dateA = new Date(a.tierDocument.date_uploaded);
      const dateB = new Date(b.tierDocument.date_uploaded);

      return dateB - dateA;
    });
    return sortedData;
  };

  return (
    <div className="content-container">
      <Header text="Tier 2 Verification" />
      <TiersTable
        tiers={sortData(Object.values(tiers))}
        isFetching={isFetching}
        reviewTierRequest={reviewTierRequest}
      />
    </div>
  );
};

const TiersTable = ({ tiers, reviewTierRequest, isFetching }) => {
  const [isDocumentVisible, setIsDocumentVisible] = useState(false);
  const [activeDocument, setActiveDocument] = useState('');
  const [isActionModalVisible, setIsActionModalVisible] = useState(false);
  const [activeTierInfo, setActiveTierInfo] = useState({});

  const cols = [
    { label: 'Sender', field: 'sender_name' },
    { label: 'Sender ID', field: 'sender_id' },
    { label: 'Sender KYC ID', field: 'sender_kyc_id' },
    { label: 'Doc Type', field: 'doc_type' },
    { label: 'Doc ID', field: 'doc_id' },
    { label: 'Expiry Date', field: 'doc_expiry_date' },
    { label: 'Issue Authority', field: 'issued_authority' },
    { label: 'Document Status', field: 'doc_status' },
    { label: 'Failure Reason', field: 'failure_reason' },
    { label: 'Document', field: 'document' },
    { label: 'Comment', field: 'comment' },
    { label: 'Actions', field: 'actions' }
  ];
  const saveImage = fileLink => {
    // const image = (fileLink.responseType = 'blob');
    // const image = new File(fileLink, );
    // debugger;

    saveAs(fileLink, 'img.jpg');
  };
  const reviewTier = (status, comment) => {
    const payload = {
      status,
      reviewerEmail: activeTierInfo.senderEmail,
      comment: comment
    };
    reviewTierRequest(activeTierInfo.senderId, payload);
    setIsActionModalVisible(false);
  };
  const mapTierData = tiers => {
    const mappedData = [];

    Object.values(tiers).forEach(tier => {
      mappedData.push({
        sender_name: tier.senderName,
        sender_id: (
          <Link to={`/senders/${tier.senderId}`} target="_blank">
            {tier.senderId}
          </Link>
        ),
        sender_kyc_id: tier.kycId,
        doc_type: tier.tierDocument && tier.tierDocument.document_type,
        doc_id: tier.tierDocument && tier.tierDocument.document_id,
        doc_expiry_date: tier.tierDocument && tier.tierDocument.expiry_date,
        issued_authority:
          tier.tierDocument && tier.tierDocument.id_issuing_authority,
        doc_status:
          tier.tierDocument && tier.tierDocument.document_review_state,
        failure_reason: tier.tierDocument && tier.tierDocument.failure_reasons,
        document: (
          <div>
            <Button
              name="View"
              onClick={() => {
                setActiveDocument(tier.tierDocument.file_link);
                setIsDocumentVisible(true);
              }}
            />
            {tier.tierDocument ? (
              <a href={`data:${tier.tierDocument.file_link}`} download>
                {/* <Button onClick={() => saveImage(tier.tierDocument.file_link)}>
                download
              </Button> */}
                download
              </a>
            ) : null}
          </div>
        ),
        comment: tier.comment,
        actions: (
          <div>
            <Button
              name="View Actions"
              onClick={() => {
                setIsActionModalVisible(true);
                setActiveTierInfo(tier);
              }}
            />
          </div>
        )
      });
    });

    return mappedData;
  };

  return (
    <>
      {isDocumentVisible && (
        <Modal onClose={() => setIsDocumentVisible(false)}>
          <img
            src={activeDocument}
            className="document-view"
            alt="document preview"
          ></img>
        </Modal>
      )}
      {isActionModalVisible && (
        <Modal onClose={() => setIsActionModalVisible(false)}>
          <div className="modal-container">
            <Header text="Verification Actions" />
            <Form initialValues={{}}>
              <VerificationForm reviewTier={reviewTier} />
            </Form>
          </div>
        </Modal>
      )}

      <Table cols={cols} data={mapTierData(tiers)} isLoading={isFetching} />
    </>
  );
};

const VerificationForm = ({ reviewTier }) => {
  const { values } = useFormikContext();

  return (
    <>
      <InputField
        name="comment"
        label="Comment"
        id={generateComponentId('mto_reference_number', 'txn-filter')}
        value={values.comment}
      />
      <div className="tier-verification-action__button-wrapper">
        <div className="tier-verification-action__button">
          <Button
            className="btn btn-success"
            name="Approve"
            onClick={data => reviewTier('APPROVED', values.comment)}
          />
        </div>
        <div className="tier-verification-action__button">
          <Button
            className="btn btn-danger"
            name="Disapprove"
            onClick={data => reviewTier('DISAPPROVED', values.comment)}
          />
        </div>
        <div className="tier-verification-action__button">
          <Button
            className="btn btn-primary"
            name="Request for Document"
            onClick={data => reviewTier('DOCUMENT', values.comment)}
          />
        </div>
      </div>
    </>
  );
};

export default compose(
  connect(
    state => ({
      tiers: state.tier.data,
      isFetching: state.tier.isFetching
    }),
    dispatch => ({
      fetchTierTwoList: () => dispatch(fetchTierTwoList()),
      reviewTierRequest: (senderId, payload) =>
        dispatch(reviewTierRequest(senderId, payload))
    })
  )
)(TierTwoVerification);
