import React from 'react';
import PropTypes from 'prop-types';
import { Table as BootstrapTable } from 'react-bootstrap';

import { FixedLoader } from '../loader/Loader';

/**
 * Display key and value info table component without pagination.
 *
 * @param {Object} props
 *
 * @returns {React.FC}
 */
export const InfoTable = ({ cols, data = {}, isLoading }) => (
  <div className="loader-wrapper">
    {isLoading && <FixedLoader />}
    <BootstrapTable borderless className="data-table" size="sm">
      <tbody>
        {cols.map((col, index) => (
          <tr key={index}>
            <td className="info-table__key">{col.label}</td>{' '}
            <td className="info-table__value">{data[col.field]}</td>
          </tr>
        ))}
      </tbody>
    </BootstrapTable>
  </div>
);

InfoTable.propTypes = {
  cols: PropTypes.array,
  data: PropTypes.object,
  isLoading: PropTypes.bool
};

export default InfoTable;
