import produce from 'immer';

import { toStatesObject, toCountriesObject } from 'utils/index';
import { STATE, COUNTRY } from '../actions/actions';

const initialState = {
  data: {},
  states: {},
  error: '',
  isFetching: false
};

const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case COUNTRY.FETCH:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case COUNTRY.FETCH_SUCCEEDED:
      return produce(state, draft => {
        draft.isFetching = false;
        draft.data = toCountriesObject(action.payload.results);
      });

    case COUNTRY.FETCH_FAILED:
      return produce(state, draft => {
        draft.isFetching = false;
        draft.countryError = action.payload;
      });

    case STATE.FETCH_SUCCEEDED:
      return produce(state, draft => {
        draft.isFetching = false;
        draft.states = toStatesObject(action.payload.results);
      });

    default:
      return state;
  }
};

export default countriesReducer;
