import { MSB } from './actions';

export const fetchMsbList = () => ({
  type: MSB.FETCH,
  payload: {
    url: '/raas/msbs',
    onSuccess: MSB.FETCH_SUCCEEDED,
    onFailure: MSB.FETCH_FAILED
  }
});
