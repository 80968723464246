import * as Yup from 'yup';
import produce from 'immer';
import { compose } from 'redux';
import { useField } from 'formik';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import { InputField, Form, Button, Checkbox } from '@raas-dashboard/base';

import {
  saveTierInfo,
  toggleComponent,
  saveAffiliateDraft
} from 'actions/affiliateAction';

import { error } from 'constants/messages';
import { generateComponentId } from 'utils/index';
import { configuration } from 'components/affiliate/AffiliateForm';

const input = {
  dailyLimit: {
    name: 'dailyLimit',
    label: 'Daily Limit',
    type: 'number'
  },
  singleTxnlimit: {
    name: 'singleTxnlimit',
    label: 'Per Transaction Limit',
    type: 'number'
  },
  fortnightlyLimit: {
    name: 'fortnightlyLimit',
    label: 'Fortnightly Limit',
    type: 'number'
  },
  monthlyLimit: {
    name: 'monthlyLimit',
    label: 'Monthly Limit',
    type: 'number'
  },
  semiAnnualLimit: {
    name: 'semiAnnualLimit',
    label: 'Semi Annual Limit',
    type: 'number'
  }
};

const PayoutPartnerConfiguration = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const handleSubmit = data => {
    let tags = [];
    if (data.requiredKyc) {
      Object.keys(data.requiredKyc).forEach(key => {
        if (data.requiredKyc[key] && data.requiredKyc[key].value) {
          tags.push(key);
        }
      });
    }
    const params = {
      tags,
      limit: data.limit
    };
    props.saveTierInfo(props.level, params);

    switch (props.level) {
      case 1:
        return props.toggleComponent(configuration.TIER_2);

      case 2:
        return props.toggleComponent(configuration.TIER_3);

      default:
        return props.toggleComponent(configuration.REVIEW);
    }
  };

  const { kycTags } = props;
  const initialValues = props.affiliate.tiers[`level${props.level}`] || {};
  const validationSchema = Yup.object({});

  return (
    <div className="container">
      <div>
        <h2>Tier Configuration</h2>
      </div>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        buttonName={props.level === 3 ? 'Save' : 'Next'}
      >
        <FormElements
          level={props.level}
          kycTags={kycTags}
          affiliate={props.affiliate}
          saveAffiliateDraft={props.saveAffiliateDraft}
        />
      </Form>
    </div>
  );
};

const FormElements = props => {
  const { affiliate, kycTags, saveAffiliateDraft } = props;
  const saveProgress = data => {
    const affiliateDraft = produce(affiliate, draft => {
      draft.tiers = data;
    });
    saveAffiliateDraft(affiliateDraft);
  };
  const [field] = useField(props);

  return (
    <>
      {showTierForm(props.level, Object.values(kycTags))}
      <div className="affiliate-form__button-wrapper">
        <div className="affiliate-form__button">
          <Button className="btn btn-primary" name="Next" type="submit" disabled={affiliate.isFetching} />
        </div>
        <div className="affiliate-form__button">
          <Button
            name="Save Progress"
            className="btn btn-primary"
            disabled={affiliate.isFetching}
            onClick={() => saveProgress(field.value)}
          />
        </div>
      </div>
    </>
  );
};

const showTierLimitForm = level => {
  const {
    dailyLimit,
    singleTxnlimit,
    monthlyLimit,
    fortnightlyLimit,
    semiAnnualLimit
  } = input;

  return (
    <React.Fragment>
      <Row>
        <Col className="col-md-6">
          <InputField
            name={`limit.${singleTxnlimit.name}`}
            id={generateComponentId(singleTxnlimit.name, 'tier-configuration')}
            label={singleTxnlimit.label}
          />
        </Col>
        <Col className="col-md-6">
          <InputField
            name={`limit.${dailyLimit.name}`}
            id={generateComponentId(dailyLimit.name, 'tier-configuration')}
            label={dailyLimit.label}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-md-6">
          <InputField
            name={`limit.${fortnightlyLimit.name}`}
            id={generateComponentId(
              fortnightlyLimit.name,
              'tier-configuration'
            )}
            label={fortnightlyLimit.label}
          />
        </Col>
        <Col className="col-md-6">
          <InputField
            name={`limit.${monthlyLimit.name}`}
            id={generateComponentId(monthlyLimit.name, 'tier-configuration')}
            label={monthlyLimit.label}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-md-6">
          <InputField
            name={`limit.${semiAnnualLimit.name}`}
            id={generateComponentId(semiAnnualLimit.name, 'tier-configuration')}
            label={semiAnnualLimit.label}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

const showTierForm = (level, kycTags) => {
  return (
    <div className="container">
      <div>
        <h3>Tier Level {level}</h3>
      </div>

      <div>
        {showTierLimitForm(level)}
        <div>
          <h5>Required KYC</h5>
        </div>
        <div>
          {kycTags.map((kycTag, key) => {
            return (
              <React.Fragment key={key}>
                <Checkbox
                  // todo: no tag_name set for now so using tag_id as label
                  label={`Tag ${kycTag.tag_id}`}
                  name={`requiredKyc[${kycTag.tag_id.toString()}].value`}
                />
                <span>{kycTag.fields.join(', ')}</span>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default compose(
  connect(
    state => ({
      kycTags: state.kycTag.data,
      affiliate: state.affiliate
    }),
    dispatch => ({
      saveAffiliateDraft: draft => dispatch(saveAffiliateDraft(draft)),
      toggleComponent: component => dispatch(toggleComponent(component)),
      saveTierInfo: (level, tierInfo) => dispatch(saveTierInfo(level, tierInfo))
    })
  )
)(PayoutPartnerConfiguration);
