import { compose } from 'redux';
import { produce } from 'immer';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';

import {
  Table,
  Modal,
  Section,
  InfoTable,
  FixedLoader,
  ErrorToastMessage,
  SuccessToastMessage
} from '@raas-dashboard/base';

import { isObjectEmpty } from 'utils/commonUtils';
import { fetchStates } from 'actions/stateAction';
import { fetchKycTags } from 'actions/kycTagAction';
import { fetchOriginators } from 'actions/originatorAction';
import {
  updateAffiliate,
  fetchAffiliateDetails
} from 'actions/affiliateAction';

import {
  mapToOriginatorInfoData,
  mapToPayoutPartnersData,
  mapToServiceProvidersData,
  mapToPayoutPartnersRequest,
  mapToOriginatorInfoRequest,
  mapToServiceProviderRequest
} from 'mappers/affiliateMapper';
import { configuration } from './AffiliateForm';

import TierConfiguration from './configuration/TierConfiguration';
import BasicInfoConfiguration from './configuration/BasicInfoConfiguration';
import OriginatorConfiguration from './configuration/OriginatorConfiguration';
import PayoutPartnerConfiguration from './configuration/PayoutPartnerConfiguration';
import ServiceProviderConfiguration from './configuration/ServiceProviderConfiguration';
import { fetchCountries } from 'actions/countryAction';
import { fetchPayoutPartners } from 'actions/payoutPartnerAction';
import { fetchServiceProviders } from 'actions/serviceProviderAction';

import './Affiliates.scss';

const AffiliateDetail = props => {
  const {
    states,
    kycTags,
    countries,
    originators,
    fetchStates,
    fetchKycTags,
    payoutPartners,
    fetchCountries,
    serviceProviders: serviceProviderList,
    affiliateDetails,
    fetchOriginators,
    isFetchingStates,
    isFetchingKycTags,
    fetchPayoutPartners,
    isFetchingAffiliates,
    fetchServiceProviders,
    isFetchingOriginators,
    fetchAffiliateDetails
  } = props;

  useEffect(() => {
    getStates(states, fetchStates);
    getKycTags(kycTags, fetchKycTags);
    getCountries(countries, fetchCountries);
    getOriginators(originators, fetchOriginators);
    getPayoutPartners(payoutPartners, fetchPayoutPartners);
    getServiceProviders(serviceProviderList, fetchServiceProviders);
    fetchAffiliateDetails(window.location.pathname.split('/').pop());
  }, []);

  const {
    basicInfo,
    tiers,
    licensedStates,
    payouts,
    serviceProviders
  } = affiliateDetails;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeEdit, setActiveEdit] = useState('');

  const showEditModal = component => {
    setActiveEdit(component);
    setIsModalVisible(true);
  };
  const closeModal = _ => setIsModalVisible(false);

  const updateAffiliateInfo = affiliateInfo => {
    const onSuccess = data => {
      closeModal();
      toast.success(
        <SuccessToastMessage message="Change has been requested" />
      );
    };
    const onFailure = error => {
      toast.error(
        <ErrorToastMessage message="Due to some reason couldn't update" />
      );
      closeModal();
    };
    props.updateAffiliate(
      basicInfo.id,
      requestData(affiliateInfo, activeEdit),
      onSuccess,
      onFailure
    );
  };
  const requestData = (affiliateInfo, activeEdit) => {
    switch (activeEdit) {
      case configuration.BASIC_INFO:
        return {
          basicInfo: affiliateInfo
        };

      case configuration.ORIGINATOR:
        return {
          licensedStates: mapToOriginatorInfoRequest(affiliateInfo, originators)
        };

      case configuration.PAYOUT_PARTNER:
        return {
          payouts: mapToPayoutPartnersRequest(affiliateInfo)
        };

      case configuration.SERVICE_PROVIDER:
        return {
          serviceProviders: mapToServiceProviderRequest(affiliateInfo)
        };

      case configuration.TIER_1:
        return {};

      default:
        return {};
    }
  };
  const showLoader =
    isFetchingStates ||
    isFetchingKycTags ||
    isFetchingAffiliates ||
    isFetchingOriginators;

  return (
    <div className="content-container">
      {showLoader && <FixedLoader />}
      {isModalVisible && (
        <Modal onClose={closeModal}>
          <ActiveConfiguration
            onEdit={updateAffiliateInfo}
            activeConfiguration={activeEdit}
            affiliateDetails={affiliateDetails}
            originators={originators}
          />
        </Modal>
      )}

      <div>
        <h2>Affiliate Details</h2>
      </div>
      <br />

      <Section
        title="Basic Info"
        onEdit={() => showEditModal(configuration.BASIC_INFO)}
      >
        <InfoTable cols={basicInfoCols} data={basicInfo} />
      </Section>

      <Section
        title="Originators"
        onEdit={() => showEditModal(configuration.ORIGINATOR)}
      >
        <Table
          cols={licensedStateCols}
          data={getVisibleLicensedStates(licensedStates, states, originators)}
        />
      </Section>

      <Section
        title="Payouts"
        onEdit={() => showEditModal(configuration.PAYOUT_PARTNER)}
      >
        <Table cols={payoutCols} data={payouts} />
      </Section>

      <Section
        title="Service Providers"
        onEdit={() => showEditModal(configuration.SERVICE_PROVIDER)}
      >
        <Table cols={serviceProviderCols} data={serviceProviders} />
      </Section>

      <Section title="Tiers" onEdit={() => showEditModal(configuration.TIER_1)}>
        <Section title="Transaction Limits">
          <Table cols={tierLimitCols} data={getVisibleTiers(tiers, kycTags)} />
        </Section>
        <Section title="Tags">
          <Table cols={tierTagCols} data={getVisibleTiers(tiers, kycTags)} />
        </Section>
      </Section>
    </div>
  );
};

const ActiveConfiguration = ({
  onEdit,
  affiliateDetails,
  activeConfiguration,
  originators
}) => {
  switch (activeConfiguration) {
    case configuration.BASIC_INFO:
      return (
        <BasicInfoConfiguration
          onSubmit={onEdit}
          initialValues={affiliateDetails.basicInfo}
        />
      );

    case configuration.ORIGINATOR:
      return (
        <OriginatorConfiguration
          onSubmit={onEdit}
          initialValues={mapToOriginatorInfoData(
            affiliateDetails.licensedStates,
            originators
          )}
          isEditView={true}
        />
      );

    case configuration.PAYOUT_PARTNER:
      return (
        <PayoutPartnerConfiguration
          onSubmit={onEdit}
          initialValues={mapToPayoutPartnersData(affiliateDetails.payouts)}
          isEditView={true}
        />
      );

    case configuration.SERVICE_PROVIDER:
      return (
        <ServiceProviderConfiguration
          onSubmit={onEdit}
          initialValues={mapToServiceProvidersData(
            affiliateDetails.serviceProviders
          )}
          isEditView={true}
        />
      );

    case configuration.TIER_1:
      return <TierConfiguration level={1} key={1} />;

    default:
      return <BasicInfoConfiguration />;
  }
};

const getStates = (states, fetchStates) => {
  if (isObjectEmpty(states)) {
    fetchStates('USA');
  }
};

const getKycTags = (kycTags, fetchKycTags) => {
  if (isObjectEmpty(kycTags)) {
    fetchKycTags();
  }
};

const getCountries = (countries, fetchCountries) => {
  if (isObjectEmpty(countries)) {
    fetchCountries();
  }
};

const getPayoutPartners = (payoutPartners, fetchPayoutPartners) => {
  if (isObjectEmpty(payoutPartners)) {
    fetchPayoutPartners();
  }
};

const getServiceProviders = (serviceProviders, fetchServiceProviders) => {
  if (isObjectEmpty(serviceProviders)) {
    fetchServiceProviders();
  }
};

const getOriginators = (originators, fetchOriginators) => {
  if (isObjectEmpty(originators)) {
    fetchOriginators();
  }
};

const basicInfoCols = [
  { label: 'Name', field: 'name' },
  { label: 'ID', field: 'id' },
  { label: 'Address', field: 'addressLine1' },
  { label: 'City', field: 'city' },
  { label: 'State', field: 'state' },
  { label: 'Mobile', field: 'mobileNumber' },
  { label: 'Telephone', field: 'telephoneNumber' },
  { label: 'Website', field: 'website' },
  { label: 'ZIP Code', field: 'zipCode' }
];

const serviceProviderCols = [
  { label: 'Service', field: 'type' },
  { label: 'Provider', field: 'provider' },
  { label: 'Funding Source Type', field: 'fundingSourceType' }
];

const payoutCols = [
  { label: 'Country', field: 'country' },
  { label: 'Payout Method', field: 'payoutMethod' },
  { label: 'Provider', field: 'provider' }
];

const getVisibleTiers = (tiers = [], kycTags) => {
  const mappedData = produce(tiers, draft => {
    for (let key in tiers) {
      draft[key].levelDisplay = tiers[key].level.slice(5);
      let fields = [];
      tiers[key].tags.forEach(tag => {
        if (kycTags[tag]) {
          fields = fields.concat(kycTags[tag].fields);
        }
      });
      draft[key].tagsDisplay = fields.join(', ');
      draft[key].singleTxnlimit = tiers[key].txnLimit.singleTxnlimit;
      draft[key].dailyLimit = tiers[key].txnLimit.dailyLimit;
      draft[key].monthlyLimit = tiers[key].txnLimit.monthlyLimit;
      draft[key].fortnightlyLimit = tiers[key].txnLimit.fortnightlyLimit;
      draft[key].semiAnnualLimit = tiers[key].txnLimit.semiAnnualLimit;
    }
  });

  return Object.values(mappedData);
};
const tierLimitCols = [
  { label: 'Levels', field: 'levelDisplay' },
  { label: 'Per Txn. Limit($)', field: 'singleTxnlimit' },
  { label: 'Daily Limit($)', field: 'dailyLimit' },
  { label: 'Monthly Limit($)', field: 'monthlyLimit' },
  { label: 'Fortnight Limit($)', field: 'fortnightlyLimit' },
  { label: 'Semi Annual Limit($)', field: 'semiAnnualLimit' }
];
const tierTagCols = [
  { label: 'Levels', field: 'levelDisplay' },
  { label: 'Tags', field: 'tagsDisplay' }
];

const getVisibleLicensedStates = (licensedStates = [], states, originators) => {
  const mappedData = produce(licensedStates, draft => {
    for (let key in licensedStates) {
      draft[key].stateName =
        states[licensedStates[key].state] &&
        states[licensedStates[key].state].name;
      draft[key].originatorName =
        originators[licensedStates[key].originator] &&
        originators[licensedStates[key].originator].name;
    }
  });
  return Object.values(mappedData);
};
const licensedStateCols = [
  { label: 'State', field: 'stateName' },
  { label: 'Originator', field: 'originatorName' }
];

export default compose(
  connect(
    state => ({
      kycTags: state.kycTag.data,
      states: state.country.states,
      countries: state.country.data,
      originators: state.originator.data,
      payoutPartners: state.payoutPartner.data,
      isFetchingKycTags: state.kycTag.isFetching,
      isFetchingStates: state.country.isFetching,
      affiliateDetails: state.affiliate.affiliate,
      serviceProviders: state.serviceProvider.data,
      isFetchingAffiliates: state.affiliate.isFetching,
      isFetchingOriginators: state.originator.isFetching
    }),
    dispatch => ({
      fetchKycTags: _ => dispatch(fetchKycTags()),
      fetchOriginators: _ => dispatch(fetchOriginators()),
      updateAffiliate: (affiliateInfo, onSuccess, onFailure) =>
        dispatch(updateAffiliate(affiliateInfo, onSuccess, onFailure)),
      fetchStates: countryCode => dispatch(fetchStates(countryCode)),
      fetchCountries: _ => dispatch(fetchCountries()),
      fetchPayoutPartners: _ => dispatch(fetchPayoutPartners()),
      fetchServiceProviders: _ => dispatch(fetchServiceProviders()),
      fetchAffiliateDetails: affiliateId =>
        dispatch(fetchAffiliateDetails(affiliateId))
    })
  )
)(AffiliateDetail);
