import { MTO } from './actions';

export const fetchMtoList = () => ({
  type: MTO.FETCH,
  payload: {
    url: '/raas/associated-companies',
    onSuccess: MTO.FETCH_SUCCEEDED,
    onFailure: MTO.FETCH_FAILED
  }
});
