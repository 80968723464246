import React from 'react';
import { Switch } from 'react-router-dom';

import { routes } from 'constants/routes';
import { userRoles } from 'components/common/RoleBasedRoute';

import PrivateRoute from 'components/common/PrivateRoute';

import Affiliates from 'components/affiliate/Affiliates';
import AffiliateForm from 'components/affiliate/AffiliateForm';
import AffiliateDetail from 'components/affiliate/AffiliateDetail';

const AffiliateRoutes = _ => (
  <Switch>
    <PrivateRoute
      exact
      path={routes.ADD_AFFILIATE}
      component={AffiliateForm}
      roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
    />
    <PrivateRoute
      exact
      path={routes.AFFILIATE_DETAIL}
      component={AffiliateDetail}
      roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
    />
    <PrivateRoute
      exact
      path={routes.AFFILIATE}
      component={Affiliates}
      roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
    />
  </Switch>
);

export default AffiliateRoutes;
