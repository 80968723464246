import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import './HorizontalNav.scss';

const HorizontalNav = ({ menuData, activeTab }) => {
  return (
    <div className="horizontal-nav">
      {menuData.map(menu =>
        menu.route ? (
          <Link
            className={`tablinks ${useLocation().pathname === menu.route &&
              'active'}`}
            to={menu.route}
            key={menu.label}
          >
            {menu.label}
          </Link>
        ) : (
          <Link
            className={`tablinks ${activeTab === menu.name && 'active'}`}
            onClick={menu.onClick}
            to={useLocation().pathname}
            key={menu.label}
          >
            {menu.label}
          </Link>
        )
      )}
    </div>
  );
};

HorizontalNav.propTypes = {
  activeTab: PropTypes.string,
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.name,
      route: PropTypes.string,
      onClick: PropTypes.func
    })
  )
};

export default HorizontalNav;
