import React from 'react';
import { Switch } from 'react-router-dom';

import { routes } from 'constants/routes';
import { userRoles } from 'components/common/RoleBasedRoute';

import PrivateRoute from 'components/common/PrivateRoute';

import TierTwoVerification from './TierTwoVerification';
import ChangeDeliveryStatus from './ChangeDeliveryStatus';
import TierThreeVerification from './TierThreeVerification';
import ChangeTransactionStatus from './ChangeTransactionStatus';

const AdminToolRoutes = _ => (
  <Switch>
    <PrivateRoute
      exact
      path={routes.CHANGE_TRANSACTION_STATUS}
      component={ChangeTransactionStatus}
      roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
    />
    <PrivateRoute
      exact
      path={routes.CHANGE_DELIVERY_STATUS}
      component={ChangeDeliveryStatus}
      roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
    />
    <PrivateRoute
      exact
      path={routes.TIER_2_VERIFICATION}
      component={TierTwoVerification}
      roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
    />
    <PrivateRoute
      exact
      path={routes.TIER_3_VERIFICATION}
      component={TierThreeVerification}
      roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
    />
  </Switch>
);

export default AdminToolRoutes;
