import React from 'react';
import { Field, useField } from 'formik';
import PropTypes from 'prop-types';

/**
 * Checkbox component.
 *
 * @param {Object} props
 *
 * @returns {React.FC}
 */
export const Checkbox = ({ value, name, label, ...props }) => {
  const [field] = useField(props);

  return (
    <div className="form-group">
      <Field {...field} name={name} type="checkbox" value={value} {...props} />
      <label className="form-check-label">{label}</label>
    </div>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  label: PropTypes.string.isRequired
};

export default Checkbox;
