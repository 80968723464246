import { COUNTRY } from './actions';

export const fetchCountries = _ => ({
  type: COUNTRY.FETCH,
  payload: {
    url: '/raas/countries',
    onSuccess: COUNTRY.FETCH_SUCCEEDED,
    onFailure: COUNTRY.FETCH_FAILED
  }
});
