import { call } from 'redux-saga/effects';

import { patch } from './api';

export function* updateTransactionStatus({ payload, onSuccess, onFailure }) {
  try {
    const { data } = yield call(patch, payload);
    onSuccess(data);
  } catch (error) {
    onFailure(error);
  }
}
