import { PAYOUT_PARTNER } from './actions';

export const fetchPayoutPartners = _ => ({
  type: PAYOUT_PARTNER.FETCH,
  payload: {
    url: '/raas/payout-partners',
    onSuccess: PAYOUT_PARTNER.FETCH_SUCCEEDED,
    onFailure: PAYOUT_PARTNER.FETCH_FAILED
  }
});
