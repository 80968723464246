import React from 'react';
import produce from 'immer';
import { compose } from 'redux';
import { useField } from 'formik';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';

import {
  Form,
  Header,
  Button,
  Checkbox,
  RadioGroup
} from '@raas-dashboard/base';

import {
  toggleComponent,
  saveAffiliateDraft,
  saveServiceProviderInfo
} from 'actions/affiliateAction';
import { configuration } from 'components/affiliate/AffiliateForm';

const ServiceProviderConfiguration = props => {
  const { serviceProviders } = props;
  const initialValues = props.initialValues;

  const handleSubmit = data => {
    const serviceProviders = JSON.parse(JSON.stringify(data));

    if (props.onSubmit) {
      return props.onSubmit(serviceProviders);
    }
    props.saveServiceProviderInfo(serviceProviders);
    props.toggleComponent(configuration.TIER_1);
  };

  return (
    <div className="container">
      <Header text="Service Providers" />
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        <FormElements
          serviceProviders={serviceProviders}
          isEditView={props.isEditView}
          affiliate={props.affiliate}
          saveAffiliateDraft={props.saveAffiliateDraft}
        />
      </Form>
    </div>
  );
};

const FormElements = props => {
  const { affiliate, isEditView, serviceProviders, saveAffiliateDraft } = props;
  const saveProgress = data => {
    const affiliateDraft = produce(affiliate, draft => {
      draft.serviceProviders = data;
    });
    saveAffiliateDraft(affiliateDraft);
  };
  const [field] = useField(props);

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Services</th>
            <th>Service Providers</th>
          </tr>
        </thead>
        <tbody>
          {serviceProviders.map((serviceProvider, key) => {
            if (
              !(
                serviceProvider.service === 'ORIGINATOR' ||
                serviceProvider.service === 'PAYOUT_PARTNER'
              )
            ) {
              return showServiceProviderRow(serviceProvider, key);
            }
            return null;
          })}
        </tbody>
      </Table>
      <div className="affiliate-form__button-wrapper">
        {isEditView ? (
          <div className="affiliate-form__button">
            <Button name="Save" type="submit" disabled={affiliate.isFetching} />
          </div>
        ) : (
          <>
            <div className="affiliate-form__button">
              <Button
                name="Next"
                type="submit"
                className="btn btn-primary"
                disabled={affiliate.isFetching}
              />
            </div>
            <div className="affiliate-form__button">
              <Button
                name="Save Progress"
                className="btn btn-primary"
                disabled={affiliate.isFetching}
                onClick={() => saveProgress(field.value)}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

const showServiceProviderRow = (serviceProvider, key) => {
  const serviceTypes = {};
  if (serviceProvider.serviceProviders.length > 0) {
    serviceProvider.serviceProviders.forEach(provider => {
      if (!serviceTypes[provider.type]) {
        serviceTypes[provider.type] = [];
      }
      if (!serviceTypes[provider.type].includes(provider.name)) {
        serviceTypes[provider.type].push(provider.name);
      }
    });
    return (
      <tr key={key}>
        <td>
          <label>{serviceProvider.service}</label>
        </td>
        <td>
          {serviceProvider.serviceProviders.map((provider, key) => {
            return (
              <React.Fragment key={key}>
                {provider.type === 'DEFAULT' ? null : (
                  <Checkbox
                    label={provider.type}
                    name={`${serviceProvider.service}[${provider.type}].value`}
                  />
                )}
                <RadioGroup
                  label=""
                  name={`${serviceProvider.service}[${provider.type}].serviceProvider`}
                  values={serviceTypes[provider.type]}
                />
              </React.Fragment>
            );
          })}
        </td>
      </tr>
    );
  }
};

export default compose(
  connect(
    state => ({
      affiliate: state.affiliate,
      serviceProviders: state.serviceProvider.data
    }),
    dispatch => ({
      saveAffiliateDraft: draft => dispatch(saveAffiliateDraft(draft)),
      toggleComponent: component => dispatch(toggleComponent(component)),
      saveServiceProviderInfo: serviceProviders =>
        dispatch(saveServiceProviderInfo(serviceProviders))
    })
  )
)(ServiceProviderConfiguration);
