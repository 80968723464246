import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { TopNav, SideNav } from '@raas-dashboard/base';

import { routes } from 'constants/routes';
import { signOutUser } from 'actions/authAction';

import './Main.scss';

const MainWrapper = props => {
  const menuData = [
    //Todo: just commenting out for the first phase release
    // { label: 'Affiliates', route: routes.AFFILIATE },
    // { label: 'Add Affiliate', route: routes.ADD_AFFILIATE },
    { label: 'Preference', route: routes.PREFERENCE },
    { label: 'Transactions', route: routes.TRANSACTION },
    { label: 'Senders', route: routes.SENDER },
    { label: 'Recipients', route: routes.RECIPIENT },
    { label: 'Refunds Report', route: routes.REFUND },
    { label: 'Returned Report', route: routes.RETURN },
    { label: 'Hold Transaction Report', route: routes.HOLD_TRANSACTION },
    { label: 'Tier 2 Verification', route: routes.TIER_2_VERIFICATION },
    { label: 'Tier 3 Verification', route: routes.TIER_3_VERIFICATION },
    // { label: 'Countries', route: routes.COUNTRY },
    {
      label: 'Change transaction status',
      route: routes.CHANGE_TRANSACTION_STATUS
    },
    {
      label: 'Change delivery status',
      route: routes.CHANGE_DELIVERY_STATUS
    },
    {
      label: 'Exchange Rates',
      route: routes.EXCHANGE_RATE
    }
  ];
  const userMenuData = [
    {
      label: 'User Name',
      value: props.user.username
    },
    {
      label: 'User ID',
      value: props.user.sub
    },
    {
      label: 'Email',
      value: props.user.email
    }
  ];

  return (
    <div className="wrapper">
      <ToastContainer />
      <TopNav
        logoUrl="/machnet-logo.png"
        altText="Machnet Logo"
        userMenuData={userMenuData}
        logout={() => {
          props.signOutUser();
        }}
      />
      <SideNav menuData={menuData} />
      {props.children}
    </div>
  );
};

export default compose(
  connect(
    state => ({
      user: state.auth.user
    }),
    dispatch => ({
      signOutUser: _ => dispatch(signOutUser())
    })
  )
)(MainWrapper);
