import React from 'react';
import PropTypes from 'prop-types';

/**
 * Display table headers component.
 *
 * @param {Object} props
 *
 * @returns {React.FC}
 */
const TableHeader = ({ cols }) => {
  return (
    <thead>
      <tr>
        {cols.map(c => (
          <th key={c.label}>{c.label}</th>
        ))}
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  cols: PropTypes.array
};

export default TableHeader;
