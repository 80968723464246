import { put, call } from 'redux-saga/effects';

import { get, post } from './api';

export function* authorizeUser({ payload }) {
  try {
    const { data } = yield call(post, payload);
    yield put({ type: payload.onSuccess, payload: data });
  } catch (error) {
    yield put({ type: payload.onFailure, error });
  }
}

export function* signOutUser({ payload }) {
  try {
    const { data } = yield call(get, payload);
    yield put({ type: payload.onSuccess, payload: data });
  } catch (error) {
    yield put({ type: payload.onFailure, error });
  }
}
