import { ADMIN_TOOLS } from 'actions/actions';

export const updateTransactionStatus = ({
  senderId,
  transactionId,
  data,
  onSuccess,
  onFailure
}) => ({
  type: ADMIN_TOOLS.UPDATE_TRANSACTION_STATUS,
  payload: {
    url: `/raas/admin/senders/${senderId}/transactions/${transactionId}/status`,
    data
  },
  onSuccess,
  onFailure
});

export const unholdTransaction = ({
  senderId,
  transactionId,
  data,
  onSuccess,
  onFailure
}) => ({
  type: ADMIN_TOOLS.UNHOLD_TRANSACTION,
  payload: {
    url: `/raas/admin/senders/${senderId}/transactions/${transactionId}/unhold`,
    data
  },
  onSuccess,
  onFailure
});

export const updateDeliveryStatus = ({
  senderId,
  transactionId,
  data,
  onSuccess,
  onFailure
}) => ({
  type: ADMIN_TOOLS.UPDATE_DELIVERY_STATUS,
  payload: {
    url: `/raas/admin/senders/${senderId}/transactions/${transactionId}/delivery-status`,
    data
  },
  onSuccess,
  onFailure
});

export const unholdTransactionDelivery = ({
  senderId,
  transactionId,
  data,
  onSuccess,
  onFailure
}) => ({
  type: ADMIN_TOOLS.UNHOLD_TRANSACTION_DELIVERY,
  payload: {
    url: `/raas/admin/senders/${senderId}/transactions/${transactionId}/delivery-unhold`,
    data
  },
  onSuccess,
  onFailure
});

export const holdTransactionDelivery = ({
  senderId,
  transactionId,
  data,
  onSuccess,
  onFailure
}) => ({
  type: ADMIN_TOOLS.HOLD_TRANSACTION_DELIVERY,
  payload: {
    url: `/raas/admin/senders/${senderId}/transactions/${transactionId}/delivery-hold`
    // data
  },
  onSuccess,
  onFailure
});

export const requestTransactionDelivery = ({
  senderId,
  transactionId,
  data,
  onSuccess,
  onFailure
}) => ({
  type: ADMIN_TOOLS.REQUEST_TRANSACTION_DELIVERY,
  payload: {
    url: `/raas/senders/${senderId}/transactions/delivery-requests/${transactionId}`,
    data
  },
  onSuccess,
  onFailure
});
