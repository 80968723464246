import React from 'react';
import PropTypes from 'prop-types';

import './ToastAlert.scss';

export const SuccessToastMessage = ({ message }) => (
  <div className="d-flex">
    <i className="icon ion-md-checkmark-circle text-normal" />
    <span className="pl-2"> {message}</span>
  </div>
);

SuccessToastMessage.propTypes = {
  message: PropTypes.string
};

export const InfoToastMessage = ({ message }) => (
  <div className="d-flex">
    <i className="icon ion-md-information-circle text-normal" />
    <span className="pl-2"> {message}</span>
  </div>
);

InfoToastMessage.propTypes = {
  message: PropTypes.string
};

export const ErrorToastMessage = ({ message }) => (
  <div className="d-flex">
    <i className="icon ion-md-remove-circle text-normal" />
    <span className="pl-2"> {message}</span>
  </div>
);

ErrorToastMessage.propTypes = {
  message: PropTypes.string
};

export const WarnToastMessage = ({ message }) => (
  <div className="d-flex">
    <i className="icon ion-md-warning text-normal" />
    <span className="pl-2"> {message}</span>
  </div>
);

WarnToastMessage.propTypes = {
  message: PropTypes.string
};
