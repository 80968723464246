import React from 'react';
import { Switch } from 'react-router-dom';

import { routes } from 'constants/routes';
import { userRoles } from 'components/common/RoleBasedRoute';

import PrivateRoute from 'components/common/PrivateRoute';

import Transactions from './Transactions';
import HoldTransactions from './HoldTransactions';
import RefundTransactions from './RefundTransactions';
import ReturnedTransactions from './ReturnedTransactions';

const TransactionRoutes = _ => (
  <Switch>
    <PrivateRoute
      exact
      path={routes.TRANSACTION}
      component={Transactions}
      roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
    />
    <PrivateRoute
      exact
      path={routes.REFUND}
      component={RefundTransactions}
      roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
    />
    <PrivateRoute
      exact
      path={routes.RETURN}
      component={ReturnedTransactions}
      roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
    />
    <PrivateRoute
      exact
      path={routes.HOLD_TRANSACTION}
      component={HoldTransactions}
      roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
    />
  </Switch>
);

export default TransactionRoutes;
