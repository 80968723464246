import React from 'react';
import PropTypes from 'prop-types';

/**
 * Display table cell component.
 *
 * @param {Object} props
 *
 * @returns {React.FC}
 */
const TableCell = ({ value }) => {
  return <td>{value}</td>;
};

TableCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default TableCell;
