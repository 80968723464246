import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { default as ReactDatePicker } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

/**
 * Date Picker field component.
 *
 * @param {Object} props
 *
 * @returns {React.FC}
 */
const DatePicker = ({ label, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const appendLeadingZeroes = n => {
    if (n <= 9) {
      return '0' + n;
    }

    return n;
  };

  return (
    <div className="form-group">
      <label className="form-check-label">{label}</label>
      <ReactDatePicker
        {...field}
        className="form-control"
        selected={(field.value && new Date(field.value)) || null}
        onChange={val => {
          const formattedValue = `${val.getFullYear()}-${appendLeadingZeroes(
            val.getMonth() + 1
          )}-${appendLeadingZeroes(val.getDate())}`;

          setFieldValue(field.name, formattedValue);
        }}
        dateFormat="yyyy-MM-dd"
        {...props}
      />
    </div>
  );
};

DatePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired
};

export default DatePicker;
