import PropTypes from 'prop-types';
import React, { useState } from 'react';

import './TopNav.scss';

const TopNav = ({ logoUrl, altText, userMenuData, logout }) => {
  const [isUserNavigationVisible, setUserNavigationVisible] = useState(false);
  const toggleUserMenu = () => {
    setUserNavigationVisible(!isUserNavigationVisible);
  };

  return (
    <div className="top-nav">
      <img
        src={logoUrl}
        className="top-nav__logo"
        alt={altText || 'Logo'}
      ></img>
      <i
        className="fas fa-bars top-nav__user-menu-icon"
        onClick={toggleUserMenu}
      ></i>
      {isUserNavigationVisible && (
        <div className="user-navigation-menu">
          <div className="user-info">
            {userMenuData.map(data => (
              <span key={data.label}> {`${data.label}: ${data.value}`} </span>
            ))}
          </div>
          <div className="logout-btn">
            <span onClick={logout}>Logout</span>
          </div>
        </div>
      )}
    </div>
  );
};

TopNav.propTypes = {
  logout: PropTypes.func,
  altText: PropTypes.string,
  logoUrl: PropTypes.string,
  userMenuData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  )
};

export default TopNav;
