import axios from 'axios';
import qs from 'qs';

import { getBasicToken } from './tokenUtils';

const cognitoHttp = axios.create();

export const cognitoPost = ({ url, data, config }) =>
  cognitoHttp.post(url, qs.stringify(data), {
    baseURL: config.userPoolBaseUri,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: getBasicToken(config.clientId, config.clientSecret)
    }
  });

export const cognitoGet = ({ url, token, config }) =>
  cognitoHttp.get(url, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`
    },
    baseURL: config.userPoolBaseUri
  });
