import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Table, Header, Section, InfoTable } from '@raas-dashboard/base';
import { fetchRecipientDetail } from 'actions/recipientAction';

const RecipientDetail = props => {
  const { sender_id, recipient_id } = useParams();
  useEffect(() => {
    props.fetchRecipientDetail(sender_id, recipient_id);
  }, []);
  const basicInfoCols = [
    { label: 'ID', field: 'id' },
    { label: 'Name', field: 'full_name' },
    { label: 'Status', field: 'verification_status' },
    { label: 'Email Address', field: 'email' },
    { label: 'Address', field: 'address_line1' },
    { label: 'Sender Email', field: 'sender_email' },
    { label: 'Occupation', field: 'occupation' },
    { label: 'Document Type', field: 'document_type' },
    { label: 'Document', field: 'document' },
    { label: 'Phone Number', field: 'mobile_phone' }
  ];

  const bankCols = [
    { label: 'Bank Name', field: 'bank_name' },
    { label: 'Account Type', field: 'account_type' },
    { label: 'Branch Name', field: 'branch_name' },
    { label: 'Account Number', field: 'account_number' }
  ];
  const cashLocationCols = [
    { label: 'ID', field: 'id' },
    { label: 'Location', field: 'location' }
  ];

  const recipientBanks = () => {
    const banks = [];

    if (props.recipientDetail.recipient_accounts)
      props.recipientDetail.recipient_accounts.forEach(account => {
        if (account.payout_method === 'BANK_DEPOSIT') {
          banks.push(account);
        }
      });

    return banks;
  };

  const recipientCashLocation = () => {
    const cashPickups = [];

    if (props.recipientDetail.recipient_accounts)
      props.recipientDetail.recipient_accounts.forEach(account => {
        if (account.payout_method === 'CASH_PICKUP') {
          cashPickups.push(account);
        }
      });

    return cashPickups;
  };

  return (
    <div className="content-container">
      <div>
        <Header text="Recipient Detail" />
      </div>
      <div>
        <Section title="Basic Info">
          <InfoTable cols={basicInfoCols} data={props.recipientDetail} />
        </Section>
      </div>

      <div>
        <Header text="Recipient Payout Methods" />

        <Section title="Banks">
          <Table cols={bankCols} data={recipientBanks()} />
        </Section>
        <Section title="Cash Locations">
          <Table cols={cashLocationCols} data={recipientCashLocation()} />
        </Section>
      </div>
    </div>
  );
};

export default compose(
  connect(
    state => ({
      recipientDetail: state.recipient.recipientDetail
    }),
    dispatch => ({
      fetchRecipientDetail: (senderId, recipientId) =>
        dispatch(fetchRecipientDetail(senderId, recipientId))
    })
  )
)(RecipientDetail);
