import produce from 'immer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { isObjectEmpty, setPreferredTimeZone } from 'utils/commonUtils';
import {
  Table,
  Header,
  Section,
  InfoTable,
  HorizontalNav
} from '@raas-dashboard/base';

import { fetchMsbList } from 'actions/msbAction';
import { fetchMtoList } from 'actions/mtoAction';
import {
  fetchSenderDetail,
  fetchRecipientsOfSender,
  fetchTransactionsOfSender,
  fetchFundingSourcesOfSender
} from 'actions/senderAction';

import './Senders.scss';

const SenderDetail = props => {
  const { sender_id } = useParams();
  const [activeInfo, setActiveInfo] = useState('transactions');

  const menuData = [
    {
      name: 'transactions',
      label: 'Transactions',
      onClick: () => setActiveInfo('transactions')
    },
    {
      name: 'recipients',
      label: 'Recipients',
      onClick: () => setActiveInfo('recipients')
    },
    {
      name: 'banks',
      label: 'Banks',
      onClick: () => setActiveInfo('banks')
    },
    {
      name: 'cards',
      label: 'Cards',
      onClick: () => setActiveInfo('cards')
    },
    {
      name: 'tierInfo',
      label: 'Tier Info',
      onClick: () => setActiveInfo('tierInfo')
    }
  ];
  const { msbs, mtos, senderDetail, fetchMsbList, fetchMtoList } = props;
  useEffect(() => {
    if (isObjectEmpty(msbs)) {
      fetchMsbList();
    }
    if (isObjectEmpty(mtos)) {
      fetchMtoList();
    }
    props.fetchSenderDetail(sender_id);
    props.fetchRecipients(sender_id);
    props.fetchTransactions(sender_id);
    props.fetchFundingSources(sender_id);
  }, []);
  const basicInfoData = produce(senderDetail, draft => {
    draft.msb_name =
      msbs[senderDetail.msb_id] && msbs[senderDetail.msb_id].name;
    draft.mto_name =
      mtos[senderDetail.mto_id] && mtos[senderDetail.mto_id].name;
    draft.sign_up_date = setPreferredTimeZone(senderDetail.signup_date);
  });

  return (
    <div className="content-container">
      <div>
        <Header text="Sender Detail" />
      </div>
      <div>
        <Section title="Basic Info">
          <InfoTable cols={basicInfoCols} data={basicInfoData} />
        </Section>
      </div>
      <div>
        <div>
          <HorizontalNav activeTab={activeInfo} menuData={menuData} />
          <ActiveInfo
            activeInfo={activeInfo}
            senderDetail={senderDetail}
            recipients={Object.values(props.recipients)}
            transactions={Object.values(props.transactions)}
            fundingSources={Object.values(props.fundingSources)}
          />
        </div>
        <Section title="Sender Status History">
          <div>
            {senderDetail.status_history &&
              senderDetail.status_history.map((history, index) => (
                <div className="transaction-history" key={index}>
                  <span className="status">{history.status}</span>
                  <span className="history-date">{` on ${setPreferredTimeZone(
                    history.changed_at
                  )}`}</span>
                </div>
              ))}
          </div>
        </Section>
      </div>
    </div>
  );
};

const ActiveInfo = ({
  activeInfo,
  recipients,
  senderDetail,
  transactions,
  fundingSources
}) => {
  switch (activeInfo) {
    case 'recipients':
      return <Recipients recipients={recipients} />;

    case 'transactions':
      return <Transactions transactions={transactions} />;

    case 'banks':
      return <Banks fundingSources={fundingSources} />;

    case 'cards':
      return <Cards fundingSources={fundingSources} />;

    case 'tierInfo':
      return <TierInfo senderDetail={senderDetail} />;

    default:
      return <> </>;
  }
};

const TierInfo = ({ senderDetail }) => {
  const { sender_cip_details } = senderDetail;

  return (
    <Section title="Tier Info">
      <InfoTable cols={tierInfoCols} data={sender_cip_details} />
      <div className="Uploaded Documents">
        <Table
          cols={uploadedDocumentCols}
          data={getUploadedDocuments(sender_cip_details)}
        />
      </div>
      <div className="Manual Verification Results">
        <Table
          cols={manualVerificationCols}
          data={getManualVerificationResults(sender_cip_details)}
        />
      </div>
    </Section>
  );
};

const Recipients = ({ recipients }) => {
  const recipientCols = [
    { label: 'Name', field: 'first_name' },
    { label: 'Status', field: 'status' },
    { label: 'Email', field: 'email' },
    { label: 'Phone Number', field: 'mobile_phone' },
    { label: 'Address', field: 'address_line1' }
  ];

  return (
    <Section>
      <Table cols={recipientCols} data={recipients} />
    </Section>
  );
};

const Transactions = ({ transactions }) => {
  const transactionCols = [
    { label: 'Reference Number', field: 'mto_reference_number' },
    { label: 'Transaction Date', field: 'txn_date' },
    { label: 'Sender Name', field: 'sender_name' },
    { label: 'Receiver Name', field: 'recipient_name' },
    { label: 'FX Rate', field: 'exchange_rate' },
    { label: 'Send Amount', field: 'txn_amount' },
    { label: 'Receive Amount', field: 'recipient_amount' },
    { label: 'Transaction Status', field: 'status' },
    { label: 'Delivery Status', field: 'delivery_status' },
    { label: 'Bank Flag', field: 'bank_flag' }
  ];

  const transactionData = produce(transactions, draft => {
    for (let key in transactions) {
      draft[key].sender_name = transactions[key].from.full_name;
      draft[key].sender_name = transactions[key].to.full_name;
      draft[key].recipient_name = transactions[key].verified_account_holder
        ? 'Yes'
        : 'No';
      draft[key].txn_date = setPreferredTimeZone(transactions[key].created_on);
    }
  });

  return (
    <Section>
      <Table cols={transactionCols} data={transactionData} />
    </Section>
  );
};

const Banks = ({ fundingSources }) => {
  const bankCols = [
    { label: 'Name', field: 'funding_source_name' },
    { label: 'Account Holders Name', field: 'account_holder_name' },
    { label: 'Flag', field: 'verification_status' }
  ];
  const banks = [];
  fundingSources.forEach(fundingSource => {
    if (fundingSource.funding_source_type === 'BANK') {
      banks.push(fundingSource);
    }
  });

  return (
    <Section>
      <Table cols={bankCols} data={banks} />
    </Section>
  );
};

const Cards = ({ fundingSources }) => {
  const cardCols = [
    { label: 'Network Name', field: 'network' },
    { label: 'Card Number', field: 'card_number' },
    { label: 'Nickname', field: 'nick_name' }
  ];
  const cards = [];
  fundingSources.forEach(fundingSource => {
    if (fundingSource.funding_source_type === 'CARD') {
      cards.push(fundingSource);
    }
  });

  return (
    <Section>
      <Table cols={cardCols} data={cards} />
    </Section>
  );
};

const basicInfoCols = [
  { label: 'Name', field: 'full_name' },
  { label: 'ID', field: 'id' },
  { label: 'Email Address', field: 'email' },
  { label: 'Gender', field: 'gender' },
  { label: 'Address', field: 'address_line1' },
  { label: 'Phone Number', field: 'mobile_phone' },
  { label: 'SSN', field: 'ssn' },
  { label: 'DOB', field: 'date_of_birth' },
  { label: 'KYC Status', field: 'status' },
  { label: 'Tier Status', field: 'tier' },
  { label: 'Source of Income', field: 'source_of_income' },
  { label: 'MSB', field: 'msb_name' },
  { label: 'Affiliate', field: 'mto_name' },
  { label: 'Sign Up Date', field: 'sign_up_date' }
];

const tierInfoCols = [
  { label: 'First Name', field: 'first_name' },
  { label: 'Last Name', field: 'last_name' },
  { label: 'Email Address', field: 'email' },
  { label: 'Gender', field: 'gender' },
  { label: 'Address', field: 'address_line1' },
  { label: 'Phone Number', field: 'mobile_phone' },
  { label: 'SSN', field: 'full_ssn' },
  { label: 'DOB', field: 'date_of_birth' },
  { label: 'Source of Income', field: 'source_of_income' },
  { label: 'Occupation', field: 'occupation' },
  { label: 'Company Details', field: 'company_details' }
];

const uploadedDocumentCols = [
  { label: 'File Name', field: 'file_name' },
  { label: 'Document Side', field: 'document_side' },
  { label: 'Type', field: 'document_type' },
  { label: 'KYC ID', field: 'kyc_id' },
  { label: 'ID Number', field: 'identification_number' },
  { label: 'Expiry Date', field: 'expiry_date' },
  { label: 'Date Uploaded', field: 'date_uploaded' },
  { label: 'Reviewed By', field: 'id_issuing_authority' },
  { label: 'State', field: 'status' },
  { label: 'Result', field: 'result' },
  { label: 'Failure Reason', field: 'failure_reasons' },
  { label: 'Download/View', field: 'file_link' }
];
const getUploadedDocuments = (cipDetail = {}) => {
  const { documents } = cipDetail;
  const mappedData = produce(documents, draft => {
    for (let key in documents) {
      draft[key].file_link = <a href={documents[key].file_link}>Link</a>;
    }
  });

  return mappedData;
};

const manualVerificationCols = [
  { label: 'KYC ID', field: 'kyc_id' },
  { label: 'Check Date', field: 'createdAt' },
  { label: 'Verification Type', field: 'cipVerificationType' },
  { label: 'Type', field: 'cipVerificationType' },
  { label: 'Result', field: 'status' }
];
const getManualVerificationResults = (cipDetail = {}) => {
  const { manual_kyc_history } = cipDetail;
  return manual_kyc_history;
};

export default compose(
  connect(
    state => ({
      msbs: state.msb.data,
      mtos: state.mto.data,
      recipients: state.sender.recipients,
      senderDetail: state.sender.senderDetail,
      transactions: state.sender.transactions,
      fundingSources: state.sender.fundingSources
    }),
    dispatch => ({
      fetchMsbList: () => dispatch(fetchMsbList()),
      fetchMtoList: () => dispatch(fetchMtoList()),
      fetchSenderDetail: page => dispatch(fetchSenderDetail(page)),
      fetchRecipients: senderId => dispatch(fetchRecipientsOfSender(senderId)),
      fetchTransactions: senderId =>
        dispatch(fetchTransactionsOfSender(senderId)),
      fetchFundingSources: senderId =>
        dispatch(fetchFundingSourcesOfSender(senderId))
    })
  )
)(SenderDetail);
