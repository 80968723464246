import React from 'react';
import PropTypes from 'prop-types';

import TableCell from './TableCell';

/**
 * Display table row component.
 *
 * @param {Object} props
 *
 * @returns {React.FC}
 */
const TableRow = ({ cols, row }) => {
  return (
    <tr
      onClick={() => {
        if (typeof row.onRowClick === 'function') {
          row.onRowClick();
        }
      }}
      className={typeof row.onRowClick === 'function' ? 'clickable-row' : ''}
    >
      {cols.map(c => (
        <TableCell key={row[c.field]} value={row[c.field]} />
      ))}
    </tr>
  );
};

TableRow.propTypes = {
  cols: PropTypes.array,
  row: PropTypes.object
};

export default TableRow;
