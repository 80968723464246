import React from 'react';
import { compose } from 'redux';
import { produce } from 'immer';
import { connect } from 'react-redux';

import { Button } from '@raas-dashboard/base';
import { Table, Section, InfoTable } from '@raas-dashboard/base';

import { configuration } from './AffiliateForm';
import { mapToAffiliateRequest } from 'mappers/affiliateMapper';
import { saveAffiliate, toggleComponent } from 'actions/affiliateAction';

import './Affiliates.scss';

const AffiliateDetail = props => {
  const { states, kycTags, affiliate, originators, saveAffiliate } = props;

  const affiliateDetails = mapToAffiliateRequest(affiliate, originators);
  const {
    basicInfo,
    tiers,
    licensedStates,
    payouts,
    serviceProviders
  } = affiliateDetails;

  const editBasicInfo = () => {
    props.toggleComponent(configuration.BASIC_INFO);
  };

  const editServiceProvider = () => {
    props.toggleComponent(configuration.SERVICE_PROVIDER);
  };

  const editPayouts = () => {
    props.toggleComponent(configuration.PAYOUT_PARTNER);
  };

  const editTier = () => {
    props.toggleComponent(configuration.TIER_1);
  };

  const editLicensedStates = () => {
    props.toggleComponent(configuration.ORIGINATOR);
  };

  return (
    <div className="container">
      <div>
        <h2>Affiliate Details</h2>
      </div>
      <br />

      <Section title="Basic Info" onEdit={editBasicInfo}>
        <InfoTable cols={basicInfoCols} data={basicInfo} />
      </Section>

      <Section title="Originators" onEdit={editLicensedStates}>
        <Table
          cols={licensedStateCols}
          data={getVisibleLicensedStates(licensedStates, states, originators)}
        />
      </Section>

      <Section title="Payouts" onEdit={editPayouts}>
        <Table cols={payoutCols} data={payouts} />
      </Section>

      <Section title="Service Providers" onEdit={editServiceProvider}>
        <Table cols={serviceProviderCols} data={serviceProviders} />
      </Section>

      <Section title="Tiers" onEdit={editTier}>
        <Section title="Transaction Limits">
          <Table cols={tierLimitCols} data={getVisibleTiers(tiers, kycTags)} />
        </Section>
        <Section title="Tags">
          <Table cols={tierTagCols} data={getVisibleTiers(tiers, kycTags)} />
        </Section>
      </Section>
      <div className="affiliate-form__button-wrapper">
        <div className="affiliate-form__button">
          <Button
            name="Save Affiliate"
            disabled={affiliate.isFetching}
            onClick={() => saveAffiliate(affiliateDetails)}
          />
        </div>
      </div>
    </div>
  );
};

const basicInfoCols = [
  { label: 'Name', field: 'name' },
  { label: 'ID', field: 'id' },
  { label: 'Address Line 1', field: 'addressLine1' },
  { label: 'Address Line 2', field: 'addressLine2' },
  { label: 'City', field: 'city' },
  { label: 'State', field: 'state' },
  { label: 'Mobile', field: 'mobileNumber' },
  { label: 'Telephone', field: 'telephoneNumber' },
  { label: 'Website', field: 'website' },
  { label: 'ZIP Code', field: 'zipCode' }
];

const serviceProviderCols = [
  { label: 'Service', field: 'type' },
  { label: 'Provider', field: 'provider' },
  { label: 'Funding Source Type', field: 'fundingSourceType' }
];

const payoutCols = [
  { label: 'Country', field: 'country' },
  { label: 'Payout Method', field: 'payoutMethod' },
  { label: 'Provider', field: 'provider' }
];

const getVisibleTiers = (tiers = [], kycTags) => {
  const mappedData = produce(tiers, draft => {
    for (let key in tiers) {
      draft[key].levelDisplay = tiers[key].level.slice(5);
      let fields = [];
      tiers[key].tags.forEach(tag => {
        if (kycTags[tag]) {
          fields = fields.concat(kycTags[tag].fields);
        }
      });
      draft[key].tagsDisplay = fields.join(', ');
      draft[key].singleTxnlimit = tiers[key].txnLimit.singleTxnlimit;
      draft[key].dailyLimit = tiers[key].txnLimit.dailyLimit;
      draft[key].monthlyLimit = tiers[key].txnLimit.monthlyLimit;
      draft[key].fortnightlyLimit = tiers[key].txnLimit.fortnightlyLimit;
      draft[key].semiAnnualLimit = tiers[key].txnLimit.semiAnnualLimit;
    }
  });

  return Object.values(mappedData);
};
const tierLimitCols = [
  { label: 'Levels', field: 'levelDisplay' },
  { label: 'Per Txn. Limit($)', field: 'singleTxnlimit' },
  { label: 'Daily Limit($)', field: 'dailyLimit' },
  { label: 'Monthly Limit($)', field: 'monthlyLimit' },
  { label: 'Fortnight Limit($)', field: 'fortnightlyLimit' },
  { label: 'Semi Annual Limit($)', field: 'semiAnnualLimit' }
];
const tierTagCols = [
  { label: 'Levels', field: 'levelDisplay' },
  { label: 'Tags', field: 'tagsDisplay' }
];

const getVisibleLicensedStates = (licensedStates = [], states, originators) => {
  const mappedData = produce(licensedStates, draft => {
    for (let key in licensedStates) {
      draft[key].stateName =
        states[licensedStates[key].state] &&
        states[licensedStates[key].state].name;
      draft[key].originatorName =
        originators[licensedStates[key].originator] &&
        originators[licensedStates[key].originator].name;
    }
  });
  return Object.values(mappedData);
};
const licensedStateCols = [
  { label: 'State', field: 'stateName' },
  { label: 'Originator', field: 'originatorName' }
];

export default compose(
  connect(
    state => ({
      affiliate: state.affiliate,
      kycTags: state.kycTag.data,
      states: state.country.states,
      originators: state.originator.data
    }),
    dispatch => ({
      toggleComponent: component => dispatch(toggleComponent(component)),
      saveAffiliate: affiliateDetails =>
        dispatch(saveAffiliate(affiliateDetails))
    })
  )
)(AffiliateDetail);
