import { SERVICE_PROVIDER } from './actions';

export const fetchServiceProviders = _ => ({
  type: SERVICE_PROVIDER.FETCH,
  payload: {
    url: '/raas/service-providers',
    onSuccess: SERVICE_PROVIDER.FETCH_SUCCEEDED,
    onFailure: SERVICE_PROVIDER.FETCH_FAILED
  }
});
