import React from 'react';
import PropTypes from 'prop-types';
import { Table as BootstrapTable } from 'react-bootstrap';

import TableRow from './TableRow';
import Pagination from './Pagination';
import TableHeader from './TableHeader';
import { FixedLoader } from '../loader/Loader';

import './Table.scss';

/**
 * Display table component.
 *
 * @param {Object} props
 *
 * @returns {React.FC}
 */
export const Table = ({ cols, data = [], isLoading, pagination }) => (
  <div className="loader-wrapper">
    {isLoading && <FixedLoader />}
    <BootstrapTable striped bordered hover className="data-table" size="sm">
      <TableHeader cols={cols} />
      {!isLoading ? (
        <tbody>
          {data.length ? (
            data.map((row, idx) => <TableRow key={idx} cols={cols} row={row} />)
          ) : (
            <tr>
              <td className="data-unavailable__td" colSpan={cols.length}>
                <span className="data-unavailable__span">Data Unavailable</span>
              </td>
            </tr>
          )}
        </tbody>
      ) : null}
    </BootstrapTable>
    {pagination && <Pagination {...pagination} />}
  </div>
);

Table.propTypes = {
  cols: PropTypes.array,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    hasNextPage: PropTypes.bool,
    fetchPrevious: PropTypes.func,
    fetchNext: PropTypes.func
  })
};

export default Table;
