import produce from 'immer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';

import { Table } from '@raas-dashboard/base';
import { fetchExchangeRates } from 'actions/exchangeRateAction';

import './ExchangeRate.scss';

const TrangloExchangeRate = props => {
  useEffect(() => {
    props.fetchExchangeRates('tranglo');
  }, []);

  const cols = [
    { label: 'S.No.', field: 'sNo' },
    { label: 'Corridor', field: 'corridor' },
    { label: 'From Currency', field: 'fromCurrency' },
    { label: 'To Currency', field: 'toCurrency' },
    { label: 'Exchange Rate', field: 'fxRate' },
    { label: 'Updated Date', field: 'updatedAt' }
  ];

  const getDisplayData = rates => {
    const displayData = rates.map((rate, index) =>
      produce(rate, draft => {
        draft.sNo = index + 1;
      })
    );

    return displayData;
  };

  return (
    <div className="rate-table-wrapper">
      <Table
        cols={cols}
        data={getDisplayData(props.rates)}
        isLoading={props.isFetching}
      />
    </div>
  );
};

export default compose(
  connect(
    state => ({
      rates: state.exchangeRate.data,
      isFetching: state.exchangeRate.isFetching
    }),
    dispatch => ({
      fetchExchangeRates: provider => dispatch(fetchExchangeRates(provider))
    })
  )
)(TrangloExchangeRate);
