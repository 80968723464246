import React from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';

import { TIER } from 'actions/actions';
import { ErrorToastMessage, SuccessToastMessage } from '@raas-dashboard/base';

const initialState = {
  data: {},
  error: '',
  isFetching: false
};

const tierReducer = (state = initialState, action) => {
  switch (action.type) {
    case TIER.FETCH_TIER_LIST:
      return produce(state, draft => {
        draft.data = {};
        draft.isFetching = true;
      });

    case TIER.FETCH_TIER_LIST_SUCCESS:
      const { results, paging } = action.payload;
      return produce(state, draft => {
        draft.isFetching = false;
        results.forEach(obj => {
          draft.data[obj.kycId] = obj;
        });
        draft.paging = paging;
      });

    case TIER.FETCH_TIER_LIST_ERROR:
      toast.error(
        <ErrorToastMessage message="Network error. Couldn't fetch Tier List." />
      );

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    case TIER.REVIEW_TIER_CHANGE_REQUEST:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case TIER.REVIEW_TIER_CHANGE_REQUEST_SUCCESS:
      toast.success(
        <SuccessToastMessage message="Tier review action completed." />
      );
      return produce(state, draft => {
        draft.isFetching = false;
      });

    case TIER.REVIEW_TIER_CHANGE_REQUEST_ERROR:
      toast.error(
        <ErrorToastMessage message="Network error. Couldn't review selected Tier." />
      );
      return produce(state, draft => {
        draft.isFetching = false;
      });

    default:
      return state;
  }
};

export default tierReducer;
