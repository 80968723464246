import React from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';

import { SERVICE_PROVIDER } from '../actions/actions';
import { ErrorToastMessage } from '@raas-dashboard/base';

const initialState = {
  data: []
};

const serviceProviderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_PROVIDER.FETCH_SUCCEEDED:
      return produce(state, draft => {
        draft.data = action.payload;
      });

    case SERVICE_PROVIDER.FETCH_FAILED:
      toast.error(
        <ErrorToastMessage message="Network error. Couldn't fetch Service Providers." />
      );

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    default:
      return state;
  }
};

export default serviceProviderReducer;
