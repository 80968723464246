import React, { useState } from 'react';

import { HorizontalNav } from '@raas-dashboard/base';

import MfsExchangeRate from './MfsExchangeRate';
import ThunesExchangeRate from './ThunesExchangeRate';
import TrangloExchangeRate from './TrangloExchangeRate';
import ThunesChoiceRemitExchangeRate from './ThunesChoiceRemitExchangeRate';

export const rateProviders = {
  MFS: 'MFS',
  THUNES: 'THUNES',
  THUNES_CHOICE_REMIT: 'THUNES-CHOICE-REMIT',
  TRANGLO: 'TRANGLO'
};

const component = {
  [rateProviders.MFS]: <MfsExchangeRate />,
  [rateProviders.THUNES]: <ThunesExchangeRate />,
  [rateProviders.THUNES_CHOICE_REMIT]: <ThunesChoiceRemitExchangeRate />,
  [rateProviders.TRANGLO]: <TrangloExchangeRate />
};

const ExchangeRate = props => {
  const [activeComponent, setActiveComponent] = useState(rateProviders.THUNES);

  const menuData = [
    {
      name: rateProviders.MFS,
      label: 'MFS',
      onClick: () => setActiveComponent(rateProviders.MFS)
    },
    {
      name: rateProviders.TRANGLO,
      label: 'Tranglo',
      onClick: () => setActiveComponent(rateProviders.TRANGLO)
    },
    {
      name: rateProviders.THUNES,
      label: 'Thunes',
      onClick: () => setActiveComponent(rateProviders.THUNES)
    },
    {
      name: rateProviders.THUNES_CHOICE_REMIT,
      label: 'Thunes(Choice Remit)',
      onClick: () => setActiveComponent(rateProviders.THUNES_CHOICE_REMIT)
    }
  ];

  return (
    <div className="content-container">
      <HorizontalNav activeTab={activeComponent} menuData={menuData} />
      {component[activeComponent]}
    </div>
  );
};

export default ExchangeRate;
