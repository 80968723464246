import { combineReducers } from 'redux';

import ui from './uiReducer';
import msb from './msbReducer';
import mto from './mtoReducer';
import auth from './authReducer';
import tier from './tierReducer';
import sender from './senderReducer';
import kycTag from './kycTagReducer';
import session from './sessionReducer';
import country from './countryReducer';
import affiliate from './affiliateReducer';
import recipient from './recipientReducer';
import originator from './originatorReducer';
import transaction from './transactionReducer';
import exchangeRate from './exchangeRateReducer';
import payoutPartner from './payoutPartnerReducer';
import serviceProvider from './serviceProviderReducer';

export default combineReducers({
  ui,
  session,

  msb,
  mto,
  auth,
  tier,
  sender,
  kycTag,
  country,
  affiliate,
  recipient,
  originator,
  transaction,
  exchangeRate,
  payoutPartner,
  serviceProvider
});
