import React from 'react';
import PropTypes from 'prop-types';

/**
 * Pagination component for table.
 *
 * @param {Object} props
 *
 * @returns {React.FC}
 */
const Pagination = ({
  currentPage,
  pageSize,
  hasNextPage,
  fetchPrevious,
  fetchNext
}) => {
  return (
    <div className="pagination justify-content-end">
      <div
        className={`page-item ${currentPage <= 1 ? 'disabled' : ''}`}
        onClick={fetchPrevious}
      >
        <span className="page-link">Previous</span>
      </div>
      <div className="page-item">
        <span className="page-link">{currentPage}</span>
      </div>

      <div
        className={`page-item ${hasNextPage ? '' : 'disabled'}`}
        onClick={fetchNext}
      >
        <span className="page-link">Next</span>
      </div>
      <div className="page-item">
        <span className="page-link">{`Page Size: ${pageSize}`}</span>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  hasNextPage: PropTypes.bool,
  fetchPrevious: PropTypes.func,
  fetchNext: PropTypes.func
};

export default Pagination;
