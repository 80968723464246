import React from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';

import { MTO } from '../actions/actions';
import { ErrorToastMessage } from '@raas-dashboard/base';

const initialState = {
  data: {},
  isFetching: false,
  error: ''
};

const mtoReducer = (state = initialState, action) => {
  switch (action.type) {
    case MTO.FETCH:
      return produce(state, draft => {
        draft.isFetching = true;
      });
    case MTO.FETCH_SUCCEEDED:
      return produce(state, draft => {
        draft.isFetching = false;
        action.payload.results.forEach(obj => {
          draft.data[obj.id] = obj;
        });
      });

    case MTO.FETCH_FAILED:
      toast.error(
        <ErrorToastMessage message="Network error. Couldn't fetch MTO list." />
      );

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    default:
      return state;
  }
};

export default mtoReducer;
