import { put, call } from 'redux-saga/effects';

import { get, put as putApi, post } from './api';

export function* saveBasicInfo({ payload }) {
  try {
    const { data } = yield call(post, payload);
    yield put({ type: payload.onSuccess, payload: data });
  } catch (error) {
    yield put({ type: payload.onFailure, error });
  }
}

export function* saveAffiliateDraft({ payload }) {
  try {
    const { data } = yield call(post, payload);
    yield put({ type: payload.onSuccess, payload: data });
  } catch (error) {
    yield put({ type: payload.onFailure, error });
  }
}

export function* saveAffiliate({ payload }) {
  try {
    const { data } = yield call(putApi, payload);
    yield put({ type: payload.onSuccess, payload: data });
  } catch (error) {
    yield put({ type: payload.onFailure, error });
  }
}

export function* updateAffiliate({ payload, onSuccess, onFailure }) {
  try {
    const { data } = yield call(putApi, payload);
    onSuccess(data);
  } catch (error) {
    onFailure(error);
  }
}

export function* fetchAffiliates({ payload }) {
  try {
    const { data } = yield call(get, payload);
    yield put({ type: payload.onSuccess, payload: data });
  } catch (error) {
    yield put({ type: payload.onFailure, error });
  }
}

export function* fetchAffiliateDetails({ payload }) {
  try {
    const { data } = yield call(get, payload);
    yield put({ type: payload.onSuccess, payload: data });
  } catch (error) {
    yield put({ type: payload.onFailure, error });
  }
}
