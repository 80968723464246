import React from 'react';
import { Switch } from 'react-router-dom';

import { routes } from 'constants/routes';
import { userRoles } from 'components/common/RoleBasedRoute';

import PrivateRoute from 'components/common/PrivateRoute';

import Recipients from './Recipients';

const RecipientRoutes = _ => (
  <Switch>
    <PrivateRoute
      exact
      path={routes.RECIPIENT}
      component={Recipients}
      roles={[userRoles.MSB, userRoles.SUPER_ADMIN]}
    />
  </Switch>
);

export default RecipientRoutes;
