import React from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';

import { SENDER } from 'actions/actions';
import { PAGE_SIZE } from 'constants/index';
import { ErrorToastMessage } from '@raas-dashboard/base';

const initialState = {
  data: {},
  error: '',
  paging: {
    page: 1,
    has_more: false,
    page_size: PAGE_SIZE
  },
  senderDetail: {},
  recipients: {},
  transactions: {},
  fundingSources: {},
  isFetching: false
};

const senderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SENDER.FETCH:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case SENDER.FETCH_SUCCEEDED:
      return produce(state, draft => {
        draft.isFetching = false;
        action.payload.results.forEach(obj => {
          draft.data[obj.id] = obj;
        });
        draft.paging = action.payload.paging;
      });

    case SENDER.FETCH_FAILED:
      toast.error(<ErrorToastMessage message="Network Error." />);

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    case SENDER.FETCH_SENDER_DETAIL:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case SENDER.FETCH_SENDER_DETAIL_SUCCEEDED:
      return produce(state, draft => {
        draft.isFetching = false;
        draft.senderDetail = action.payload;
      });

    case SENDER.FETCH_SENDER_DETAIL_FAILED:
      toast.error(
        <ErrorToastMessage message="Network Error. Couldn't fetch Details the Sender." />
      );

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    case SENDER.FETCH_RECIPIENTS_OF_SENDER:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case SENDER.FETCH_RECIPIENTS_OF_SENDER_SUCCEEDED:
      return produce(state, draft => {
        draft.isFetching = false;
        action.payload.results.forEach(obj => {
          draft.recipients[obj.id] = obj;
        });
      });

    case SENDER.FETCH_RECIPIENTS_OF_SENDER_FAILED:
      toast.error(
        <ErrorToastMessage message="Network Error.  Couldn't fetch Recipeints of the Sender." />
      );

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    case SENDER.FETCH_TRANSACTIONS_OF_SENDER:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case SENDER.FETCH_TRANSACTIONS_OF_SENDER_SUCCEEDED:
      return produce(state, draft => {
        draft.isFetching = false;
        action.payload.results.forEach(obj => {
          draft.transactions[obj.id] = obj;
        });
      });

    case SENDER.FETCH_TRANSACTIONS_OF_SENDER_FAILED:
      toast.error(
        <ErrorToastMessage message="Network Error. Couldn't fetch Transactions for the Sender." />
      );

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    case SENDER.FETCH_FUNDING_SOURCES_OF_SENDER:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case SENDER.FETCH_FUNDING_SOURCES_OF_SENDER_SUCCEEDED:
      return produce(state, draft => {
        draft.isFetching = false;
        action.payload.results.forEach(obj => {
          draft.fundingSources[obj.id] = obj;
        });
      });

    case SENDER.FETCH_FUNDING_SOURCES_OF_SENDER_FAILED:
      toast.error(
        <ErrorToastMessage message="Network Error. Couldn't fetch Funding Source for the Sender." />
      );

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    case SENDER.INITIALIZE_SENDERS:
      return produce(state, draft => {
        draft.data = {};
        draft.isFetching = false;
        draft.error = '';
        draft.paging = {
          page: 1,
          has_more: false,
          page_size: PAGE_SIZE
        };
        draft.senderDetail = {};
        draft.recipients = {};
        draft.transactions = {};
        draft.fundingSources = {};
      });

    default:
      return state;
  }
};

export default senderReducer;
