import produce from 'immer';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';

import { fetchMsbList } from 'actions/msbAction';
import { fetchMtoList } from 'actions/mtoAction';
import { fetchStates } from 'actions/stateAction';

import { deliveryStatusOptions } from 'constants/deliveryStatus';
import { transactionStatusOptions } from 'constants/transactionStatus';
import {
  isObjectEmpty,
  getQueryString,
  generateComponentId,
  setPreferredTimeZone
} from 'utils/commonUtils';
import {
  fetchTransactions,
  initializeTransactions
} from 'actions/transactionAction';
import {
  holdTransactionDelivery,
  unholdTransactionDelivery,
  requestTransactionDelivery
} from 'actions/adminToolAction';
import {
  Form,
  Table,
  Modal,
  Button,
  Select,
  InputField,
  DatePicker,
  ErrorToastMessage,
  SuccessToastMessage
} from '@raas-dashboard/base';

import './ChangeDeliveryStatus.scss';
import '../transaction/Transactions.scss';

const ChangeDeliveryStatus = props => {
  const {
    msbs,
    mtos,
    states,
    hasMore,
    isFetching,
    fetchStates,
    fetchMsbList,
    fetchMtoList,
    transactions,
    fetchTransactions,
    holdTransactionDelivery,
    initializeTransactions,
    unholdTransactionDelivery,
    requestTransactionDelivery
  } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(20);
  const [filter, setFilter] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [displayData, setDisplayData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const openChangeTxnStatusModal = transaction => {
    setSelectedTransaction(transaction);
    setIsModalVisible(true);
  };

  const cols = [
    {
      label: 'Reference Number',
      field: 'mto_reference_number'
    },
    { label: 'Sender', field: 'sender_name' },
    {
      label: 'Receiver',
      field: 'recipient_name'
    },
    { label: 'Txn. Date', field: 'txn_date' },
    { label: 'Total Amount', field: 'total_amount' },
    { label: 'Status', field: 'status' },
    {
      label: 'Delivery Status',
      field: 'delivery_status'
    },
    {
      label: 'Remarks',
      field: 'remarks'
    },
    {
      label: 'Bank Flag',
      field: 'bank_flag'
    },
    { label: 'Actions', field: 'buttons' }
  ];

  const getVisibleData = (dataArray, page) => {
    //todo: later currency needs to be used from backend data.
    const currency = 'USD';
    const slicedDataArray = dataArray
      .sort((a, b) => {
        const dateA = new Date(a.created_on);
        const dateB = new Date(b.created_on);

        return dateB - dateA;
      })
      .slice((page - 1) * pageSize, page * pageSize);
    const mappedData = produce(slicedDataArray, draft => {
      for (let key in slicedDataArray) {
        const {
          total_amount,
          exchange_rate,
          recipient_amount
        } = slicedDataArray[key];
        draft[key].exchange_rate = (
          <div className="text-align-right">
            <span>{`${currency} ${parseFloat(exchange_rate).toFixed(2)}`}</span>
          </div>
        );
        draft[key].total_amount = (
          <div className="text-align-right">
            <span>{`${currency} ${parseFloat(total_amount).toFixed(2)}`}</span>
          </div>
        );
        draft[key].recipient_amount = (
          <div className="text-align-right">
            <span>
              {`${currency} ${parseFloat(recipient_amount).toFixed(2)}`}
            </span>
          </div>
        );
        draft[key].buttons = (slicedDataArray[key].delivery_status ===
          'PENDING' ||
          slicedDataArray[key].delivery_status === 'HOLD') && (
          <button
            onClick={() => openChangeTxnStatusModal(slicedDataArray[key])}
          >
            Change delivery status
          </button>
        );
        draft[key].status = slicedDataArray[key].status && (
          <span
            className={`trans-status ${slicedDataArray[
              key
            ].status.toLowerCase()}`}
          >
            {slicedDataArray[key].status}
          </span>
        );
        draft[key].delivery_status = slicedDataArray[key].delivery_status && (
          <span
            className={`delivery-status ${slicedDataArray[
              key
            ].delivery_status.toLowerCase()}`}
          >
            {slicedDataArray[key].delivery_status}
          </span>
        );
        draft[key].txn_date = setPreferredTimeZone(
          slicedDataArray[key].created_on
        );
      }
    });

    return mappedData;
  };

  const hasNextPage =
    hasMore ||
    getVisibleData(Object.values(transactions), currentPage + 1).length;
  const pagination = {
    currentPage,
    pageSize,
    hasNextPage,
    fetchPrevious: () => fetchPrevious(currentPage),
    fetchNext: () => fetchNext(fetchTransactions, currentPage, filter)
  };

  const fetchPrevious = currentPage => {
    setCurrentPage(currentPage - 1);
  };

  const fetchNext = (fetchTransactions, currentPage, filter) => {
    const nextPage = currentPage + 1;

    setCurrentPage(nextPage);
    if (!getVisibleData(Object.values(transactions), nextPage).length) {
      fetchTransactions(nextPage, getQueryString(filter));
    }
  };

  useEffect(() => {
    if (isObjectEmpty(states)) {
      fetchStates();
    }
    if (isObjectEmpty(msbs)) {
      fetchMsbList();
    }
    if (isObjectEmpty(mtos)) {
      fetchMtoList();
    }
  }, []);

  useEffect(() => {
    if (!isObjectEmpty(msbs)) {
      setFilter({
        msb_id: Object.keys(msbs)[0]
      });
    }
  }, [props.msbs]);

  useEffect(() => {
    const initialPage = 1;

    setCurrentPage(initialPage);
    initializeTransactions();

    if (filter.msb_id) {
      fetchTransactions(initialPage, getQueryString(filter));
    }
  }, [filter]);

  useEffect(() => {
    setIsLoading(true);
    setDisplayData(getVisibleData(Object.values(transactions), currentPage));
  }, [filter, currentPage, transactions]);

  useEffect(() => {
    setIsLoading(false);
  }, [displayData]);

  const handleUpdateTxnStatus = data => {
    const requestData = {
      comment: data.comment,
      status: data.updatedStatus
    };
    const onSuccess = () => {
      toast.success(<SuccessToastMessage message="Status has been updated." />);
      initializeTransactions();
      fetchTransactions(currentPage, getQueryString(filter));
      setIsModalVisible(false);
    };
    const onFailure = error => {
      toast.error(<ErrorToastMessage message={error.message} />);
      setIsModalVisible(false);
    };
    const params = {
      senderId: selectedTransaction.sender_id,
      transactionId: selectedTransaction.id,
      data: requestData,
      onSuccess,
      onFailure
    };
    if (requestData.status === 'UNHOLD') {
      unholdTransactionDelivery(params);
    } else if (requestData.status === 'DELIVERY_REQUESTED') {
      requestTransactionDelivery(params);
    } else {
      holdTransactionDelivery(params);
    }
  };
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  return (
    <div className="content-container">
      Change Delivery Status
      {isFilterVisible && (
        <Filter
          filter={filter}
          setFilter={setFilter}
          states={states}
          msbs={msbs}
          mtos={mtos}
        />
      )}
      <div className="toggle-filter__wrapper">
        {isFilterVisible ? (
          <span className="toggle-filter__button" onClick={toggleFilter}>
            Hide Filter{' '}
            <i class="fa fa-chevron-circle-up" aria-hidden="true"></i>
          </span>
        ) : (
          <span className="toggle-filter__button" onClick={toggleFilter}>
            Show Filter{' '}
            <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
          </span>
        )}
      </div>
      {isModalVisible && (
        <Modal onClose={() => setIsModalVisible(false)}>
          <div className="modal-container">
            <ChangeStatusForm
              selectedTransaction={selectedTransaction}
              onSubmit={handleUpdateTxnStatus}
            />
          </div>
        </Modal>
      )}
      <Table
        cols={cols}
        data={displayData}
        isLoading={isFetching || isLoading}
        pagination={pagination}
      />
    </div>
  );
};

const ChangeStatusForm = ({ selectedTransaction, onSubmit }) => {
  const pendingStatusOptions = [
    { name: 'HOLD', value: 'HOLD' },
    { name: 'REQUEST', value: 'DELIVERY_REQUESTED' }
  ];
  const holdStatusOptions = [{ name: 'UNHOLD', value: 'UNHOLD' }];
  return (
    <Form initialValues={{}} onSubmit={onSubmit}>
      <Select
        name="updatedStatus"
        id={generateComponentId('updatedStatus', 'change-txn-status-form')}
        label="Transaction status"
        placeholder="Select Status"
        options={
          selectedTransaction.delivery_status === 'PENDING'
            ? pendingStatusOptions
            : holdStatusOptions
        }
      />

      <InputField
        name="comment"
        id={generateComponentId('comment', 'change-txn-status-form')}
        label={'Comment'}
      />
      <div className="change-txn-status__button-wrapper">
        <div className="change-txn-status__button">
          <Button name="Update" type="submit" />
        </div>
      </div>
    </Form>
  );
};

const Filter = ({ filter, setFilter, states, msbs, mtos }) => {
  const mtoOptions = (mtos = {}) => {
    const result = [];
    produce(mtos, draft => {
      for (let key in mtos) {
        result.push({
          name: mtos[key].name,
          value: mtos[key].id
        });
      }
    });

    return result;
  };

  const submitFilter = filter => {
    const updatedFilter = produce(filter, draft => {
      if (filter.from_date && filter.end_date) {
        draft.start_date = `btn:${moment(filter.from_date)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss')},${moment(filter.end_date)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss')}`;
      } else if (filter.from_date) {
        draft.start_date = `btn:${moment(filter.from_date)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss')},${moment()
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss')}`;
      }
    });
    setFilter(updatedFilter);
  };

  const FormElements = props => {
    const { values, setValues } = useFormikContext();
    const { status, mto_id, delivery_status, mto_reference_number } = values;

    return (
      <>
        <div className="txn-filter__element">
          <InputField
            name="mto_reference_number"
            id={generateComponentId('mto_reference_number', 'txn-filter')}
            label="Reference Number"
            value={mto_reference_number}
          />
        </div>
        <div className="txn-filter__element">
          <Select
            name="mto_id"
            id={generateComponentId('mto_id', 'txn-filter')}
            label="Affiliate"
            placeholder="Select Affiliate"
            options={mtoOptions(mtos)}
            value={mto_id}
          />
        </div>
        <div className="txn-filter__element">
          <Select
            name="status"
            id={generateComponentId('status', 'txn-filter')}
            label="Transaction Status"
            placeholder="Select Status"
            options={transactionStatusOptions}
            value={status}
          />
        </div>
        <div className="txn-filter__element">
          <Select
            name="delivery_status"
            id={generateComponentId('delivery_status', 'txn-filter')}
            label="Delivery Status"
            placeholder="Select Delivery Status"
            options={deliveryStatusOptions}
            value={delivery_status}
          />
        </div>
        <div className="txn-filter__element">
          <DatePicker
            name="from_date"
            id={generateComponentId('from_date', 'txn-filter')}
            label="From Date"
          />
        </div>
        <div className="txn-filter__element">
          <DatePicker
            name="end_date"
            id={generateComponentId('end_date', 'txn-filter')}
            label="To Date"
          />
        </div>

        <div className="txn-filter__button-wrapper">
          <div className="txn-filter__button">
            <Button name="Filter" type="submit" />
          </div>
          <div className="txn-filter__button">
            <Button
              name="Reset"
              type="reset"
              onClick={() => {
                setValues({ msb_id: Object.keys(msbs)[0] });
                setFilter({ msb_id: Object.keys(msbs)[0] });
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="txn-filter">
      <Form initialValues={filter} onSubmit={submitFilter}>
        <FormElements />
      </Form>
    </div>
  );
};

export default compose(
  connect(
    state => ({
      msbs: state.msb.data,
      mtos: state.mto.data,
      states: state.country.states,
      transactions: state.transaction.data,
      isFetching: state.transaction.isFetching,
      hasMore: state.transaction.paging.has_more
    }),
    dispatch => ({
      fetchMsbList: () => dispatch(fetchMsbList()),
      fetchMtoList: () => dispatch(fetchMtoList()),
      fetchStates: country => dispatch(fetchStates(country)),
      unholdTransactionDelivery: object =>
        dispatch(unholdTransactionDelivery(object)),
      holdTransactionDelivery: object =>
        dispatch(holdTransactionDelivery(object)),
      requestTransactionDelivery: object =>
        dispatch(requestTransactionDelivery(object)),
      fetchTransactions: (page, filter) =>
        dispatch(fetchTransactions(page, filter)),
      initializeTransactions: () => dispatch(initializeTransactions())
    })
  )
)(ChangeDeliveryStatus);
