import React from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';

import { AFFILIATE } from '../actions/actions';
import { ErrorToastMessage, SuccessToastMessage } from '@raas-dashboard/base';

const initialState = {
  data: {},
  affiliate: {},
  basicInfo: {},
  tiers: {},
  originators: {},
  payoutPartners: {},
  serviceProviders: {},
  activeConfiguration: '',
  isFetching: false
};

const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case AFFILIATE.SAVE_SUCCEEDED:
      toast.error(<SuccessToastMessage message="Affiliate has been saved" />);

      return produce(state, draft => {
        draft.affiliate = action.payload;
      });

    case AFFILIATE.SAVE_BASIC_INFO:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case AFFILIATE.SAVE_BASIC_INFO_SUCCEEDED:
      return produce(state, draft => {
        draft.isFetching = false;
        draft.basicInfo = action.payload.basicInfo;
      });

    case AFFILIATE.SAVE_BASIC_INFO_FAILED:
      toast.error(<ErrorToastMessage message="Network Error." />);

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    case AFFILIATE.SAVE_DRAFT:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case AFFILIATE.SAVE_DRAFT_SUCCEEDED:
      toast.error(<SuccessToastMessage message="Draft saved" />);

      return produce(state, draft => {
        draft.isFetching = false;
      });

    case AFFILIATE.SAVE_DRAFT_FAILED:
      toast.error(
        <ErrorToastMessage message="Network Error. Draft couldn't be saved" />
      );

      return produce(state, draft => {
        draft.isFetching = false;
      });

    case AFFILIATE.SAVE_KYC_INFO:
      return produce(state, draft => {
        draft.tiers[`level${action.payload.level}`] = action.payload.tierInfo;
      });

    case AFFILIATE.SAVE_ORIGINATOR_INFO:
      return produce(state, draft => {
        draft.originators = action.payload;
      });

    case AFFILIATE.SAVE_PAYOUT_PARTNER_INFO:
      return produce(state, draft => {
        draft.payoutPartners = action.payload;
      });

    case AFFILIATE.SAVE_SERVICE_PROVIDER_INFO:
      return produce(state, draft => {
        draft.serviceProviders = action.payload;
      });

    case AFFILIATE.TOGGLE_CONFIGURATION_COMPONENT:
      return produce(state, draft => {
        draft.activeConfiguration = action.payload;
      });

    case AFFILIATE.FETCH:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case AFFILIATE.FETCH_SUCCEEDED:
      return produce(state, draft => {
        draft.isFetching = false;
        action.payload.forEach(obj => {
          draft.data[obj.id] = obj;
        });
      });

    case AFFILIATE.FETCH_FAILED:
      toast.error(<ErrorToastMessage message="Network Error." />);

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    case AFFILIATE.FETCH_DETAIL:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case AFFILIATE.FETCH_DETAIL_SUCCEEDED:
      return produce(state, draft => {
        draft.isFetching = false;
        draft.affiliate = action.payload;
      });

    case AFFILIATE.FETCH_DETAIL_FAILED:
      toast.error(<ErrorToastMessage message="Network Error." />);

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.error;
      });

    default:
      return state;
  }
};

export default countriesReducer;
