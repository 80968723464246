import { AFFILIATE } from './actions';

export const fetchAffiliates = _ => ({
  type: AFFILIATE.FETCH,
  payload: {
    url: '/raas/affiliates',
    onSuccess: AFFILIATE.FETCH_SUCCEEDED,
    onFailure: AFFILIATE.FETCH_FAILED
  }
});

export const saveAffiliate = affiliateDetail => ({
  type: AFFILIATE.SAVE,
  payload: {
    url: `/raas/affiliates/${affiliateDetail.basicInfo.id}`,
    data: affiliateDetail,
    onSuccess: AFFILIATE.SAVE_SUCCEEDED,
    onFailure: AFFILIATE.SAVE_FAILED
  }
});

export const saveAffiliateDraft = affiliateDetail => ({
  type: AFFILIATE.SAVE_DRAFT,
  payload: {
    url: `/raas/affiliates/${affiliateDetail.basicInfo.id}/draft`,
    data: affiliateDetail,
    onSuccess: AFFILIATE.SAVE_DRAFT_SUCCEEDED,
    onFailure: AFFILIATE.SAVE_DRAFT_FAILED
  }
});

export const fetchAffiliateDetails = affiliateId => ({
  type: AFFILIATE.FETCH_DETAIL,
  payload: {
    url: `/raas/affiliates/${affiliateId}`,
    onSuccess: AFFILIATE.FETCH_DETAIL_SUCCEEDED,
    onFailure: AFFILIATE.FETCH_DETAIL_FAILED
  }
});

export const toggleComponent = component => ({
  type: AFFILIATE.TOGGLE_CONFIGURATION_COMPONENT,
  payload: component
});

export const saveBasicInfo = basicInfo => ({
  type: AFFILIATE.SAVE_BASIC_INFO,
  payload: {
    url: '/raas/affiliates',
    data: basicInfo,
    onSuccess: AFFILIATE.SAVE_BASIC_INFO_SUCCEEDED,
    onFailure: AFFILIATE.SAVE_BASIC_INFO_FAILED
  }
});

export const updateAffiliate = (
  affiliateId,
  affiliateInfo,
  onSuccess,
  onFailure
) => ({
  type: AFFILIATE.UPDATE,
  payload: {
    url: `/raas/affiliates/${affiliateId}`,
    data: affiliateInfo
  },
  onSuccess,
  onFailure
});

export const saveTierInfo = (level, tierInfo) => ({
  type: AFFILIATE.SAVE_KYC_INFO,
  payload: { level, tierInfo }
});

export const saveOriginatorInfo = originators => ({
  type: AFFILIATE.SAVE_ORIGINATOR_INFO,
  payload: originators
});

export const savePayoutPartnerInfo = payoutPartners => ({
  type: AFFILIATE.SAVE_PAYOUT_PARTNER_INFO,
  payload: payoutPartners
});

export const saveServiceProviderInfo = serviceProviders => ({
  type: AFFILIATE.SAVE_SERVICE_PROVIDER_INFO,
  payload: serviceProviders
});
