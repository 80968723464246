import React from 'react';
import PropTypes from 'prop-types';

/**
 * Header component.
 *
 * @param {Object} props
 *
 * @returns {React.FC}
 */
export const Header = ({ text, size = 'medium' }) => {
  switch (size) {
    case 'small':
      return <h3>{text}</h3>;

    case 'large':
      return <h1>{text}</h1>;

    default:
      return <h2>{text}</h2>;
  }
};

Header.propTypes = {
  size: PropTypes.string,
  text: PropTypes.string
};

export default Header;
