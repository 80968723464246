import { ORIGINATOR } from './actions';

export const fetchOriginators = _ => ({
  type: ORIGINATOR.FETCH,
  payload: {
    url: '/raas/originators',
    onSuccess: ORIGINATOR.FETCH_SUCCEEDED,
    onFailure: ORIGINATOR.FETCH_FAILED
  }
});
