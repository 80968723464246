import moment from 'moment';
import produce from 'immer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';

import { fetchMsbList } from 'actions/msbAction';
import { fetchMtoList } from 'actions/mtoAction';
import { fetchStates } from 'actions/stateAction';

import { transactionStatus } from 'constants/transactionStatus';
import {
  isObjectEmpty,
  getQueryString,
  generateComponentId,
  setPreferredTimeZone
} from 'utils/commonUtils';
import {
  fetchTransactions,
  fetchAllTransactions,
  initializeTransactions
} from 'actions/transactionAction';
import {
  Form,
  Table,
  Modal,
  Header,
  Button,
  Loader,
  Select,
  Checkbox,
  InputField,
  DatePicker,
  exportReport,
  ErrorToastMessage,
  SuccessToastMessage
} from '@raas-dashboard/base';

import './Transactions.scss';

const RefundTransactions = props => {
  const {
    msbs,
    mtos,
    states,
    hasMore,
    isFetching,
    fetchStates,
    fetchMsbList,
    fetchMtoList,
    transactions,
    fetchTransactions,
    fetchAllTransactions,
    initializeTransactions
  } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(20);
  const [filter, setFilter] = useState({});
  const [displayData, setDisplayData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const cols = [
    {
      label: 'Reference Number',
      field: 'mto_reference_number'
    },
    { label: 'Sender', field: 'sender_name' },
    {
      label: 'Receiver',
      field: 'recipient_name'
    },
    { label: 'Txn. Date', field: 'txn_date' },
    { label: 'FX Rate', field: 'exchange_rate' },
    { label: 'Total Amount', field: 'total_amount' },
    { label: 'Received Amount', field: 'recipient_amount' },
    { label: 'Status', field: 'status' },
    { label: 'Buttons', field: 'buttons' }
  ];

  const getVisibleData = (dataArray, page) => {
    //todo: later currency needs to be used from backend data.
    const currency = 'USD';
    const slicedDataArray = dataArray
      .sort((a, b) => {
        const dateA = new Date(a.created_on);
        const dateB = new Date(b.created_on);

        return dateB - dateA;
      })
      .slice((page - 1) * pageSize, page * pageSize);
    const mappedData = produce(slicedDataArray, draft => {
      for (let key in slicedDataArray) {
        const {
          id,
          sender_id,
          total_amount,
          exchange_rate,
          recipient_amount
        } = slicedDataArray[key];
        draft[key].exchange_rate = (
          <div className="text-align-right">
            <span>{`${currency} ${parseFloat(exchange_rate).toFixed(2)}`}</span>
          </div>
        );
        draft[key].total_amount = (
          <div className="text-align-right">
            <span>{`${currency} ${parseFloat(total_amount).toFixed(2)}`}</span>
          </div>
        );
        draft[key].recipient_amount = (
          <div className="text-align-right">
            <span>
              {`${currency} ${parseFloat(recipient_amount).toFixed(2)}`}
            </span>
          </div>
        );
        draft[key].buttons = (
          <Link to={`/senders/${sender_id}/transactions/${id}`} target="_blank">
            <button>View Detail</button>
          </Link>
        );
        draft[key].status = slicedDataArray[key].status && (
          <span
            className={`trans-status ${slicedDataArray[
              key
            ].status.toLowerCase()}`}
          >
            {slicedDataArray[key].status}
          </span>
        );
        draft[key].txn_date = setPreferredTimeZone(
          slicedDataArray[key].created_on
        );
      }
    });

    return mappedData;
  };

  const hasNextPage =
    hasMore ||
    getVisibleData(Object.values(transactions), currentPage + 1).length;
  const pagination = {
    currentPage,
    pageSize,
    hasNextPage,
    fetchPrevious: () => fetchPrevious(currentPage),
    fetchNext: () => fetchNext(fetchTransactions, currentPage, filter)
  };

  const fetchPrevious = currentPage => {
    setCurrentPage(currentPage - 1);
  };

  const fetchNext = (fetchTransactions, currentPage, filter) => {
    const nextPage = currentPage + 1;

    setCurrentPage(nextPage);
    if (!getVisibleData(Object.values(transactions), nextPage).length) {
      fetchTransactions(nextPage, getQueryString(filter));
    }
  };

  useEffect(() => {
    if (isObjectEmpty(states)) {
      fetchStates();
    }
    if (isObjectEmpty(msbs)) {
      fetchMsbList();
    }
    if (isObjectEmpty(mtos)) {
      fetchMtoList();
    }
  }, []);

  useEffect(() => {
    if (!isObjectEmpty(msbs)) {
      setFilter({
        msb_id: Object.keys(msbs)[0],
        type: transactionStatus.REFUNDED
      });
    }
  }, [props.msbs]);

  useEffect(() => {
    const initialPage = 1;

    setCurrentPage(initialPage);
    initializeTransactions();

    if (filter.msb_id) {
      fetchTransactions(initialPage, getQueryString(filter));
    }
  }, [filter]);

  useEffect(() => {
    setIsLoading(true);
    setDisplayData(getVisibleData(Object.values(transactions), currentPage));
  }, [filter, currentPage, transactions]);

  useEffect(() => {
    setIsLoading(false);
  }, [displayData]);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const mapDataForExport = data => {
    const mappedData = [];

    data.forEach(transaction => {
      const { from: sender, to: recipient } = transaction;
      const { account_detail: senderAccountDetail } = sender;
      const { account_detail: recipientAccountDetail } = recipient;
      mappedData.push({
        mto_reference_number: transaction.mto_reference_number,
        txn_date: transaction.created_on,
        updated_date: transaction.last_modified_on,
        status: transaction.status,
        payment_processor_id: transaction.payment_processor_txn_id,
        sender_name: sender.full_name,
        sender_email: sender.email,
        sender_city: sender.city,
        sender_state: sender.state,
        sender_zip_code: sender.zipcode,
        sender_mobile: sender.mobile_phone,
        sender_bank_name:
          senderAccountDetail && senderAccountDetail.funding_source_name,
        sender_ssn: sender.ssn,
        sender_dob: sender.date_of_birth,
        recipient_name: recipient.full_name,
        recipient_city: recipient.city,
        recipient_phone: recipient.mobile_phone,
        recipient_bank:
          recipientAccountDetail && recipientAccountDetail.bank_name,
        recipient_account_number:
          recipientAccountDetail && recipientAccountDetail.account_number,
        txn_amount_usd: transaction.total_amount,
        txn_fee: transaction.fee_amount,
        amount_received: transaction.recipient_amount,
        exchange_rate: transaction.exchange_rate,
        ip_address: transaction.ip_address,
        method_of_payment: transaction.funding_source_type,
        txn_time: transaction.created_on,
        sender_document_type: sender.document_type,
        sender_document_id_number: sender.document_id_number,
        sender_document_issued_country: sender.document_issued_country,
        sender_document_issued_state: sender.document_issued_state,
        purpose_of_remittance: transaction.remittance_purpose,
        source_of_income: sender.source_of_income,
        recipient_relationship: recipient.sender_relationship,
        mto: transaction.mto_id,
        msb: transaction.msb_id,
        wire_status: transaction.wire_status,
        reference_number: transaction.mto_reference_number,
        debit_card_network: senderAccountDetail && senderAccountDetail.network,
        method_of_payout: transaction.payout_method,
        cash_pickup_location: transaction.cash_pickup_location,
        risk_score: transaction.risk_score,
        card_fee: transaction.card_txn_fees
      });
    });
    return mappedData;
  };

  const [isExporting, setIsExporting] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);

  const handleExport = exportCols => {
    const onSuccess = data => {
      const exportData = mapDataForExport(data.results);
      exportReport({
        exportCols,
        exportData,
        fileName: `Refund_Transaction_Report_${moment().format(
          'YYYY-MM-DD_HH-mm-ss'
        )}`,
        fileExtension: '.xlsx'
      });
      setIsExporting(false);
      setIsExportModalVisible(false);
      toast.success(
        <SuccessToastMessage message="Refunded Transaction Report Exported" />
      );
    };
    const onFailure = () => {
      toast.error(
        <ErrorToastMessage message="Failed to export refund transaction report." />
      );
      setIsExporting(false);
      setIsExportModalVisible(false);
    };
    setIsExporting(true);
    fetchAllTransactions(
      getQueryString({ type: transactionStatus.REFUNDED }),
      onSuccess,
      onFailure
    );
  };

  return (
    <div className="content-container">
      <Header text="Refund Report" />
      {isFilterVisible && (
        <Filter
          filter={filter}
          setFilter={setFilter}
          states={states}
          msbs={msbs}
          mtos={mtos}
        />
      )}
      <div className="toggle-filter__wrapper">
        {isFilterVisible ? (
          <span className="toggle-filter__button" onClick={toggleFilter}>
            Hide Filter{' '}
            <i class="fa fa-chevron-circle-up" aria-hidden="true"></i>
          </span>
        ) : (
          <span className="toggle-filter__button" onClick={toggleFilter}>
            Show Filter{' '}
            <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
          </span>
        )}
      </div>
      {isExportModalVisible && (
        <ExportModal
          onSubmit={handleExport}
          onClose={() => setIsExportModalVisible(false)}
          isExporting={isExporting}
        />
      )}
      <div className="txn-export__container">
        <button
          className="txn-export__button"
          onClick={() => setIsExportModalVisible(true)}
          disabled={isExporting}
        >
          <span>
            <i class="fas fa-download"></i> Export
          </span>
        </button>
      </div>

      <Table
        cols={cols}
        data={displayData}
        isLoading={isFetching || isLoading}
        pagination={pagination}
      />
    </div>
  );
};

const ExportModal = ({ onSubmit, onClose, isExporting }) => {
  const exportCols = [
    { label: 'RTRN', field: 'mto_reference_number' },
    { label: 'Txn. Date', field: 'txn_date' },
    { label: 'Last Updated', field: 'updated_date' },
    { label: 'Status', field: 'status' },
    { label: 'Payment Processor ID', field: 'payment_processor_id' },
    { label: 'Sender Name', field: 'sender_name' },
    { label: 'Sender Email', field: 'sender_email' },
    { label: 'Sender City', field: 'sender_city' },
    { label: 'Sender State', field: 'sender_state' },
    { label: 'Sender ZIP code', field: 'sender_zip_code' },
    { label: 'Sender Mobile Number', field: 'sender_mobile' },
    { label: 'Sender Bank Name', field: 'sender_bank_name' },
    { label: 'Sender SSN', field: 'sender_ssn' },
    { label: 'Sender Date of Birth', field: 'sender_dob' },
    { label: 'Beneficiary Name', field: 'recipient_name' },
    { label: 'Beneficiary City', field: 'recipient_city' },
    { label: 'Beneficiary Tel Number', field: 'recipient_phone' },
    { label: 'Beneficiary Bank', field: 'recipient_bank' },
    { label: 'Beneficiary Bank A/C No.', field: 'recipient_account_number' },
    { label: 'Txn Amount USD', field: 'txn_amount_usd' },
    { label: 'Transaction Fee or Commission(USD)', field: 'txn_fee' },
    { label: 'Amount Received', field: 'amount_received' },
    { label: 'Rate of Exchange', field: 'exchange_rate' },
    { label: 'IP address', field: 'ip_address' },
    { label: 'Method of Payment', field: 'method_of_payment' },
    { label: 'Transaction Time', field: 'txn_time' },
    { label: 'Sender Document Type', field: 'sender_document_type' },
    { label: 'Sender Document ID Number', field: 'sender_document_id_number' },
    {
      label: 'Sender Document Issued Country',
      field: 'sender_document_issued_country'
    },
    {
      label: 'Sender Document Issued State',
      field: 'sender_document_issued_state'
    },
    { label: 'Purpose of Remittance', field: 'purpose_of_remittance' },
    { label: 'Source of Income', field: 'source_of_income' },
    { label: 'Beneficiary Relationship', field: 'beneficiary_relationship' },
    { label: 'MTO', field: 'mto' },
    { label: 'MSB', field: 'msb' },
    { label: 'Wire Status', field: 'wire_status' },
    { label: 'MSB Reference Number', field: 'reference_number' },
    { label: 'Debit Card issuing network', field: 'debit_card_network' },
    { label: 'Method of Payout', field: 'method_of_payout' },
    { label: 'Cash Pick-up Location', field: 'cash_pickup_location' },
    { label: 'Risk Score', field: 'risk_score' },
    { label: 'Debit Card Processing fees', field: 'card_fee' }
  ];
  const selectedExportFields = {
    mto_reference_number: true,
    txn_date: true,
    updated_date: true,
    status: true,
    payment_processor_id: true,
    sender_name: true,
    sender_email: true,
    sender_city: true,
    sender_state: true,
    sender_zip_code: true,
    sender_mobile: true,
    sender_bank_name: true,
    sender_ssn: true,
    sender_dob: true,
    recipient_name: true,
    recipient_city: true,
    recipient_phone: true,
    recipient_bank: true,
    recipient_account_number: true,
    txn_amount_usd: true,
    txn_fee: true,
    amount_received: true,
    exchange_rate: true,
    ip_address: true,
    method_of_payment: true,
    txn_time: true,
    sender_document_type: true,
    sender_document_id_number: true,
    sender_document_issued_country: true,
    sender_document_issued_state: true,
    purpose_of_remittance: true,
    source_of_income: true,
    recipient_relationship: true,
    mto: true,
    msb: true,
    wire_status: true,
    reference_number: true,
    debit_card_network: true,
    method_of_payout: true,
    cash_pickup_location: true,
    risk_score: true,
    card_fee: true,
    beneficiary_relationship: true
  };
  const handleSubmit = data => {
    const selectedExportCols = [];
    exportCols.forEach(exportCol => {
      if (data.exportFields[exportCol.field]) {
        selectedExportCols.push(exportCol);
      }
    });

    onSubmit(selectedExportCols);
  };

  return (
    <Modal onClose={onClose}>
      <div className="container">
        <div>
          <Header text="Export transaction Report" />
        </div>
        <Form
          initialValues={{ exportFields: selectedExportFields }}
          onSubmit={handleSubmit}
        >
          <div className="txn-export__field-selector">
            {exportCols.map(col => (
              <Checkbox label={col.label} name={`exportFields[${col.field}]`} />
            ))}
          </div>
          <div className="txn-export__btn-container">
            <Button
              className="txn-export__button"
              type="submit"
              disabled={isExporting}
            >
              <span>
                <i class="fas fa-download"></i> Export
              </span>
              {isExporting && (
                <div className="txn-export__loader">
                  <Loader />
                </div>
              )}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const Filter = ({ filter, setFilter, states, msbs, mtos }) => {
  const msbOptions = (msbs = {}) => {
    const result = [];
    produce(msbs, draft => {
      for (let key in msbs) {
        result.push({
          name: msbs[key].name,
          value: msbs[key].id
        });
      }
    });

    return result;
  };
  const mtoOptions = (mtos = {}) => {
    const result = [];
    produce(mtos, draft => {
      for (let key in mtos) {
        result.push({
          name: mtos[key].name,
          value: mtos[key].id
        });
      }
    });

    return result;
  };
  const stateOptions = (states = {}) => {
    const result = [];
    produce(states, draft => {
      for (let key in states) {
        result.push({
          name: states[key].name,
          value: states[key].code
        });
      }
    });

    return result;
  };
  const fundingSourceOptions = [
    { name: 'BANK', value: 'BANK' },
    { name: 'CARD', value: 'CARD' }
  ];
  const senderKycStatusOptions = [
    { name: 'VERIFIED', value: 'VERIFIED' },
    { name: 'DOCUMENT', value: 'DOCUMENT' },
    { name: 'DOCUMENT_REQUESTED', value: 'DOCUMENT_REQUESTED' },
    { name: 'UNVERIFIED', value: 'UNVERIFIED' },
    { name: 'RETRY', value: 'RETRY' },
    { name: 'RETRY_REQUESTED', value: 'RETRY_REQUESTED' },
    { name: 'REVIEW_PENDING', value: 'REVIEW_PENDING' },
    { name: 'IN_PROGRESS', value: 'IN_PROGRESS' },
    { name: 'SUSPENDED', value: 'SUSPENDED' }
  ];
  const submitFilter = filter => {
    const updatedFilter = produce(filter, draft => {
      if (filter.from_date && filter.end_date) {
        draft.start_date = `btn:${moment(filter.from_date)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss')},${moment(filter.end_date)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss')}`;
      } else if (filter.from_date) {
        draft.start_date = `btn:${moment(filter.from_date)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss')},${moment()
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss')}`;
      }
    });
    setFilter(updatedFilter);
  };

  const FormElements = props => {
    const { values, setValues } = useFormikContext();
    const {
      state,
      mto_id,
      msb_id,
      kyc_status,
      funding_source,
      mto_reference_number
    } = values;

    return (
      <>
        <div className="txn-filter__element">
          <InputField
            name="mto_reference_number"
            id={generateComponentId('mto_reference_number', 'txn-filter')}
            label="Reference Number"
            value={mto_reference_number}
          />
        </div>
        <div className="txn-filter__element">
          <Select
            name="msb_id"
            id={generateComponentId('msb_id', 'txn-filter')}
            label="MSB"
            placeholder="Select MSB"
            options={msbOptions(msbs)}
            value={msb_id}
          />
        </div>
        <div className="txn-filter__element">
          <Select
            name="mto_id"
            id={generateComponentId('mto_id', 'txn-filter')}
            label="Affiliate"
            placeholder="Select Affiliate"
            options={mtoOptions(mtos)}
            value={mto_id}
          />
        </div>
        <div className="txn-filter__element">
          <Select
            name="state"
            id={generateComponentId('state', 'txn-filter')}
            label="State"
            placeholder="Select State"
            options={stateOptions(states)}
            value={state}
          />
        </div>
        <div className="txn-filter__element">
          <Select
            name="funding_source"
            id={generateComponentId('funding_source', 'txn-filter')}
            label="Funding Source"
            placeholder="Select Funding Source"
            options={fundingSourceOptions}
            value={funding_source}
          />
        </div>
        <div className="txn-filter__element">
          <Select
            name="kyc_status"
            id={generateComponentId('kyc_status', 'txn-filter')}
            label="Sender KYC Status"
            placeholder="Select Sender KYC Status"
            options={senderKycStatusOptions}
            value={kyc_status}
          />
        </div>
        <div className="txn-filter__element">
          <DatePicker
            name="from_date"
            id={generateComponentId('from_date', 'txn-filter')}
            label="From Date"
          />
        </div>
        <div className="txn-filter__element">
          <DatePicker
            name="end_date"
            id={generateComponentId('end_date', 'txn-filter')}
            label="To Date"
          />
        </div>

        <div className="txn-filter__button-wrapper">
          <div className="txn-filter__button">
            <Button className="btn btn-primary" name="Filter" type="submit" />
          </div>
          <div className="txn-filter__button">
            <Button
              name="Reset"
              className="btn btn-primary"
              type="reset"
              onClick={() => {
                setValues({ msb_id: Object.keys(msbs)[0] });
                setFilter({
                  msb_id: Object.keys(msbs)[0],
                  type: transactionStatus.REFUNDED
                });
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="txn-filter">
      <Form initialValues={filter} onSubmit={submitFilter}>
        <FormElements />
      </Form>
    </div>
  );
};

export default compose(
  connect(
    state => ({
      msbs: state.msb.data,
      mtos: state.mto.data,
      states: state.country.states,
      transactions: state.transaction.data,
      isFetching: state.transaction.isFetching,
      hasMore: state.transaction.paging.has_more
    }),
    dispatch => ({
      fetchStates: country => dispatch(fetchStates(country)),
      fetchMsbList: () => dispatch(fetchMsbList()),
      fetchMtoList: () => dispatch(fetchMtoList()),
      fetchTransactions: (page, filter) =>
        dispatch(fetchTransactions(page, filter)),
      fetchAllTransactions: (filter, onSuccess, onFailure) =>
        dispatch(fetchAllTransactions(filter, onSuccess, onFailure)),
      initializeTransactions: () => dispatch(initializeTransactions())
    })
  )
)(RefundTransactions);
