import React from 'react';
import * as Yup from 'yup';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { Form, InputField, Button } from '@raas-dashboard/base';

import { configuration } from '../AffiliateForm';
import { saveBasicInfo, toggleComponent } from 'actions/affiliateAction';

import { error } from 'constants/messages';
import { generateComponentId } from 'utils/index';

export const input = {
  affiliateName: {
    name: 'name',
    label: 'Affiliate Name',
    placeholder: ''
  },
  websiteUrl: {
    name: 'website',
    label: 'Website URL',
    placeholder: ''
  },
  addressLine1: {
    name: 'addressLine1',
    label: 'Address Line 1',
    placeholder: ''
  },
  addressLine2: {
    name: 'addressLine2',
    label: 'Address Line 2',
    placeholder: ''
  },
  city: {
    name: 'city',
    label: 'City',
    placeholder: ''
  },
  state: {
    name: 'state',
    label: 'State',
    placeholder: ''
  },
  zipCode: {
    name: 'zipCode',
    label: 'Zip Code',
    placeholder: ''
  },
  mobileNumber: {
    name: 'mobileNumber',
    label: 'Mobile Number',
    placeholder: ''
  },
  telNumber: {
    name: 'telephoneNumber',
    label: 'Telephone Number',
    placeholder: ''
  }
};

const validationSchema = Yup.object({
  name: Yup.string().required("Affiliate company's name is required.")
});

const BasicInfoConfiguration = props => {
  const initialValues = props.initialValues;

  const handleSubmit = data => {
    if (props.onSubmit) {
      return props.onSubmit(data);
    }
    props.saveBasicInfo({ basicInfo: { ...data } });
    props.toggleComponent(configuration.ORIGINATOR);
  };

  return (
    <div className="container">
      <div>
        <h2>Add Affiliate</h2>
      </div>
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <FormElements />
      </Form>
    </div>
  );
};

const FormElements = props => {
  return (
    <>
      <InputField
        name={input.affiliateName.name}
        id={generateComponentId(input.affiliateName.name, 'affiliate-form')}
        label={input.affiliateName.label}
      />
      <InputField
        name={input.websiteUrl.name}
        id={generateComponentId(input.websiteUrl.name, 'affiliate-form')}
        label={input.websiteUrl.label}
      />
      <Row>
        <Col className="col-md-6">
          <InputField
            name={input.addressLine1.name}
            id={generateComponentId(input.addressLine1.name, 'affiliate-form')}
            label={input.addressLine1.label}
          />
        </Col>
        <Col className="col-md-6">
          <InputField
            name={input.addressLine2.name}
            id={generateComponentId(input.addressLine2.name, 'affiliate-form')}
            label={input.addressLine2.label}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-md-6">
          <InputField
            name={input.city.name}
            id={generateComponentId(input.city.name, 'affiliate-form')}
            label={input.city.label}
          />
        </Col>
        <Col className="col-md-6">
          <InputField
            name={input.state.name}
            id={generateComponentId(input.state.name, 'affiliate-form')}
            label={input.state.label}
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-md-6">
          <InputField
            name={input.zipCode.name}
            id={generateComponentId(input.zipCode.name, 'affiliate-form')}
            label={input.zipCode.label}
          />
        </Col>
        <Col className="col-md-6">
          <InputField
            name={input.mobileNumber.name}
            id={generateComponentId(input.mobileNumber.name, 'affiliate-form')}
            label={input.mobileNumber.label}
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-md-6">
          <InputField
            name={input.telNumber.name}
            id={generateComponentId(input.telNumber.name, 'affiliate-form')}
            label={input.telNumber.label}
          />
        </Col>
        <Col className="col-md-6"></Col>
      </Row>
      <div className="affiliate-form__button-wrapper">
        <div className="affiliate-form__button">
          <Button name="Save" type="submit" />
        </div>
      </div>
    </>
  );
};

export default compose(
  connect(
    state => ({ affiliate: state.affiliate }),
    dispatch => ({
      saveBasicInfo: basicInfo => dispatch(saveBasicInfo(basicInfo)),
      toggleComponent: component => dispatch(toggleComponent(component))
    })
  )
)(BasicInfoConfiguration);
