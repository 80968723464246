import produce from 'immer';
import { UI } from '../actions/actions';

const initialState = {};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case UI.SET:
      return produce(state, draft => {
        draft = action.payload;
      });
    // Todo: need to ask sudhir dai
    // return setIn(state, action.payload.key.split('.'), action.payload.value);
    default:
      return state;
  }
};

export default uiReducer;
