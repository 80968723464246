import config from 'config';

export const getLoginUrl = () => {
  return `${config.auth.signinUri}?client_id=${
    config.auth.clientId
  }&response_type=${config.auth.responseType}&scope=${config.auth.scope.join(
    '+'
  )}&redirect_uri=${config.auth.callbackUri}`;
};

export const getLogoutUrl = () => {
  return `${config.auth.signoutUri}?client_id=${
    config.auth.clientId
  }&response_type=${config.auth.responseType}&scope=${config.auth.scope.join(
    '+'
  )}&redirect_uri=${config.auth.callbackUri}&state=STATE`;
};

export const getUrlParams = url => {
  const hashes = url.slice(url.indexOf('?') + 1).split('&');
  const params = {};

  hashes.map(hash => {
    const [key, val] = hash.split('=');

    params[key] = decodeURIComponent(val);
  });

  return params;
};
