import produce from 'immer';
import { compose } from 'redux';
import { useField } from 'formik';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import { Form, Button, RadioGroup, Checkbox } from '@raas-dashboard/base';

import { configuration } from 'components/affiliate/AffiliateForm';
import {
  toggleComponent,
  saveAffiliateDraft,
  savePayoutPartnerInfo
} from 'actions/affiliateAction';

const PayoutPartnerConfiguration = props => {
  const [countries, setCountries] = useState({});
  const initialValues = props.initialValues;
  const handleSubmit = data => {
    const payoutPartners = JSON.parse(JSON.stringify(data));
    if (props.onSubmit) {
      return props.onSubmit(payoutPartners);
    }
    props.savePayoutPartnerInfo(payoutPartners);
    props.toggleComponent(configuration.SERVICE_PROVIDER);
  };

  useEffect(() => {
    setCountryPayoutPartners(props, setCountries);
  }, []);

  return (
    <div className="container">
      <div>
        <h2>Payout Partners</h2>
      </div>
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        <FormElements
          countries={countries}
          isEditView={props.isEditView}
          affiliate={props.affiliate}
          saveAffiliateDraft={props.saveAffiliateDraft}
        />
      </Form>
    </div>
  );
};

const setCountryPayoutPartners = (props, setCountries) => {
  const { countries, payoutPartners } = props;
  const countryList = produce({}, draft => {
    let payouts = [];
    payoutPartners.forEach(payoutPartner => {
      payoutPartner.countries.forEach(country => {
        if (!payouts[country.country_code]) {
          payouts[country.country_code] = [];
        }
        payouts[country.country_code].push({
          id: payoutPartner.id,
          name: payoutPartner.name,
          type: payoutPartner.type
        });
        draft[country.country_code] = {
          selected: false,
          receivingCurrency: country.receiving_currency,
          payoutPartners: payouts[country.country_code],
          name: countries[country.country_code].name,
          three_char_code: countries[country.country_code].three_char_code
        };
      });
    });
  });
  setCountries(countryList);
};

const FormElements = props => {
  const { affiliate, countries, isEditView, saveAffiliateDraft } = props;
  const saveProgress = data => {
    const affiliateDraft = produce(affiliate, draft => {
      draft.payoutPartners = data;
    });
    saveAffiliateDraft(affiliateDraft);
  };
  const [field] = useField(props);

  return (
    <>
      <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Country</th>
              <th>Available Payout Partners</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(countries).map((country, key) =>
              showCountryRow(countries[country], key)
            )}
          </tbody>
        </Table>
      </div>
      <div className="affiliate-form__button-wrapper">
        {isEditView ? (
          <div className="affiliate-form__button">
            <Button name="Save" type="submit" disabled={affiliate.isFetching} />
          </div>
        ) : (
          <>
            <div className="affiliate-form__button">
              <Button
                name="Next"
                type="submit"
                disabled={affiliate.isFetching}
              />
            </div>
            <div className="affiliate-form__button">
              <Button
                name="Save Progress"
                disabled={affiliate.isFetching}
                onClick={() => saveProgress(field.value)}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

const showCountryRow = (country, key) => {
  if (country.payoutPartners.length > 0) {
    const payoutMethods = {};
    country.payoutPartners.forEach(payoutPartner => {
      if (!payoutMethods[payoutPartner.type]) {
        payoutMethods[payoutPartner.type] = [];
      }
      if (!payoutMethods[payoutPartner.type].includes(payoutPartner.name)) {
        payoutMethods[payoutPartner.type].push(payoutPartner.name);
      }
    });
    return (
      <tr key={key}>
        <td>
          <label>{country.name}</label>
        </td>
        <td>
          {Object.keys(payoutMethods).map((payoutMethod, key) => {
            return (
              <React.Fragment key={key}>
                <Checkbox
                  label={payoutMethod}
                  name={`${country.three_char_code}[${payoutMethod}].value`}
                />
                <RadioGroup
                  label=""
                  name={`${country.three_char_code}[${payoutMethod}].payoutPartner`}
                  values={payoutMethods[payoutMethod]}
                />
              </React.Fragment>
            );
          })}
        </td>
      </tr>
    );
  }
};

export default compose(
  connect(
    state => ({
      affiliate: state.affiliate,
      countries: state.country.data,
      payoutPartners: state.payoutPartner.data
    }),
    dispatch => ({
      saveAffiliateDraft: draft => dispatch(saveAffiliateDraft(draft)),
      toggleComponent: component => dispatch(toggleComponent(component)),
      savePayoutPartnerInfo: payoutPartners =>
        dispatch(savePayoutPartnerInfo(payoutPartners))
    })
  )
)(PayoutPartnerConfiguration);
