import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';

import config from '../config';
import { withData, withError } from 'utils/commonUtils';
import { securedLS, KS_KEY } from 'utils/localStorageUtils';
import { ErrorToastMessage } from '@raas-dashboard/base';

const axiosInstance = axios.create({
  baseURL: config.server.url,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = cb => {
  refreshSubscribers.push(cb);
};

const onRrefreshed = token => {
  refreshSubscribers.map(cb => cb(token));
};

axiosInstance.interceptors.response.use(
  response => {
    return withData(response.data);
  },
  error => {
    if (error.message === 'Network Error') {
      toast.error(<ErrorToastMessage message={error.message} />);

      return withError(error.message);
    }

    const {
      response: { status }
    } = error;
    const isSignedIn = securedLS.get(KS_KEY.TOKEN).data ? true : false;

    if (status === 401 && isSignedIn) {
      return handle401Error(error);
    }

    return withError(error.response ? error.response.data : error);
  }
);

export const refreshAccessToken = () => {
  return post({
    url: `/auth/token`,
    data: {
      referenceToken: securedLS.get(KS_KEY.TOKEN).data
    }
  });
};

const handle401Error = error => {
  const pendingRequest = error.config;

  if (!isRefreshing) {
    isRefreshing = true;
    refreshAccessToken().then(res => {
      if (res.data) {
        const { data } = res;
        isRefreshing = false;
        securedLS.set(KS_KEY.TOKEN, data.token);
        onRrefreshed(data.token);

        return (refreshSubscribers = []);
      }

      // clear auth details immediately if access token
      // cannot be refreshed to prevent infinite loop
      securedLS.clear();
      window.location.reload();
    });
  }

  const retryPendingRequest = new Promise(resolve => {
    subscribeTokenRefresh(token => {
      // replace the expired token and retry
      pendingRequest.headers.Authorization = `Bearer ${token}`;
      resolve(axiosInstance(pendingRequest));
    });
  });

  return retryPendingRequest;
};

export const get = ({ url, params = {} }) =>
  axiosInstance({
    method: 'get',
    url,
    params,
    headers: {
      Authorization: `Bearer ${securedLS.get(KS_KEY.TOKEN).data}`
    }
  });

export const post = ({ url, data }) =>
  axiosInstance({
    method: 'post',
    url,
    data,
    headers: {
      Authorization: `Bearer ${securedLS.get(KS_KEY.TOKEN).data}`
    }
  });

export const put = ({ url, data }) =>
  axiosInstance({
    method: 'put',
    url,
    data,
    headers: {
      Authorization: `Bearer ${securedLS.get(KS_KEY.TOKEN).data}`
    }
  });

export const del = ({ url, params = {} }) =>
  axiosInstance({
    method: 'delete',
    url,
    params,
    headers: {
      Authorization: `Bearer ${securedLS.get(KS_KEY.TOKEN).data}`
    }
  });

export const patch = ({ url, data }) =>
  axiosInstance({
    method: 'patch',
    url,
    data,
    headers: {
      Authorization: `Bearer ${securedLS.get(KS_KEY.TOKEN).data}`
    }
  });
