import React from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';

import { EXCHANGE_RATE } from '../actions/actions';
import { ErrorToastMessage } from '@raas-dashboard/base';

const initialState = {
  data: [],
  isFetching: false,
  error: ''
};

const exchangeRateReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXCHANGE_RATE.FETCH:
      return produce(state, draft => {
        draft.isFetching = true;
        draft.data = [];
        draft.error = '';
      });
    case EXCHANGE_RATE.FETCH_SUCCEEDED:
      return produce(state, draft => {
        draft.isFetching = false;
        draft.data = action.payload.results;
      });

    case EXCHANGE_RATE.FETCH_FAILED:
      toast.error(
        <ErrorToastMessage message="Network error. Couldn't fetch Exchange Rates." />
      );

      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    default:
      return state;
  }
};

export default exchangeRateReducer;
