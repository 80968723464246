import { AUTH } from 'actions/actions';

export const saveUserCredentials = data => ({
  type: AUTH.SAVE_USER_CREDENTIALS,
  payload: data
});

export const authorizeUser = data => ({
  payload: {
    url: '/auth/authorize',
    data,
    onSuccess: AUTH.USER_AUTHORIZATION_SUCCESS,
    onFailure: AUTH.USER_AUTHORIZATION_FAILED
  },
  type: AUTH.USER_AUTHORIZATION_PENDING
});

export const signOutUser = () => ({
  payload: {
    url: '/auth/signout',
    onSuccess: AUTH.SIGN_OUT_SUCCESS,
    onFailure: AUTH.SIGN_OUT_FAILED
  },
  type: AUTH.SIGN_OUT_PENDING
});
