import React from 'react';
import PropTypes from 'prop-types';

import './Modal.scss';

/**
 * Popup modal component.
 *
 * @param {Object} props
 *
 * @returns {React.FC}
 */
const Modal = props => {
  return (
    <div className="modal-wrapper">
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="modal-top">
          <span className="modal-top__close-btn" onClick={props.onClose}>
            <i className="fas fa-times"></i>
          </span>
        </div>
        {props.children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onClose: PropTypes.func
};

export default Modal;
